/* Reset default styles */
* {
  box-sizing: border-box;
}

/* Video Player Modal - Specific namespace */
.video-player-modal-overlay {
  position: fixed !important;
  inset: 0 !important; /* Shorthand for top, right, bottom, left */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: rgba(0, 0, 0, 0.85) !important;
  z-index: 99999 !important;
  padding: 20px !important;
  margin: 0 !important;
}

.video-player-modal {
  position: relative !important;
  width: 95vw !important;
  
  max-width: 1600px !important;
  max-height: 900px !important;
  background: var(--vjs-theme-bg, #000) !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column !important;
  margin: auto !important;
}

.video-player-modal .modal-content {
  flex: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  position: relative !important;
  height: 100% !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  background: transparent !important;
  border: none !important;
  overflow: hidden !important;
}

.video-player-modal .video-player-container {
  flex: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
  background: #000 !important;
}

.video-player-modal .video-container {
  flex: 1 !important;
  display: flex !important;
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  background: #000 !important;
}

.video-player-modal .video-main {
  flex: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  min-height: 0 !important;
}

.video-player-modal .video-wrapper {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  overflow: hidden !important;
}

.video-player-modal [data-vjs-player] {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.video-player-modal .video-js {
  width: 100% !important;
  height: 100% !important;
  max-width: 1440px !important;
  max-height: 810px !important;
  margin: auto !important;
  background: #000 !important;
  position: relative !important;
}

.video-player-modal .drawing-canvas {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  pointer-events: none !important;
  z-index: 2 !important;
}

.video-player-modal .drawing-canvas.active {
  pointer-events: auto !important;
  cursor: crosshair !important;
}

.drawing-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;
}

.drawing-canvas.active {
  pointer-events: auto !important;
  cursor: crosshair !important;
}

/* Tambahan style untuk memastikan canvas bisa menerima event */
.video-js .drawing-canvas {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 10 !important; /* Meningkatkan z-index */
}

.video-js .drawing-canvas.active {
  pointer-events: auto !important;
  cursor: crosshair !important;
}

/* Top bar styles */
.video-player-modal .top-bar {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 10px 20px !important;
  background: rgba(0, 0, 0, 0.8) !important;
  color: white !important;
  z-index: 1 !important;
}

.video-player-modal .close-button {
  background: transparent !important;
  border: none !important;
  color: white !important;
  font-size: 24px !important;
  cursor: pointer !important;
  padding: 5px 10px !important;
  margin: 0 !important;
  opacity: 0.8 !important;
  transition: opacity 0.2s !important;
}

.video-player-modal .close-button:hover {
  opacity: 1 !important;
}

/* Tool buttons styles */
.control-button.active-tool {
  background-color: #4CAF50 !important;
  color: white !important;
  border: 2px solid #2E7D32 !important;
}

/* Responsive styles */
@media (max-width: 1600px) {
  .video-player-modal {
    max-width: 1280px !important;
    max-height: 800px !important;
  }
  
  .video-player-modal .video-js {
    max-width: 1200px !important;
    max-height: 675px !important;
  }
}

@media (max-width: 1280px) {
  .video-player-modal {
    max-width: 1024px !important;
    max-height: 700px !important;
  }
  
  .video-player-modal .video-js {
    max-width: 960px !important;
    max-height: 540px !important;
  }
}

@media (max-width: 1024px) {
  .video-player-modal {
    width: 98vw !important;
    height: 98vh !important;
    max-width: 900px !important;
    max-height: 600px !important;
  }
  
  .video-player-modal .video-js {
    max-width: 854px !important;
    max-height: 480px !important;
  }
}

.video-player-container {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.video-container {
  display: flex;
  height: 100%;
}

.video-main {
  flex: 1;
  transition: width 0.3s ease;
}

.video-main.full-width {
  width: 100%;
}

.sidebarpresent {
  width: 300px;
  transition: width 0.3s ease;
}

.sidebarpresent.collapsed {
  width: 0;
  overflow: hidden;
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.video-title {
  font-weight: 500;
  color: #2d3748;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #4a5568;
}

/* Frame Timeline */
.frame-timeline {
  position: relative;
  height: 40px;
  background: #f8f9fa;
  border-top: 1px solid #e9ecef;
  cursor: pointer;
}

.frame-markers {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.frame-marker {
  position: absolute;
  top: 50%;
  width: 1px;
  height: 10px;
  background: #cbd5e0;
  transform: translateY(-50%);
}

.frame-marker.major {
  height: 16px;
  background: #4a5568;
}

.frame-number {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  color: #4a5568;
  white-space: nowrap;
}

.annotation-marker {
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  background: #4299e1;
  opacity: 0.7;
}

.timeline-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(66, 153, 225, 0.2);
}

.timeline-handle {
  position: absolute;
  right: -4px;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 16px;
  background: #4299e1;
  border-radius: 4px;
}

.current-frame {
  position: absolute;
  top: -20px;
  right: -30px;
  background: #4299e1;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
}

/* Video Controls */
.video-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
}

.control-buttons {
  display: flex;
  gap: 10px;
}

.control-button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.control-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.time-display {
  color: white;
  font-family: monospace;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.frame-display {
  font-size: 12px;
  opacity: 0.8;
}

/* Sidebar */
.sidebar-toggle {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  z-index: 1001;
  margin-left: 10px;
}

.sidebar-toggle:hover {
  background: rgba(255, 255, 255, 0.2);
}

.sidebar-tabs {
  display: flex;
  border-bottom: 1px solid #e9ecef;
}

.tab-button {
  flex: 1;
  padding: 12px;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 500;
  color: #4a5568;
  transition: all 0.2s;
}

.tab-button.active {
  color: #4299e1;
  border-bottom: 2px solid #4299e1;
}

.tab-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

/* Info Panel */
.info-panel h3 {
  margin-top: 0;
  
  margin-bottom: 20px;
}

.info-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #e9ecef;
}

.info-item label {
  
  font-weight: 500;
}

.info-item span {

  font-family: monospace;
}

/* Annotations Panel */
.annotation-header {
  margin-bottom: 20px;
  padding: 10px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  display: flex !important;
  flex-direction: column !important;
}

.annotation-header h3 {
  font-size: 14px !important;
  margin: 0 0 10px 0 !important;
  color: #2d3748 !important;
  font-weight: 500 !important;
}

.annotation-tools {
  display: flex !important;
  flex-direction: column !important;
  gap: 16px !important;
  padding: 16px !important;
  background: #f8f9fa !important;
  border-radius: 8px !important;
}

.tool-button {
  display: flex !important;
  align-items: center !important;
  gap: 4px !important;
  padding: 6px 12px !important;
  font-size: 12px !important;
  background: #fff !important;
  border: 1px solid #e2e8f0 !important;
  border-radius: 4px !important;
  color: #2d3748 !important;
  cursor: pointer !important;
  transition: background 0.2s !important;
}

.tool-button:hover {
  background: #f7fafc !important;
}

.tool-button.active {
  background: #ebf8ff !important;
  border-color: #4299e1 !important;
  color: #4299e1 !important;
}

.tool-button span {
  font-size: 14px !important;
}

.color-picker {
  width: 30px !important;
  height: 30px !important;
  padding: 2px !important;
  border: 1px solid #e2e8f0 !important;
  border-radius: 4px !important;
  background: transparent !important;
  cursor: pointer !important;
}

.text-annotation-form {
  display: none !important;
  margin-top: 16px;
  padding: 12px;
  background: #f7fafc;
  border-radius: 6px;
}

.text-annotation-form textarea {
  width: 100%;
  min-height: 80px;
  padding: 8px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  resize: vertical;
  margin-bottom: 12px;
  font-size: 14px;
}

.form-buttons {
  display: flex;
  gap: 8px;
}

.form-buttons button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.save-button {
  background: #4299e1;
  color: white;
}

.cancel-button {
  background: #e2e8f0;
  color: #4a5568;
}

.annotations-section {
  margin-top: 24px;
}

.annotations-section h3 {
  margin-top: 0;
  margin-bottom: 16px;
  color: #2d3748;
  display: flex;
  align-items: center;
  gap: 8px;
}

.annotations-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.annotation-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;
}

.annotation-item:hover {
  background: #f1f5f9;
}

.annotation-item.active {
  background: #e6f6ff;
  border-color: #4299e1;
}

.annotation-info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.annotation-time {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: #718096;
}

.time {
  font-family: monospace;
}

.frame {
  padding: 2px 6px;
  background: #e2e8f0;
  border-radius: 4px;
}

.annotation-content {
  color: #2d3748;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-content {
  font-weight: 500;
}

.draw-content {
  color: #718096;
  font-style: italic;
}

.delete-button {
  background: none;
  border: none;
  color: #a0aec0;
  font-size: 18px;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s;
  margin-left: 8px;
}

.delete-button:hover {
  background: #fed7d7;
  color: #e53e3e;
}

/* Modal specific styles */
.modal-mode .video-container {
  flex-direction: row;
  height: 100vh;
}

.modal-mode .video-main {
  flex: 1;
  height: 100%;
}

.video-js .vjs-progress-control { display: none; }
.video-js .vjs-volume-panel { display: none; }

.video-js .vjs-control-bar {
  justify-content: flex-end; /* Mengatur posisi ke kanan */
}

.video-js {
  /* Background player */
  --vjs-theme-bg: #1a1a1a;
  /* Warna primary untuk controls */  
  --vjs-theme-primary: #fff;
  /* Warna secondary untuk progress bar */
  --vjs-theme-secondary: #ff0000;
}

.video-js .vjs-progress-control .vjs-progress-holder {
  background: rgba(255,255,255,0.2);
}

.video-js .vjs-progress-control .vjs-play-progress {
  background: var(--vjs-theme-secondary);
}

.vjs-custom-volume {
  width: 100px;
  margin: 0 10px;
}

.vjs-custom-volume svg {
  width: 100%;
  height: 100%;
}

.vjs-vol-muted svg path {
  fill: #666;
}

.vjs-vol-full svg path {
  fill: #fff;
}

.top-bar-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.video-container:hover .top-bar-video,
.video-container:hover .custom-controls {
  opacity: 1;
}

.video-title {
  color: white;
  font-size: 14px;
  margin-left: 10px;
}

.top-controls {
  display: flex;
  gap: 10px;
}

.video-container:fullscreen {
  width: 100vw;
  height: 100vh;
  background: black;
}

.video-container:fullscreen .top-bar-video,
.video-container:fullscreen .custom-controls {
  opacity: 1;
  z-index: 2147483647; /* Nilai z-index tertinggi */
}

.video-container:fullscreen .top-bar-video {
  position: fixed;
  top: 0;
}

.video-container:fullscreen .custom-controls {
  position: fixed;
  bottom: 0;
}

.video-container:-moz-full-screen {
  width: 100vw;
  height: 100vh;
}

.video-container:-webkit-full-screen {
  width: 100vw;
  height: 100vh;
}

.video-container:fullscreen video {
  width: 100%;
  height: 100%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.8);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.modal-header span {
  color: white;
  font-size: 16px;
}

.close-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
}

.close-button:hover {
  color: #ff0000;
}

.modal-body {
  display: flex;
  flex: 1;
  height: calc(100% - 50px);
  overflow: hidden;
  background: var(--vjs-theme-bg);
}

.modal-content {
  flex: 4;
  position: relative;
  padding: 0;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 1320px;
  height: 100%;
}

.modal-content [data-vjs-player] {
  width: 1280px;
  height: 720px;
  position: relative;
  margin: 0;
}

.modal-content .video-js {
  width: 1280px !important;
  height: 720px !important;
  background-color: black;
}

.modal-sidebar {
  flex: 1;
  background: rgba(0, 0, 0, 0.3);
  padding: 20px;
  color: white;
  width: 100%;
  overflow-y: auto;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-header {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-header h3 {
  margin: 0;
  font-size: 16px;
  color: white;
}

.info-item {
  margin-bottom: 15px;
}

.info-label {
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 5px;
}

.info-value {
  font-size: 14px;
  color: white;
}

.sidebar-tabs {
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
}

.tab-button {
  background: transparent;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.tab-button.active {
  opacity: 1;
  border-bottom: 2px solid white;
}

.annotation-tools {
  display: flex !important;
  flex-direction: column !important;
  gap: 16px !important;
  padding: 16px !important;
  background: #f8f9fa !important;
  border-radius: 8px !important;
}

.annotation-header {
  width: 100% !important;
}

.annotation-header h3 {
  margin: 0 0 8px 0 !important;
  font-size: 18px !important;
  color: #2d3748 !important;
}

.annotations-section {
  width: 100% !important;
  background: white !important;
  border-radius: 8px !important;
  padding: 16px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.saved-annotations {
  width: 100% !important;
  background: white !important;
  border-radius: 8px !important;
  padding: 16px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.tool-buttons {
  display: flex !important;
  gap: 8px !important;
  flex-wrap: wrap !important;
}

/* Menambahkan media query untuk layar yang lebih kecil */
@media screen and (max-width: 1440px) {
  .modal-content {
    min-width: 1000px; /* 960px + 40px padding */
  }
  
  .modal-content [data-vjs-player],
  .modal-content .video-js {
    width: 960px !important;
    height: 540px !important;
  }
}

@media screen and (max-width: 1024px) {
  .modal-content {
    min-width: 894px; /* 854px + 40px padding */
  }
  
  .modal-content [data-vjs-player],
  .modal-content .video-js {
    width: 854px !important;
    height: 480px !important;
  }
}

/* Menambahkan style untuk marker di timeline */
.timeline {
  position: relative !important;
  width: 100% !important;
  height: 20px !important;
  background: rgba(0, 0, 0, 0.2) !important;
  cursor: pointer !important;
  margin-bottom: 40px !important;
}

.timeline-progress {
  position: absolute !important;
  height: 100% !important;
  background: rgba(66, 153, 225, 0.5) !important;
  pointer-events: none !important;
}

.timeline-marker {
  position: absolute !important;
  top: -2px !important;
  width: 3px !important;
  height: 24px !important;
  background: #4299e1 !important;
  transform: translateX(-50%) !important;
  z-index: 1 !important;
}

.timeline-marker::after {
  content: '' !important;
  position: absolute !important;
  top: -4px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: 8px !important;
  height: 8px !important;
  background: #4299e1 !important;
  border-radius: 50% !important;
}

.timeline-frame-marker {
  position: absolute !important;
  bottom: -2px !important;
  width: 2px !important;
  height: 12px !important;
  background: rgba(255, 255, 255, 0.3) !important;
  transform: translateX(-50%) !important;
}

.saved-annotations {
  margin-top: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

.saved-annotations h3 {
  margin: 0 0 10px 0;
  font-size: 16px;
  color: #2d3748;
}

.saved-annotations-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.saved-annotation-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.saved-annotation-item:hover {
  background: #edf2f7;
  border-color: #cbd5e0;
}

.saved-annotation-item span {
  font-size: 14px;
}

.saved-annotation-item span:last-child {
  color: #718096;
  font-size: 12px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.section-header h3 {
  margin: 0;
  font-size: 16px;
  color: #2d3748;
}

.collapse-button {
  background: none;
  border: none;
  color: #718096;
  font-size: 20px;
  cursor: pointer;
  padding: 0 4px;
  line-height: 1;
}

.collapse-button:hover {
  color: #4a5568;
}

.tool-button {
  background: #4299e1;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.tool-button:hover {
  background: #3182ce;
}

.tool-button.active {
  background: #2c5282;
}

.tool-buttons {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

/* Dark mode for sidebar */
.video-player-modal .sidebarpresent {
  background: #1a1a1a !important;
  color: #ffffff !important;
  border-right: 1px solid #2d2d2d !important;
}

/* Dark mode for top-bar */
.video-player-modal .top-barPresent {
  background: #1a1a1a !important;
  color: #595959 !important;
  border-bottom: 1px solid #2d2d2d !important;
}

/* Dark mode for tab buttons */
.video-player-modal .tab-button {
  background: #2d2d2d !important;
  color: #ffffff !important;
  border: 1px solid #3d3d3d !important;
}

.video-player-modal .tab-button.active {
  background: #4a90e2 !important;
  color: #ffffff !important;
}

/* Dark mode for close button */
.video-player-modal .close-button {
  color: #ffffff !important;
  background: transparent !important;
}

.video-player-modal .close-button:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}

.top-barPresent {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  opacity: 1;
}

.top-bar-controls {
  display: flex;
  gap: 10px;
  align-items: center;
}

.sidebar-toggle {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  z-index: 1001;
  margin-left: 10px;
}

.sidebar-toggle:hover {
  background: rgba(255, 255, 255, 0.2);
}

.video-player-present-container {
  display: flex;
  height: 100%;
}

.sidebar-present {
  width: 300px;
  background-color: #2c3e50;
  color: white;
  padding: 1rem;
  transition: all 0.3s ease;
}

.video-container-present {
  flex: 1;
  transition: all 0.3s ease;
}

.video-container-present.with-sidebar {
  margin-left: 300px;
}

.video-container-present.full-width {
  margin-left: 0;
}

video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}