.hashtag-manager {
  padding: 20px;
  background: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hashtag-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.hashtag-header h3 {
  margin: 0;
  color: var(--text-color);
}

.hashtag-add {
  display: flex;
  gap: 10px;
}

.hashtag-input,
.edit-input {
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--input-background);
  color: var(--text-color);
  font-size: 14px;
  min-width: 200px;
}

.hashtag-input:focus,
.edit-input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.add-btn,
.edit-btn,
.delete-btn,
.save-btn,
.cancel-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.add-btn {
  background-color: var(--primary-color);
  color: white;
}

.add-btn:hover {
  background-color: var(--primary-color-dark);
}

.edit-btn {
  background-color: var(--warning-color);
  color: white;
}

.delete-btn {
  background-color: var(--danger-color);
  color: white;
}

.save-btn {
  background-color: var(--success-color);
  color: white;
}

.cancel-btn {
  background-color: var(--secondary-color);
  color: white;
}

.hashtag-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hashtag-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: var(--item-background);
  border-radius: 4px;
  border: 1px solid var(--border-color);
}

.hashtag-name {
  color: var(--text-color);
  font-size: 14px;
}

.hashtag-actions {
  display: flex;
  gap: 5px;
}

.error-message {
  color: var(--danger-color);
  margin: 10px 0;
  padding: 10px;
  background-color: var(--danger-color-light);
  border-radius: 4px;
  font-size: 14px;
}
