.present-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--vjs-theme-bg);
  }
  
  .present-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    height: 50px;
  }
  
  .present-content {
    display: flex;
    flex: 1;
    overflow: hidden;
  }
  
  .present-sidebar {
    width: 300px;
    padding: 20px;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .present-sidebar .category-select {
    width: 100%;
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
   
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  .present-sidebar .category-select option {
    background: #1a1a1a;
    color: white;
  }
  
  .present-sidebar .library-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 10px;
  }
  
  .present-sidebar .video-preview {
    aspect-ratio: 16/9;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    background: rgba(0, 0, 0, 0.3);
  }
  
  .present-sidebar .video-info {
    padding: 8px;
  }
  
  .present-sidebar .video-info h4 {
    font-size: 8px;
    margin: 0 0 4px 0;
    color: white;
    font-weight: 500;
  }
  
  .present-sidebar .video-info p {
    font-size: 12px;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .present-sidebar::-webkit-scrollbar {
    width: 8px;
  }
  
  .present-sidebar::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
  }
  
  .present-sidebar::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }
  
  .present-sidebar::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  
  .present-main-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  .library-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .video-preview {
    position: relative;
    aspect-ratio: 16/9;
    overflow: hidden;
    border-radius: 4px;
  }
  
  .video-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .video-info-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 8px;
    background: linear-gradient(to top, rgba(0,0,0,0.8), transparent);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .version {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
  }
  
  .approved-badge {
    background: #4CAF50;
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
  }
  
  .selected-videos-scroll {
    display: flex;
    overflow-x: auto;
    gap: 15px;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.2);
  }
  
  .selected-video-card {
    flex: 0 0 200px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    cursor: move;
    user-select: none;
    transition: all 0.2s ease;
    border: 2px solid transparent;
    background: rgba(0, 0, 0, 0.3);
  }
  
  .selected-video-card.playing {
    border-color: #4CAF50;
    background: rgba(76, 175, 80, 0.1);
  }
  
  .selected-video-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
  
  .playing-indicator {
    position: absolute;
    top: 8px;
    left: 8px;
    background: #4CAF50;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    z-index: 2;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
  
  .video-card-info {
    padding: 8px;
    color: white;
  }
  
  .video-card-info small {
    font-size: 12px;
    opacity: 0.8;
  }
  
  .playlist-container {
    margin-top: 20px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    padding: 20px;
  }
  
  .video-container {
    width: 100%;
    aspect-ratio: 16/9;
    position: relative;
    background: black;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .playlist-controls {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 15px;
  }
  
  .playlist-controls button {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.2s;
  }
  
  .playlist-controls button:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .playlist-controls button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .item-card {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.2s ease;
    background: rgba(0, 0, 0, 0.3);
  }
  
  .item-card.selected {
    border: 2px solid #4CAF50;
  }
  
  .item-card.selected::after {
    content: '✓';
    position: absolute;
    top: 8px;
    right: 8px;
    background: #4CAF50;
    color: white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    z-index: 2;
  }
  
  .item-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
  
  .video-info {
    padding: 8px;
  }
  
  .video-info h4 {
    margin: 0 0 4px 0;
    font-size: 14px;
    color: white;
  }
  
  .video-info p {
    margin: 0;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .present-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .present-topbar-left {
    display: flex;
    gap: 10px;
  }
  
  .present-topbar-right {
    display: flex;
    gap: 10px;
  }
  
  .present-topbar button {
    position: relative;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
  }
  
  .present-topbar button:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .present-topbar button svg {
    width: 16px;
    height: 16px;
  }
  
  .present-topbar button.active {
    background: #4CAF50;
  }
  
  .file-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background: #333;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    min-width: 200px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    margin-top: 4px;
  }
  
  .file-dropdown-item {
    padding: 8px 16px;
    color: white;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: background 0.2s;
  }
  
  .file-dropdown-item:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  
  .file-dropdown-divider {
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    margin: 4px 0;
  }
  
  .recent-files {
    padding: 8px 0;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .recent-files-title {
    padding: 4px 16px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
  }
  
  .presentation-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    z-index: 1100;
    min-width: 400px;
    color: white;
  }
  
  .presentation-list {
    max-height: 400px;
    overflow-y: auto;
    margin: 20px 0;
  }
  
  .presentation-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .presentation-info {
    flex: 1;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
  }
  
  .presentation-info:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  
  .delete-button {
    background: none;
    border: none;
    color: #ff4444;
    cursor: pointer;
    padding: 5px;
    border-radius: 4px;
  }
  
  .delete-button:hover {
    background: rgba(255, 0, 0, 0.1);
  }
  
  .presentation-dialog button {
    padding: 8px 16px;
    background: #4CAF50;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
  }
  
  .presentation-dialog button:hover {
    background: #45a049;
  }
  
  .category-badge {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    z-index: 1;
  }
  
  .category-badge.shot {
    background-color: #ff6b6b;
  }
  
  .category-badge.sequence {
    background-color: #4ecdc4;
  }
  
  .category-badge.asset {
    background-color: #45b7d1;
  }
  
  .category-badge.task {
    background-color: #96c93d;
  }
  
  .invite-modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    z-index: 1001;
  }
  
  /* Dark mode */
  
  .invite-modal-content {
    padding: 20px;
    position: relative;
  }
  
  .invite-dialog {
    background: transparent;
    box-shadow: none;
    position: static;
    transform: none;
    width: 100%;
    max-height: none;
    overflow: visible;
  }

  .user-list {
    max-height: 400px;
    overflow-y: auto;
    margin: 15px 0;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
  }
  
  .user-item {
    padding: 8px;
    border-bottom: 1px solid var(--border-color);
  }
  
  .user-item label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  
  .user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .dialog-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 15px;
  } 
  .broadcast-dialog {
    padding: 20px;
    max-width: 500px;
    width: 100%;
  }
  
  .broadcast-dialog form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .broadcast-dialog .form-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .broadcast-dialog input,
  .broadcast-dialog select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .broadcast-dialog select[multiple] {
    height: 120px;
  }