.project-detail {
  
  padding: 20px;
  border-radius: 8px;
  height: 100%;
}

.project-detail h2 {
  margin-bottom: 20px;
}

.project-detail .project-thumbnail {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.project-detail p {
  margin-bottom: 10px;
}

