.level-manager {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.level-form {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.level-form input,
.level-form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.level-form button {
  padding: 8px 16px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.level-form button:hover {
  background: #0056b3;
}

.level-list {
  display: grid;
  gap: 15px;
}

.level-item {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.level-info {
  flex: 1;
}

.level-info h3 {
  margin: 0 0 8px 0;
}

.level-info p {
  color: #666;
  margin: 0 0 8px 0;
}

.level-actions {
  display: flex;
  gap: 8px;
}

.level-actions button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.level-actions button:first-child {
  background: #007bff;
  color: white;
}

.level-actions button:last-child {
  background: #dc3545;
  color: white;
}

/* Dark mode */

