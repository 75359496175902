/* Dark Mode Variables */
:root {
  --dark-bg-primary: #1a1a1a;
  --dark-bg-secondary: #242424;
  --dark-bg-tertiary: #2d2d2d;
  --dark-bg-hover: #333333;
  
  --dark-text-primary: #e0e0e0;
  --dark-text-secondary: #b0b0b0;
  
  --dark-border-primary: #333333;
  --dark-border-secondary: #404040;
  
  --dark-shadow: rgba(0, 0, 0, 0.2);
  
  --dark-accent: #f3a321;
  --dark-accent-hover: #e69416;
  
  --dark-success: #2e7d32;
  --dark-warning: #f57c00;
  --dark-error: #d32f2f;
  --dark-info: #1976d2;
}

/* Dark Mode Base Styles */
body.darkmode {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
}

.app.darkmode {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
  min-height: 100vh;
}

.darkmode .content {
  background-color: var(--dark-bg-primary);
  padding-top: 60px; /* Sesuaikan dengan tinggi top bar */
  min-height: calc(100vh - 60px);
}

/* Sidebar */
.darkmode .admin-sidebar {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
}

/* TopBar */
.darkmode .topbar {
  background-color: var(--dark-bg-secondary);
  border-bottom: 1px solid var(--dark-border-primary);
}

/* Cards and Containers */
.darkmode .admin-card,
.darkmode .chart-container,
.darkmode .content-header,
.darkmode .filter-section,
.darkmode .advanced-filter-panel,
.darkmode .dashboard-card,
.darkmode .project-card,
.darkmode .task-card,
.darkmode .issue-card {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
  box-shadow: 0 2px 4px var(--dark-shadow);
}

/* Form Elements */
.darkmode input,
.darkmode textarea,
.darkmode select,
.darkmode .select__control {
  background-color: var(--dark-bg-tertiary) !important;
  border-color: var(--dark-border-primary) !important;
  color: var(--dark-text-primary) !important;
}

.darkmode input:focus,
.darkmode textarea:focus,
.darkmode select:focus,
.darkmode .select__control--is-focused {
  border-color: var(--dark-accent) !important;
  box-shadow: 0 0 0 2px rgba(243, 163, 33, 0.1) !important;
}

/* Tables */
.darkmode table,
.darkmode .table {
  background-color: var(--dark-bg-secondary);
}

.darkmode table th,
.darkmode .table-header {
  background-color: var(--dark-bg-tertiary);
  color: var(--dark-text-secondary);
  border-color: var(--dark-border-primary);
}

.darkmode table td {
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.darkmode table tr:hover,
.darkmode .table-row:hover {
  background-color: var(--dark-bg-hover);
}

/* Buttons and Actions */
.darkmode button {
  background-color: var(--dark-bg-tertiary);
  color: var(--dark-text-primary);
  border-color: var(--dark-border-primary);
}

.darkmode button:hover {
  background-color: var(--dark-bg-hover);
}

.darkmode button.primary {
  background-color: var(--dark-accent);
  color: #ffffff;
  border: none;
}

.darkmode button.primary:hover {
  background-color: var(--dark-accent-hover);
}

/* Modals and Dialogs */
.darkmode .modal,
.darkmode .dialog {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
  box-shadow: 0 4px 6px var(--dark-shadow);
}

.darkmode .modal-header,
.darkmode .dialog-header {
  background-color: var(--dark-bg-tertiary);
  border-bottom: 1px solid var(--dark-border-primary);
}

/* Lists and Navigation */
.darkmode .list-item,
.darkmode .nav-item {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
}

.darkmode .list-item:hover,
.darkmode .nav-item:hover {
  background-color: var(--dark-bg-hover);
}

/* Status and Priority Badges */
.darkmode .status-badge {
  background-color: var(--dark-bg-tertiary);
}

/* Dropdowns and Menus */
.darkmode .dropdown-menu,
.darkmode .context-menu {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
  box-shadow: 0 2px 4px var(--dark-shadow);
}

.darkmode .dropdown-item:hover,
.darkmode .menu-item:hover {
  background-color: var(--dark-bg-hover);
}

/* Charts and Graphs */
.darkmode .recharts-surface {
  background-color: var(--dark-bg-secondary);
}

.darkmode .recharts-cartesian-grid line {
  stroke: var(--dark-border-primary);
}

.darkmode .recharts-text {
  fill: var(--dark-text-secondary);
}

/* Scrollbars */
.darkmode ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.darkmode ::-webkit-scrollbar-track {
  background: var(--dark-bg-primary);
}

.darkmode ::-webkit-scrollbar-thumb {
  background: var(--dark-bg-tertiary);
  border-radius: 5px;
}

.darkmode ::-webkit-scrollbar-thumb:hover {
  background: var(--dark-bg-hover);
}

/* Transitions */
.app.darkmode,
.darkmode * {
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Team Performance Grid - Base styles */
.report-manager .team-performance-grid {
  background-color: #ffffff;
}

/* Team Performance Grid - Dark mode */
.report-manager .team-performance-grid.darkmode {
  background-color: var(--dark-bg-primary);
}

/* Team Member Card - Base styles */
.report-manager .team-performance-grid .team-member-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.2rem;
  transition: all 0.2s ease;
  margin: 0.8rem;
  min-height: 320px;
  display: flex;
  flex-direction: column;

}

/* Team Member Card - Hover effects */
.report-manager .team-performance-grid .team-member-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Team Member Card - Dark mode */
.report-manager .team-performance-grid.darkmode .team-member-card,
.report-manager .team-performance-grid .team-member-card.darkmode {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Team Member Card - Dark mode hover */
.report-manager .team-performance-grid.darkmode .team-member-card:hover,
.report-manager .team-performance-grid .team-member-card.darkmode:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Member Header - Dark mode */
.report-manager .team-performance-grid .member-header.darkmode {
  border-color: var(--dark-border-primary);
}

/* Role - Dark mode */
.report-manager .team-performance-grid .role.darkmode {
  color: var(--dark-text-secondary);
}

/* AdminPanel dark mode styles */
.admin-panel.darkmode {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
}

.admin-sidebar.darkmode {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}

.admin-content.darkmode {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
}

.sidebar-header.darkmode {
  border-color: var(--dark-border-primary);
}

.sidebar-header.darkmode h2 {
  color: var(--dark-text-primary);
}

.content-header.darkmode {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.content-header.darkmode h2 {
  color: var(--dark-text-primary);
}

.admin-sidebar.darkmode button {
  color: var(--dark-text-secondary);
  background-color: transparent;
  border: none;
}

.admin-sidebar.darkmode button:hover {
  background-color: var(--dark-bg-hover);
  color: var(--dark-accent);
}

.admin-sidebar.darkmode button.active {
  background-color: var(--dark-bg-hover);
  color: var(--dark-accent);
  font-weight: 500;
}

/* Grid items in dark mode */
.grid-container.darkmode {
  background-color: var(--dark-bg-secondary);
}

.grid-item.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
}

/* Stats cards in dark mode */
.stats-container.darkmode {
  background-color: var(--dark-bg-secondary);
}

.stat-card.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.stat-value.darkmode {
  color: var(--dark-text-primary);
}

.stat-label.darkmode {
  color: var(--dark-text-secondary);
}

/* Tables in dark mode */
.table-container.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
}

.darkmode table {
  color: var(--dark-text-primary);
}

.darkmode th {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-secondary);
  border-color: var(--dark-border-primary);
}

.darkmode td {
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.darkmode tr:hover {
  background-color: var(--dark-bg-hover);
}

/* Forms in dark mode */
.form-group.darkmode label {
  color: var(--dark-text-primary);
}

.form-control.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.form-control.darkmode:focus {
  border-color: var(--dark-accent);
  box-shadow: 0 0 0 2px rgba(243, 163, 33, 0.1);
}

/* Buttons in dark mode */
.btn.darkmode {
  color: var(--dark-text-primary);
}

.btn-primary.darkmode {
  background-color: var(--dark-accent);
  border: none;
}

.btn-secondary.darkmode {
  background-color: transparent;
  border-color: var(--dark-accent);
  color: var(--dark-accent);
}

/* Team member card in dark mode */
.member-name.darkmode {
  color: var(--dark-text-primary);
}

.member-role.darkmode {
  color: var(--dark-text-secondary);
}

.member-stat.darkmode {
  color: var(--dark-text-secondary);
}

.stat-number.darkmode {
  color: var(--dark-text-primary);
}

.stat-title.darkmode {
  color: var(--dark-text-secondary);
}

.loading-spinner.darkmode {
  border-color: var(--dark-border-primary);
  border-top-color: var(--dark-accent);
}

/* Chart dark mode styles */
.darkmode .chart-container {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
}

.darkmode .chart-title {
  color: var(--dark-text-primary);
}

.darkmode .chart-legend {
  color: var(--dark-text-secondary);
}

/* Form controls dark mode */
.darkmode select,
.darkmode input {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.darkmode select:focus,
.darkmode input:focus {
  border-color: var(--dark-accent);
  box-shadow: 0 0 0 2px rgba(243, 163, 33, 0.1);
}

/* Table dark mode */
.darkmode table {
  background-color: var(--dark-bg-tertiary);
  color: var(--dark-text-primary);
  border-color: var(--dark-border-primary);
}

.darkmode th {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-secondary);
  border-color: var(--dark-border-primary);
}

.darkmode td {
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.darkmode tr:hover {
  background-color: var(--dark-bg-hover);
}

/* Roadmap Container - Dark mode */
.roadmap-container.darkmode {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
  border-color: var(--dark-border-primary);
}

.roadmap-container.darkmode .roadmap-header {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
}

.roadmap-container.darkmode .roadmap-content {
  background-color: var(--dark-bg-secondary);
}

.roadmap-container.darkmode .roadmap-item {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
}

.roadmap-container.darkmode .roadmap-item:hover {
  background-color: var(--dark-bg-hover);
}

.roadmap-container.darkmode .roadmap-milestone {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.roadmap-container.darkmode .roadmap-timeline {
  border-color: var(--dark-border-primary);
}

.roadmap-container.darkmode .milestone-marker {
  background-color: var(--dark-accent);
  border-color: var(--dark-border-primary);
}

.roadmap-container.darkmode .milestone-label {
  color: var(--dark-text-secondary);
}

.roadmap-container.darkmode .milestone-date {
  color: var(--dark-text-secondary);
}

/* Roadmap Header */
.roadmap-header.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
}

/* Filter Panels */
.filter-panel.darkmode,
.advanced-filter-panel.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
}

.filter-panel.darkmode select,
.advanced-filter-panel.darkmode select,
.filter-panel.darkmode input,
.advanced-filter-panel.darkmode input {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

/* Epic Items */
.epic-item.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.epic-item.darkmode:hover {
  background-color: var(--dark-bg-hover);
}

.epic-item.darkmode .epic-header {
  color: #ffffff;
  border-color: var(--dark-border-primary);
}

.epic-item.darkmode .epic-indicators {
  color: var(--dark-text-secondary);
}

/* Priority and Status Indicators */
.epic-item.darkmode.priority-high {
  border-left-color: #ff4d4d;
}

.epic-item.darkmode.priority-medium {
  border-left-color: #ffa64d;
}

.epic-item.darkmode.priority-low {
  border-left-color: #4da6ff;
}

.epic-item.darkmode.status-completed {
  border-right-color: #4dff4d;
}

.epic-item.darkmode.status-in-progress {
  border-right-color: #ffd700;
}

.epic-item.darkmode.status-pending {
  border-right-color: #808080;
}

/* Timeline View */
.timeline-groups.darkmode {
  background-color: var(--dark-bg-secondary);
}

.timeline-groups.darkmode .period-header {
  color: var(--dark-text-primary);
  border-color: var(--dark-border-primary);
}

/* Gantt View */
.gantt-view.darkmode {
  background-color: var(--dark-bg-secondary);
}

/* Toolbar */
.toolbar-buttons.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
}

.toolbar-buttons.darkmode button {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
  border-color: var(--dark-border-primary);
}

.toolbar-buttons.darkmode button:hover {
  background-color: var(--dark-bg-hover);
}

/* Messages */
.import-error.darkmode,
.error-message.darkmode {
  background-color: var(--dark-bg-tertiary);
  color: #ff4d4d;
  border-color: #ff4d4d;
}

.loading-indicator.darkmode {
  color: var(--dark-text-secondary);
}

/* Project Analytics - Dark Mode */
.project-analytics.darkmode {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
  border-color: var(--dark-border-primary);
}

/* Analytics Header */
.analytics-header.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
}

/* Analytics Cards */
.analytics-card.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.analytics-card.darkmode:hover {
  background-color: var(--dark-bg-hover);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.analytics-card.darkmode .card-title {
  color: var(--dark-text-secondary);
}

.analytics-card.darkmode .card-value {
  color: var(--dark-text-primary);
}

/* Analytics Charts */
.analytics-chart.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
  padding: 1.5rem;
}

.analytics-chart.darkmode .chart-title {
  color: var(--dark-text-primary);
}

/* Analytics Grid */
.analytics-grid.darkmode {
  background-color: var(--dark-bg-secondary);
}

/* Analytics Metrics */
.metrics-container.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
}

.metric-item.darkmode {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
}

.metric-item.darkmode:hover {
  background-color: var(--dark-bg-hover);
}

.metric-item.darkmode .metric-title {
  color: var(--dark-text-secondary);
}

.metric-item.darkmode .metric-value {
  color: var(--dark-text-primary);
}

/* Analytics Table */
.analytics-table.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
}

.analytics-table.darkmode th {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-secondary);
  border-color: var(--dark-border-primary);
}

.analytics-table.darkmode td {
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.analytics-table.darkmode tr:hover {
  background-color: var(--dark-bg-hover);
}

/* Analytics Filters */
.analytics-filters.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
}

.analytics-filters.darkmode select,
.analytics-filters.darkmode input {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.analytics-filters.darkmode button {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.analytics-filters.darkmode button:hover {
  background-color: var(--dark-bg-hover);
}

/* Progress Indicators */
.progress-bar.darkmode {
  background-color: var(--dark-bg-secondary);
}

.progress-bar.darkmode .progress {
  background-color: var(--dark-accent);
}

.progress-label.darkmode {
  color: var(--dark-text-secondary);
}

/* Status Indicators */
.status-indicator.darkmode.success {
  color: #4dff4d;
}

.status-indicator.darkmode.warning {
  color: #ffd700;
}

.status-indicator.darkmode.danger {
  color: #ff4d4d;
}

/* Trend Indicators */
.trend-up.darkmode {
  color: #4dff4d;
}

.trend-down.darkmode {
  color: #ff4d4d;
}

.trend-neutral.darkmode {
  color: var(--dark-text-secondary);
}

/* Activity Panel Dark Mode Styles */
.darkmode .activity-panel {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
  border-left: 1px solid var(--dark-border-color);
}

.darkmode .activity-panel-header {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
  border-bottom: 1px solid var(--dark-border-color);
}

.darkmode .activity-panel-tabs {
  background-color: var(--dark-bg-secondary);
  border-bottom: 1px solid var(--dark-border-color);
}

.darkmode .activity-panel-tabs button {
  color: var(--dark-text-secondary);
  background-color: transparent;
}

.darkmode .activity-panel-tabs button.active {
  color: var(--primary-color);
  border-bottom-color: var(--primary-color);
}

.darkmode .activity-panel-content {
  background-color: var(--dark-bg-primary);
}

.darkmode .activity-item {
  background-color: var(--dark-bg-secondary);
  border-bottom: 1px solid var(--dark-border-color);
}

.darkmode .activity-item:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.darkmode .activity-item-header {
  color: var(--dark-text-primary);
}

.darkmode .activity-item-details {
  color: var(--dark-text-secondary);
}

.darkmode .activity-item-time {
  color: var(--dark-text-tertiary);
}

.darkmode .activity-filter-section {
  background-color: var(--dark-bg-secondary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .activity-filter-section input,
.darkmode .activity-filter-section select {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .activity-filter-section button {
  background-color: var(--primary-color);
  color: white;
}

.darkmode .activity-empty-state {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-secondary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .activity-pagination {
  background-color: var(--dark-bg-secondary);
  border-top: 1px solid var(--dark-border-color);
}

.darkmode .activity-pagination button {
  background-color: transparent;
  color: var(--dark-text-secondary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .activity-pagination button:disabled {
  color: var(--dark-text-tertiary);
  border-color: var(--dark-border-color);
}

.darkmode .activity-pagination button:not(:disabled):hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Scrollbar for Dark Mode */
.darkmode .activity-panel-content::-webkit-scrollbar {
  width: 8px;
}

.darkmode .activity-panel-content::-webkit-scrollbar-track {
  background: var(--dark-bg-secondary);
}

.darkmode .activity-panel-content::-webkit-scrollbar-thumb {
  background-color: var(--dark-text-tertiary);
  border-radius: 4px;
}

.darkmode .activity-panel-content::-webkit-scrollbar-thumb:hover {
  background-color: var(--dark-text-secondary);
}

/* Activity Panel Header Dark Mode */
.darkmode .panel-header {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
  border-bottom: 1px solid var(--dark-border-color);
}

.darkmode .panel-header h2 {
  color: var(--dark-text-primary);
}

.darkmode .panel-header .panel-header-actions {
  background-color: transparent;
}

.darkmode .panel-header .panel-header-actions button {
  background-color: transparent;
  color: var(--dark-text-secondary);
  border: none;
}

.darkmode .panel-header .panel-header-actions button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--dark-text-primary);
}

.darkmode .panel-header .panel-header-actions button:disabled {
  color: var(--dark-text-tertiary);
  cursor: not-allowed;
}

.darkmode .panel-header .panel-header-tabs {
  background-color: var(--dark-bg-secondary);
  border-bottom: 1px solid var(--dark-border-color);
}

.darkmode .panel-header .panel-header-tabs button {
  color: var(--dark-text-secondary);
  background-color: transparent;
  border-bottom: 2px solid transparent;
}

.darkmode .panel-header .panel-header-tabs button.active {
  color: var(--primary-color);
  border-bottom-color: var(--primary-color);
}

.darkmode .panel-header .panel-header-tabs button:hover {
  color: var(--dark-text-primary);
  background-color: rgba(255, 255, 255, 0.05);
}

/* Comment Input Container Dark Mode */
.darkmode .comment-input-container {
  background-color: var(--dark-bg-secondary);
  border-top: 1px solid var(--dark-border-color);
}

.darkmode .comment-input-container textarea {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
  border: 1px solid var(--dark-border-color);
  placeholder-color: var(--dark-text-secondary);
}

.darkmode .comment-input-container textarea::placeholder {
  color: var(--dark-text-secondary);
}

.darkmode .comment-input-container .comment-input-actions {
  background-color: transparent;
}

.darkmode .comment-input-container .comment-input-actions button {
  background-color: var(--primary-color);
  color: white;
  border: none;
}

.darkmode .comment-input-container .comment-input-actions button:disabled {
  background-color: var(--dark-text-tertiary);
  color: var(--dark-bg-secondary);
  cursor: not-allowed;
}

.darkmode .comment-input-container .comment-input-actions .comment-action-buttons {
  background-color: transparent;
}

.darkmode .comment-input-container .comment-input-actions .comment-action-buttons button {
  background-color: transparent;
  color: var(--dark-text-secondary);
  border: none;
}

.darkmode .comment-input-container .comment-input-actions .comment-action-buttons button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--dark-text-primary);
}

.darkmode .comment-input-container .comment-input-attachments {
  background-color: var(--dark-bg-primary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .comment-input-container .comment-input-attachments .attachment-item {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-secondary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .comment-input-container .comment-input-attachments .attachment-item-remove {
  color: var(--danger-color);
}

/* Assets Page Dark Mode Styles */
.darkmode .asset-item {
  background-color: var(--dark-bg-secondary);
  border: 1px solid var(--dark-border-color);
  color: var(--dark-text-primary);
}

.darkmode .asset-item:hover {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: var(--primary-color);
}

.darkmode .asset-item-header {
  background-color: var(--dark-bg-primary);
  border-bottom: 1px solid var(--dark-border-color);
}

.darkmode .asset-item-header h3 {
  color: var(--dark-text-primary);
}

.darkmode .asset-item-details {
  color: var(--dark-text-secondary);
}

.darkmode .asset-item-actions {
  background-color: transparent;
}

.darkmode .asset-item-actions button {
  background-color: transparent;
  color: var(--dark-text-secondary);
  border: none;
}

.darkmode .asset-item-actions button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--dark-text-primary);
}

.darkmode .asset-item-status {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-secondary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .asset-item-status.active {
  background-color: var(--success-color);
  color: white;
}

.darkmode .asset-item-status.inactive {
  background-color: var(--danger-color);
  color: white;
}

.darkmode .asset-item-tags {
  background-color: transparent;
}

.darkmode .asset-item-tags .tag {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--dark-text-secondary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .asset-item-tags .tag:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--dark-text-primary);
}

.darkmode .asset-item-preview {
  background-color: var(--dark-bg-primary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .asset-item-preview img {
  filter: brightness(0.8);
}

.darkmode .asset-item-metadata {
  color: var(--dark-text-secondary);
}

.darkmode .asset-item-menu {
  background-color: var(--dark-bg-secondary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .asset-item-menu button {
  color: var(--dark-text-secondary);
  background-color: transparent;
}

.darkmode .asset-item-menu button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--dark-text-primary);
}

/* Shot Item Dark Mode Styles */
.darkmode .shot-item {
  background-color: var(--dark-bg-secondary);
  border: 1px solid var(--dark-border-color);
  color: var(--dark-text-primary);
}

.darkmode .shot-item:hover {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: var(--primary-color);
}

.darkmode .shot-item-header {
  background-color: var(--dark-bg-primary);
  border-bottom: 1px solid var(--dark-border-color);
}

.darkmode .shot-item-header h3 {
  color: var(--dark-text-primary);
}

.darkmode .shot-item-details {
  color: var(--dark-text-secondary);
}

.darkmode .shot-item-preview {
  background-color: var(--dark-bg-primary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .shot-item-preview img {
  filter: brightness(0.8);
}

.darkmode .shot-item-metadata {
  color: var(--dark-text-secondary);
}

.darkmode .shot-item-status {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-secondary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .shot-item-status.in-progress {
  background-color: var(--warning-color);
  color: white;
}

.darkmode .shot-item-status.completed {
  background-color: var(--success-color);
  color: white;
}

.darkmode .shot-item-status.pending {
  background-color: var(--info-color);
  color: white;
}

.darkmode .shot-item-actions {
  background-color: transparent;
}

.darkmode .shot-item-actions button {
  background-color: transparent;
  color: var(--dark-text-secondary);
  border: none;
}

.darkmode .shot-item-actions button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--dark-text-primary);
}

.darkmode .shot-item-tags {
  background-color: transparent;
}

.darkmode .shot-item-tags .tag {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--dark-text-secondary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .shot-item-tags .tag:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--dark-text-primary);
}

.darkmode .shot-item-menu {
  background-color: var(--dark-bg-secondary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .shot-item-menu button {
  color: var(--dark-text-secondary);
  background-color: transparent;
}

.darkmode .shot-item-menu button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--dark-text-primary);
}

.darkmode .shot-item-progress-bar {
  background-color: var(--dark-bg-primary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .shot-item-progress-bar .progress {
  background-color: var(--primary-color);
}

/* Shot Page Related Badges Dark Mode Styles */
.darkmode .shot-related-section {
  background-color: var(--dark-bg-secondary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .shot-related-section-header {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
  border-bottom: 1px solid var(--dark-border-color);
}

.darkmode .related-assets-badge,
.darkmode .related-tasks-badge {
  background-color: var(--dark-bg-primary);
  border: 1px solid var(--dark-border-color);
  color: var(--dark-text-secondary);
}

.darkmode .related-assets-badge:hover,
.darkmode .related-tasks-badge:hover {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: var(--primary-color);
}

.darkmode .related-assets-badge-header,
.darkmode .related-tasks-badge-header {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
  border-bottom: 1px solid var(--dark-border-color);
}

.darkmode .related-assets-badge-content,
.darkmode .related-tasks-badge-content {
  background-color: var(--dark-bg-primary);
}

.darkmode .related-assets-badge-item,
.darkmode .related-tasks-badge-item {
  border-bottom: 1px solid var(--dark-border-color);
  background-color: transparent;
}

.darkmode .related-assets-badge-item:hover,
.darkmode .related-tasks-badge-item:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.darkmode .related-assets-badge-item-title,
.darkmode .related-tasks-badge-item-title {
  color: var(--dark-text-primary);
}

.darkmode .related-assets-badge-item-details,
.darkmode .related-tasks-badge-item-details {
  color: var(--dark-text-secondary);
}

.darkmode .related-assets-badge-item-status,
.darkmode .related-tasks-badge-item-status {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-secondary);
  border: 1px solid var(--dark-border-color);
}

.darkmode .related-assets-badge-item-status.active,
.darkmode .related-tasks-badge-item-status.completed {
  background-color: var(--success-color);
  color: white;
}

.darkmode .related-assets-badge-item-status.inactive,
.darkmode .related-tasks-badge-item-status.pending {
  background-color: var(--warning-color);
  color: white;
}

.darkmode .related-assets-badge-actions,
.darkmode .related-tasks-badge-actions {
  background-color: transparent;
}

.darkmode .related-assets-badge-actions button,
.darkmode .related-tasks-badge-actions button {
  background-color: transparent;
  color: var(--dark-text-secondary);
  border: none;
}

.darkmode .related-assets-badge-actions button:hover,
.darkmode .related-tasks-badge-actions button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--dark-text-primary);
}

.darkmode .related-assets-badge-empty-state,
.darkmode .related-tasks-badge-empty-state {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-secondary);
  border: 1px solid var(--dark-border-color);
}

.shots-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  height: 100vh;
}

.shots-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}
.shots-header {
  text-align: left;
  color: black; /* Warna default */
  margin-bottom: 10px;
  font-size: 20px;
}

.header-buttons {
  display: flex;
  gap: 16px;
  align-items: center;
}

.add-shot-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.shot-form-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 500px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
}

.shot-list-thumbnail {
  width: 80px;
  height: 45px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.shot-form-thumbnail {
  max-width: 200px;
  max-height: 112px;
  object-fit: contain;
  border-radius: 4px;
  margin-top: 10px;
}

.thumbnail-preview {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
}

/* Dark mode */
.shot-container {
  display: flex;
  transition: margin-right 0.3s ease;
}

.shot-container.panel-open {
  margin-right: 500px;
}

.shot-table {
  flex: 1;
}

.shot-table table {
  width: 100%;
  border-collapse: collapse;
}

.shot-table th,
.shot-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Dark mode styles */

.shot-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

/* Dark mode header */

.shot-table tr:hover {
  background-color: #f9f9f9;
}

/* Dark mode select dan input */

/* Dark mode untuk react-select */

.shot-table button {
  margin: 0 4px;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.shot-table button:first-child {
  background-color: #f44336;
  color: white;
}

.shot-table button:last-child {
  background-color: #2196f3;
  color: white;
}

.overview-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

.overview-button:hover {
  background-color: #45a049;
}

.shot-detail-panel {
  position: fixed;
  right: 0;
  top: 60px;
  width: 500px;
  height: 100vh;
  background: rgb(100, 100, 100);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease;
}

.shot-detail-panel.open {
  transform: translateX(0);
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 5px 10px;
}

.close-button:hover {
  color: #333;
}

.shot-list-thumbnail {
  width: 80px;
  height: 45px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.shot-item {
background: #fff;
border-radius: 8px;
padding: 15px;
margin-bottom: 15px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
cursor: pointer;
transition: all 0.3s ease;
}

.shot-item:hover {
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.shot-header {
display: flex;
justify-content: space-between;
align-items: flex-start;
margin-bottom: 10px;
}

.shot-info {
display: flex;
align-items: flex-start;
flex: 1;
}

.shot-thumbnail {
flex-shrink: 0;
margin-right: 15px;
}

.shot-thumbnail img {
border-radius: 4px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.shot-details {
flex: 1;
}

.shot-name-container {
display: flex;
align-items: center;
margin-bottom: 5px;
}

.shot-name-container h3 {
margin: 0;
font-size: 1.1em;
font-weight: 600;
}

.shot-description {
margin: 0;
color: #666;
font-size: 0.9em;
}

.shot-actions {
display: flex;
gap: 8px;
}

.shot-actions button {
padding: 6px 12px;
border: none;
border-radius: 4px;
cursor: pointer;
font-size: 0.9em;
transition: all 0.2s ease;
}

.edit-button {
background: #4a90e2;
color: white;
}

.delete-button {
background: #e74c3c;
color: white;
}

.shot-footer {
margin-top: 10px;
font-size: 0.9em;
}

.related-assets,
.related-tasks {
margin-top: 8px;
}

.asset-tags,
.task-tags {
display: flex;
flex-wrap: wrap;
gap: 8px;
margin-top: 4px;
}

.asset-tag,
.task-tag {
background: #f0f0f0;
padding: 4px 8px;
border-radius: 12px;
font-size: 0.85em;
}

.status-badge {
display: inline-flex;
align-items: center;
padding: 2px 8px;
border-radius: 12px;
font-size: 0.8em;
font-weight: 500;
}

/* Form editing styles */
.shot-item.editing {
background: #f8f9fa;
border: 2px solid #4a90e2;
}

.shot-item.editing input,
.shot-item.editing textarea {
width: 100%;
padding: 8px;
border: 1px solid #ddd;
border-radius: 4px;
margin-bottom: 8px;
}

.shot-container {
padding: 20px;
transition: all 0.3s ease;
}

.shot-container.panel-open {
margin-right: 300px;
}

/* Global Header Dark Mode */
.darkmode .global-header {
  background-color: #1f2937;
  border-bottom-color: #374151;
}

.darkmode .global-header h2 {
  color: #f3f4f6;
}

.darkmode .global-header .search input {
  background-color: #111827;
  border-color: #374151;
  color: #f3f4f6;
}

.darkmode .global-header .search input:focus {
  border-color: #60a5fa;
  box-shadow: 0 0 0 2px rgba(96, 165, 250, 0.2);
}

.darkmode .global-header .search input::placeholder {
  color: #6b7280;
}

.darkmode .global-header .view-options .btn-secondary {
  background-color: #374151;
  border-color: #4b5563;
  color: #e5e7eb;
}

.darkmode .global-header .view-options .btn-secondary:hover {
  background-color: #4b5563;
  border-color: #6b7280;
}

.darkmode .global-header .view-options .btn-primary {
  background-color: #3b82f6;
  border-color: #2563eb;
  color: #ffffff;
}

.darkmode .global-header .view-options .btn-primary:hover {
  background-color: #2563eb;
  border-color: #1d4ed8;
}

/* Media Queries for Dark Mode */
@media (max-width: 768px) {
  .darkmode .global-header .search input {
    background-color: #111827;
  }
}

/* MyTasks Dark Mode */
.darkmode .dcc-panel {
  background-color: #1f2937;
  border-left-color: #374151;
}

@media (max-width: 768px) {
  .darkmode .dcc-panel {
    border-top-color: #374151;
  }
}

/* Status Badge Dark Mode */
.darkmode .status-badge.pending {
  background-color: rgba(254, 243, 199, 0.1);
  color: #FCD34D;
}

.darkmode .status-badge.in-progress {
  background-color: rgba(219, 234, 254, 0.1);
  color: #60A5FA;
}

.darkmode .status-badge.review {
  background-color: rgba(224, 231, 255, 0.1);
  color: #818CF8;
}

.darkmode .status-badge.approved {
  background-color: rgba(209, 250, 229, 0.1);
  color: #34D399;
}

.darkmode .status-badge.completed {
  background-color: rgba(187, 247, 208, 0.1);
  color: #4ADE80;
}

.darkmode .status-badge.rejected {
  background-color: rgba(254, 226, 226, 0.1);
  color: #F87171;
}

.darkmode .status-badge.on-hold {
  background-color: rgba(229, 231, 235, 0.1);
  color: #9CA3AF;
}

.darkmode .status-badge:hover {
  filter: brightness(1.1);
}
