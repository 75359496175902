/* Messenger Styles */

/* Emoji Picker */
.emoji-picker {
  position: absolute;
  background-color: var(--bg-card);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 5px;
  z-index: 100;
  box-shadow: var(--shadow-md);
  margin-top: 5px;
  min-width: 200px;
  overflow-x: auto;
}

.dark-mode .emoji-picker {
  background-color: var(--bg-card-dark);
  border-color: var(--border-color-dark);
}

.emoji-btn {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.emoji-btn:hover {
  background-color: var(--bg-hover);
}

.dark-mode .emoji-btn:hover {
  background-color: var(--bg-hover-dark);
}

/* Like Section */
.like-section {
  position: relative;
  display: inline-block;
}

.like-message-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: all 0.2s;
}

.like-message-btn.liked {
  transform: scale(1.1);
}

.emoji-counter {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
  max-width: 200px;
}

.emoji-count {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 0.8rem;
  background-color: var(--bg-secondary);
  padding: 2px 5px;
  border-radius: 10px;
  transition: transform 0.2s;
}

.emoji-count:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.dark-mode .emoji-count {
  background-color: var(--bg-secondary-dark);
}
