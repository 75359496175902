.gantt_task_line.in-progress {
    background-color: #ffA500;
  }
  
  .gantt_task_line.completed {
    background-color: #008000;
  }
  
  .gantt_task_line.pending {
    background-color: #ff0000;
  }
  
  .gantt-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    }
    .zoom-buttons,
    .export-buttons,
    .fullscreen-button,
    .undo-redo-buttons {
    display: flex;
    gap: 10px;
    }
    .gantt-controls button {
        padding: 5px 5px;
        background-color: #f0f0f0;
        border: 1px solid #606060;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
        }
    .gantt-controls button:hover {
    background-color: #e0e0e0;
    }
    .gantt-controls button:active {
    background-color: #d0d0d0;
    }
    .gantt_task_line.today {
    border-color: #FF0000;
    }

.gantt_grid_head_cell {
  cursor: col-resize;
}

.gantt_grid_head_cell:hover::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #ccc;
}

.gantt-task-text {
  position: absolute;
  top: 0;
  left: 5px;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.gantt-task-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
}

.gantt-task-progress-text {
  position: absolute;
  top: 0;
  right: 5px;
  height: 100%;
  font-weight: bold;
}

.search-filter {
  margin-bottom: 20px;
}

.search-filter input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.gantt-group-row {
  font-weight: bold;
  background-color: #f4f4f4;
}

.gantt_task_row.gantt-group-row {
  background-color: #f4f4f4;
}

.gantt_task_line.gantt-group-task {
  background-color: #ffab00;
  border-color: #ff9100;
}

.gantt_task_parent {
  font-weight: bold;
  background-color: #f4f4f4;
}

.gantt_task_line.gantt_parent_task {
  height: 4px;
  background-color: #666;
  border: 1px solid #555;
}

.gantt_row.gantt_parent_row {
  background-color: #f4f4f4;
  font-weight: bold;
}

.gantt_parent_task {
  background-color: #ffecb3;
  border-radius: 4px;
  border: 1px solid #ffd54f;
}

.gantt_parent_task .gantt_task_progress {
  background-color: #ffd54f;
}

.gantt_row.gantt_parent {
  font-weight: bold;
  background-color: #fff8e1;
}

.gantt_parent_row {
  background-color: #fff8e1;
}

.parent-task {
  background-color: #f4f4f4;
  border-left: 3px solid #2196F3;
  font-weight: bold;
}

.child-task {
  background-color: #ffffff;
  border-left: 2px solid #4CAF50;
}

.gantt_tree_icon.gantt_folder_open,
.gantt_tree_icon.gantt_folder_closed {
  color: #2196F3;
  font-weight: bold;
}

/* Dark mode untuk gantt chart */

/* Dark mode untuk row task */

/* Dark mode untuk timeline */

/* Mempertahankan warna status task */

/* Timeline styling */
.gantt_scale_cell {
  font-weight: 500;
  font-size: 12px;
}

.gantt_scale_cell.weekend {
  background-color: #f8f9fa;
}

/* Task styling */
.gantt_task_line {
  border-radius: 20px;
  height: 28px;
  line-height: 28px;
}

.gantt_task_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
}

.task_title {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.task_progress {
  background: rgba(255,255,255,0.2);
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 11px;
}

.task_assignee {
  background: rgba(255,255,255,0.2);
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 11px;
  margin-left: 4px;
}

/* Status colors */
.gantt_task_line.todo {
  background: #dfe1e6;
  border-color: #c1c7d0;
}

.gantt_task_line.in-progress {
  background: linear-gradient(45deg, #0052cc, #4c9aff);
  border-color: #0052cc;
}

.gantt_task_line.done {
  background: linear-gradient(45deg, #36b37e, #79f2c0);
  border-color: #36b37e;
}

/* Tooltip styling */
.gantt_tooltip {
  background-color: white;
  color: black !important;
  font-family: var(--font-family);
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 10px;
  border-radius: 4px;
}

.gantt_tooltip_title {
  font-weight: bold;
  margin-bottom: 5px;
  color: black;
}

.gantt_tooltip_line {
  color: black;
  margin: 2px 0;
}

/* Resource panel styling */
.gantt_resource_panel {
  background: #fff;
  border-left: 1px solid #cecece;
  box-shadow: -2px 0 4px rgba(0,0,0,0.1);
}

.resource-cell {
  padding: 6px 12px;
  border-bottom: 1px solid #e0e0e0;
}

.resource-workload {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 11px;
  background: #e3f2fd;
  color: #1976d2;
}

/* Dependency arrows styling */
.gantt_task_link {
  border-color: #2196F3;
}

.gantt_task_link .gantt_line_wrapper {
  background-color: #2196F3;
}

.gantt_task_link:hover .gantt_line_wrapper {
  box-shadow: 0 0 5px 0 #2196F3;
}

.gantt_link_arrow {
  border-color: #2196F3;
}

.gantt_link_control {
  background-color: #2196F3;
}

/* Dark mode adjustments */

/* Critical Path Styling */
.gantt_task_line.critical {
  background: linear-gradient(45deg, #ff4081, #ff6e40);
  border-color: #c51162;
  box-shadow: 0 0 5px rgba(197, 17, 98, 0.5);
}

.gantt_task_line.critical .gantt_task_content {
  color: white;
  font-weight: bold;
}

/* Resource Overallocation Indicator */
.gantt_task_line.overallocated {
  border: 2px dashed #ff5252;
}

.resource-load-indicator {
  position: absolute;
  right: -20px;
  top: -8px;
  background: #ff5252;
  color: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Risk Indicators */
.risk-indicator {
  position: absolute;
  right: -24px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.risk-indicator.LOW { background-color: #00C853; }
.risk-indicator.MEDIUM { background-color: #FFB300; }
.risk-indicator.HIGH { background-color: #D32F2F; }

/* Resource Capacity Panel */
.resource-capacity-panel {
  position: absolute;
  right: 0;
  top: 0;
  width: 250px;
  background: white;
  border-left: 1px solid #e0e0e0;
  height: 100%;
  overflow-y: auto;
}

.capacity-bar {
  height: 8px;
  border-radius: 4px;
  background: #e0e0e0;
  margin: 4px 0;
}

.capacity-bar-fill {
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.capacity-bar-fill.normal { background: #4CAF50; }
.capacity-bar-fill.warning { background: #FFC107; }
.capacity-bar-fill.overallocated { background: #F44336; }

/* Header dan Toolbar */
.gantt-chart-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
  overflow: hidden;
}

.gantt-container {
  overflow: auto;
  width: 100%;
  height: 100%;
}

/* Ensure the Gantt chart fits within its container */
.gantt_container {
  width: 100% !important;
  height: 100% !important;
  min-height: 400px;
}

/* Adjust the task grid to fit */
.gantt_grid {
  width: auto !important;
  transition: width 0.3s ease;
}

/* Make the timeline responsive */
.gantt_task {
  min-width: 1200px;
  width: auto;
  overflow-x: auto;
}

/* Ensure proper scrolling */
.gantt_hor_scroll, .gantt_ver_scroll {
  position: relative;
  height: 20px;
  background: #f0f0f0;
  z-index: 1;
}

.gantt_layout_content {
  min-width: 1200px;
  overflow-x: auto;
}

.gantt-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 12px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.toolbar-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

.toolbar-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: white;
  color: #424242;
  font-weight: 500;
  transition: all 0.2s ease;
}

.toolbar-button:hover {
  background: #f5f5f5;
  transform: translateY(-1px);
}

.toolbar-button .icon {
  font-size: 16px; /* Ukuran ikon */
}

.toolbar-button.active {
  background: #007bff;
  color: white;
  border-color: #0056b3;
}

/* Task Styling */
.gantt_task_line {
  border-radius: 20px;
  height: 32px;
  line-height: 32px;
  transition: all 0.3s ease;
}

.gantt_task_line:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.gantt_task_content {
  padding: 0 12px;
  font-size: 13px;
  font-weight: 500;
}

/* Progress Bar */
.gantt_task_progress {
  border-radius: 20px;
  background: rgba(255,255,255,0.2);
}

/* Progress colors */
.gantt_task_progress {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.gantt_task_line {
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

/* Progress color classes */
.high-progress .gantt_task_progress {
  background-color: #4CAF50 !important;
}

.medium-progress .gantt_task_progress {
  background-color: #FFC107 !important;
}

.low-progress .gantt_task_progress {
  background-color: #F44336 !important;
}

/* Task line colors based on progress */
.high-progress.gantt_task_line {
  background-color: rgba(76, 175, 80, 0.3);
}

.medium-progress.gantt_task_line {
  background-color: rgba(255, 193, 7, 0.3);
}

.low-progress.gantt_task_line {
  background-color: rgba(244, 67, 54, 0.3);
}

/* Status Colors */
.gantt_task_line.todo {
  background: linear-gradient(45deg, #E3F2FD, #90CAF9);
  border-color: #64B5F6;
}

.gantt_task_line.in-progress {
  background: linear-gradient(45deg, #FFF3E0, #FFB74D);
  border-color: #FFA726;
}

.gantt_task_line.completed {
  background: linear-gradient(45deg, #E8F5E9, #81C784);
  border-color: #66BB6A;
}

/* Dark Mode */

.advanced-filter-panel {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.filter-group {
  margin-bottom: 16px;
}

.filter-group h4 {
  margin-bottom: 8px;
  color: #333;
  font-size: 14px;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.range-slider {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.range-slider span {
  font-size: 12px;
  color: #666;
}

.date-range {
  display: flex;
  align-items: center;
  gap: 8px;
}

.date-range input {
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.apply-filters {
  background: #1976d2;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s;
}

.apply-filters:hover {
  background: #1565c0;
}

/* Dark mode */

.gantt-filter-panel {
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.filter-section {
  margin-bottom: 16px;
}

.filter-section h4 {
  margin-bottom: 8px;
  color: #424242;
  font-size: 14px;
}

.status-filters, .resource-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.filter-checkbox {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  background: #f5f5f5;
  cursor: pointer;
}

.progress-range {
  display: flex;
  align-items: center;
  gap: 8px;
}

.progress-range input[type="range"] {
  flex: 1;
}

.date-range {
  display: flex;
  align-items: center;
  gap: 8px;
}

.date-range input[type="date"] {
  padding: 4px 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

/* Dark mode */

.filter-panel {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.filter-group {
  display: flex;
  flex-direction: column;
}

.filter-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.filter-group select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.gantt-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.gantt-container {
  width: 100%;
  height: calc(100vh - 200px);
  background: var(--bg-card);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  transition: grid-template-columns 0.3s ease;
}

.gantt-controls {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background: var(--bg-card);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  margin-bottom: 1rem;
}

.zoom-buttons,
.fullscreen-button,
.export-buttons {
  display: flex;
  gap: 0.5rem;
}

.gantt-controls button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: none;
  border-radius: var(--border-radius);
  background: var(--bg-secondary);
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.gantt-controls button:hover {
  background: var(--bg-hover);
}

.dark-mode .gantt-container {
  background: var(--bg-card-dark);
}

.dark-mode .gantt-controls {
  background: var(--bg-card-dark);
}

.dark-mode .gantt-controls button {
  background: var(--bg-secondary-dark);
  color: var(--text-primary-dark);
}

.dark-mode .gantt-controls button:hover {
  background: var(--bg-hover-dark);
}

/* Filter Panel Styles */
.filters-panel {
  background: var(--bg-card);
  border-radius: var(--border-radius);
  padding: 1.5rem;
  margin: 0 1.5rem 1.5rem;
  box-shadow: var(--shadow);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.dark-mode .filters-panel {
  background: var(--bg-card-dark);
}

.progress-range {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.progress-range input {
  width: 80px;
}

.progress-range span {
  color: var(--text-secondary);
}

.dark-mode .progress-range span {
  color: var(--text-secondary-dark);
}

/* Today marker style */
.today {
  background: var(--primary);
  opacity: 0.8;
}

.dark-mode .today {
  opacity: 0.6;
}

.gantt-container {
  
  width: 100%;
  position: relative;
}

.gantt-chart {
  height: calc(100% - 50px);
  width: 100%;
}

.gantt-toolbar {
  padding: 10px;
  background: var(--background-color);
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.gantt-toolbar .left-section,
.gantt-toolbar .right-section {
  display: flex;
  gap: 10px;
  align-items: center;
}

.gantt-toolbar button {
  padding: 8px;
  background: var(--button-background);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--text-color);
  transition: all 0.2s;
}

.gantt-toolbar button:hover {
  background: var(--button-hover-background);
}

.gantt-toolbar button svg {
  width: 16px;
  height: 16px;
}

.gantt-toolbar .search {
  display: flex;
  align-items: center;
  gap: 10px;
}

.gantt-toolbar input {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--input-background);
  color: var(--text-color);
}

.filters-panel {
  background: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 15px;
  margin: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-weight: 500;
  color: var(--text-color);
}

.form-group input[type="date"] {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--input-background);
  color: var(--text-color);
}

.progress-range {
  display: flex;
  align-items: center;
  gap: 10px;
}

.progress-range input {
  width: 70px;
}

.progress-range span {
  color: var(--text-color);
}

/* Dark mode styles */
.dark-mode .gantt-toolbar,
.dark-mode .filters-panel {
  background: var(--dark-background);
  border-color: var(--dark-border-color);
}

.dark-mode .gantt-toolbar button {
  background: var(--dark-button-background);
  border-color: var(--dark-border-color);
  color: var(--dark-text-color);
}

.dark-mode .gantt-toolbar button:hover {
  background: var(--dark-button-hover-background);
}

.dark-mode .gantt-toolbar input,
.dark-mode .form-group input[type="date"] {
  background: var(--dark-input-background);
  border-color: var(--dark-border-color);
  color: var(--dark-text-color);
}

.dark-mode .form-group label,
.dark-mode .progress-range span {
  color: var(--dark-text-color);
}

/* React-select custom styles */
.select-container {
  width: 100%;
}

.select__control {
  background: var(--input-background) !important;
  border-color: var(--border-color) !important;
}

.select__single-value {
  color: var(--text-color) !important;
}

.select__menu {
  background: var(--background-color) !important;
  border: 1px solid var(--border-color);
}

.select__option {
  background: var(--background-color) !important;
  color: var(--text-color) !important;
}

.select__option--is-focused {
  background: var(--button-hover-background) !important;
}

/* Dark mode react-select */
.dark-mode .select__control {
  background: var(--dark-input-background) !important;
  border-color: var(--dark-border-color) !important;
}

.dark-mode .select__single-value {
  color: var(--dark-text-color) !important;
}

.dark-mode .select__menu {
  background: var(--dark-background) !important;
  border-color: var(--dark-border-color);
}

.dark-mode .select__option {
  background: var(--dark-background) !important;
  color: var(--dark-text-color) !important;
}

.dark-mode .select__option--is-focused {
  background: var(--dark-button-hover-background) !important;
}

/* Status styles in grid */
.gantt-status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
}

.gantt-status.pending {
  background-color: rgba(33, 150, 243, 0.2);
  color: #1565C0;
}

.gantt-status.in-progress {
  background-color: rgba(255, 152, 0, 0.2);
  color: #EF6C00;
}

.gantt-status.completed {
  background-color: rgba(76, 175, 80, 0.2);
  color: #2E7D32;
}

.gantt-status.on-hold {
  background-color: rgba(156, 39, 176, 0.2);
  color: #7B1FA2;
}

.gantt-status.cancelled {
  background-color: rgba(244, 67, 54, 0.2);
  color: #D32F2F;
}

.gantt_grid, .gantt_task {
  min-height: 500px;
}

.select__multi-value {
  background-color: #e3f2fd;
  border-radius: 4px;
  margin: 2px;
}

.select__multi-value__label {
  color: #1976d2;
  padding: 2px 6px;
}

.select__multi-value__remove {
  color: #1976d2;
  padding: 2px 6px;
}

.select__multi-value__remove:hover {
  background-color: #bbdefb;
  color: #0d47a1;
}

.select-container {
  min-width: 200px;
}

.column-controls {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
}

.column-controls .select-container {
  width: 700px;
}
