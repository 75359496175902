.activity-panel {
  position: fixed;
  right: -450px;
  top: 60px;
  width: 450px;
  height: calc(100vh - 70px);
  background: #ffffff;
  box-shadow: -4px 0 15px rgba(0, 0, 0, 0.1);
  transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  
}

.activity-panel.open {
  right: 0;
}

.activity-panel.dark {
  background: #1a1a1a;
  color: #ffffff;
}

.toggle-button {
  position: absolute;
  left: -32px;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  min-height: 80px;
  background: #ffffff;
  border: none;
  border-radius: 4px 0 0 4px;
  box-shadow: -4px 0 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1001;
  padding: 12px 0;
}

.toggle-button:hover {
  background: #f8f9fa;
  transform: translateY(-50%) translateX(-2px);
}

.toggle-button.dark {
  background: #1a1a1a;
  color: #ffffff;
  border: 1px solid #2d2d2d;
}

.toggle-button.dark:hover {
  background: #2d2d2d;
}

.toggle-button svg {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.activity-panel:not(.open) .toggle-button svg {
  transform: rotate(180deg);
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  white-space: nowrap;
  text-transform: uppercase;
}

.activity-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.activity-list {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.comment-section {
  position: sticky;
  bottom: 0;
  padding: 20px;
  background: #ffffff;
  border-top: 1px solid #e9ecef;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
}

.comment-section textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  resize: none;
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.button-group button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background: #007bff;
  color: white;
  cursor: pointer;
  transition: background 0.2s;
}

.button-group button:hover {
  background: #0056b3;
}

/* Dark mode styles */

.toggle-panel-button {
  position: fixed;
  right: 450px;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffff;
  border: 1px solid #e9ecef;
  border-right: none;
  padding: 15px 10px;
  border-radius: 8px 0 0 8px;
  cursor: pointer;
  z-index: 999;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.toggle-panel-button.panel-closed {
  right: 450px;
}

/* Dark mode untuk tombol */
.toggle-panel-button.dark {
  background: #2d2d2d;
  color: #ffffff;
  border-color: #404040;
}

.panel-header {
  padding: 20px;
  background: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel-header h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #212529;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #6c757d;
  cursor: pointer;
  padding: 5px;
  transition: color 0.2s;
}

.close-button:hover {
  color: #343a40;
}

.panel-content {
  
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 120px;
  margin-bottom: 80px;
}

.status-section {
  margin-bottom: 20px;
}

.status-badge {
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 8px;
  gap: 4px;
}

.status-badge .abbreviation {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  margin-right: 4px;
}

.description-section {
  margin-bottom: 30px;
}

.description-section h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #495057;
  margin-bottom: 10px;
}

.description-section p {
  color: #6c757d;
  line-height: 1.5;
}

.activities-section h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #495057;
  margin-bottom: 15px;
}

.activities-list {
  padding-bottom: 100px;
  overflow-y: auto;
  max-height: calc(100vh - 300px);
}

.activities-section {
  flex: 1;
  overflow-y: auto;
  position: relative;
  padding-bottom: 80px;
}

.activity-item {
  position: relative;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: white;
  transition: all 0.3s ease;
}

.activity-item.pinned {
  border: 2px solid #ffd700;
  order: -1;
  background-color: #fffef5;
}

.pin-indicator {
  margin-right: 8px;
  font-size: 1.2em;
}

.activity-list {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.activity-item.has-annotations {
  border-color: #ffd700;  /* Warna kuning */
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);  /* Glow effect kuning */
}

.activity-item.pinned {
  background: rgba(255, 193, 7, 0.15);
}

.activity-item.video-activity {
  background: rgba(25, 118, 210, 0.1);
}

.activity-item.comment {
  background: rgba(134, 107, 62, 0.8);  /* Background hijau gelap untuk comment */
  color: white;
}

.activity-item.comment .activity-content,
.activity-item.comment .user-name,
.activity-item.comment .timestamp,
.activity-item.comment .activity-header {
  color: white;
}

.activity-item.pinned.comment {
  background: linear-gradient(
    135deg, 
    rgba(255, 193, 7, 0.8) 0%, 
    rgba(34, 139, 34, 0.8) 100%
  );  /* Gradient untuk kombinasi pin dan comment */
  color: white;
}

.activity-item.pinned.video-activity {
  background: linear-gradient(
    135deg, 
    rgba(255, 193, 7, 0.15) 0%, 
    rgba(25, 118, 210, 0.1) 100%
  );
}

.activity-item.pinned.video-activity.has-annotations {
  border-color: #ffd700;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
  background: linear-gradient(
    135deg, 
    rgba(255, 193, 7, 0.25) 0%, 
    rgba(25, 118, 210, 0.2) 100%
  );
}

.activity-thumbnail {
  flex-shrink: 0;
  width: 120px;
  height: 80px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.activity-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.activity-content {
  flex: 1;
}

.activity-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.activity-user-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.activity-user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--border-color);
  background-color: var(--bg-secondary);
}

.darkmode .activity-user-avatar {
  border-color: var(--dark-border-color);
  background-color: var(--dark-bg-secondary);
}

.activity-user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.activity-username {
  font-weight: 500;
  color: var(--text-primary);
}

.darkmode .activity-username {
  color: var(--dark-text-primary);
}

.activity-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.action-button {
  background: none;
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
}

.action-button:hover {
  background: rgba(0, 0, 0, 0.05);
}

.pin-button {
  color: #0d6efd;
}

.delete-button {
  color: #dc3545;
}

.pin-indicator,
.annotation-indicator {
  font-size: 16px;
  line-height: 1;
  cursor: help;
}

.annotation-indicator {
  position: relative;
}

.annotation-indicator::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
}

.annotation-indicator:hover::after {
  opacity: 1;
  visibility: visible;
}

/* Dark mode styles */

.comment-content {
  color: #212529;
  line-height: 1.5;
}

.comment-actions {
  margin-top: 10px;
  display: flex;
  gap: 8px;
}

.comment-actions button {
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  background: #e9ecef;
  color: #495057;
  transition: all 0.2s;
}

.comment-actions button:hover {
  background: #dee2e6;
}

.comment-input {
  margin-top: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

.comment-input textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  resize: vertical;
  min-height: 80px;
  margin-bottom: 10px;
  font-family: inherit;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.action-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
}

.action-buttons button:last-child {
  background: #0d6efd;
  color: white;
}

.action-buttons button:last-child:hover {
  background: #0b5ed7;
}

/* Dark mode styles */

.comment-input-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  padding: 15px;
  border-top: 1px solid #e9ecef;
  z-index: 1000;
}

.comment-input {
  background: transparent;
  padding: 0;
}

.comment-input textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  resize: vertical;
  min-height: 60px;
  max-height: 120px;
  margin-bottom: 10px;
  font-family: inherit;
}

.preview-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  aspect-ratio: 16/9;
  margin: 10px auto;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
}

.preview-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.preview-container:hover img {
  transform: scale(1.05);
}

.preview-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.preview-container:hover .preview-overlay {
  opacity: 1;
}

.play-button {
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-button .icon {
  color: white;
  font-size: 24px;
}

.video-info-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  color: white;
}

.video-info-overlay .version {
  font-weight: bold;
  margin-right: 10px;
}

.video-info-overlay .filename {
  opacity: 0.8;
}

.video-description {
  margin-top: 10px;
  color: #666;
  font-size: 14px;
}

.activity-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.pin-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  opacity: 0.6;
  transition: opacity 0.2s;
  font-size: 1.2rem;
}

.pin-button:hover {
  opacity: 1;
}

.activity-item.pinned {
  border-left: 4px solid #007bff;
  background: rgba(0, 123, 255, 0.05);
}

.pin-indicator {
  color: #007bff;
  margin-right: 8px;
  font-size: 0.9rem;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  opacity: 0.6;
  transition: opacity 0.2s;
}

.delete-button:hover {
  opacity: 1;
}

.edit-details {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  padding: 10px;
  margin: 10px 0;
}

.before-after {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.before, .after {
  padding: 10px;
  border-radius: 4px;
}

.before {
  background: rgba(255, 0, 0, 0.05);
}

.after {
  background: rgba(0, 255, 0, 0.05);
}

.before h4, .after h4 {
  margin: 0 0 10px 0;
  color: #666;
}

.before p, .after p {
  margin: 5px 0;
  font-size: 14px;
}

.activity-panel.dark {
  background-color: #1a1a1a;
  color: #ffffff;
}

.panel-header.dark {
  background-color: #2d2d2d;
  border-bottom: 1px solid #404040;
}

.panel-content.dark {
  background-color: #504f4f;
}

.activities-section.dark {
  background-color: #1a1a1a;
}

.activities-list.dark {
  background-color: #1a1a1a;
}

.comment-input-container.dark {
  background-color: #2d2d2d;
  border-top: 1px solid #404040;
}

.comment-input.dark textarea {
  background-color: #333333;
  color: #ffffff;
  border: 1px solid #404040;
}

.comment-input.dark textarea::placeholder {
  color: #888888;
}

.button-container.dark button {
  background-color: #4a4a4a;
  color: #ffffff;
  border: 1px solid #404040;
}

.button-container.dark button:hover {
  background-color: #5a5a5a;
}

.close-button.dark {
  color: #ffffff;
  background-color: #2d2d2d;
}

.close-button.dark:hover {
  background-color: #404040;
}

.panel-open {
  margin-right: 450px;
  transition: margin-right 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.hashtag {
  display: inline-block;
  margin: 0 4px;
  padding: 2px 6px;
  background-color: #e3f2fd;
  color: #1976d2;
  border-radius: 4px;
  font-size: 0.9em;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.hashtag:hover {
  background-color: #bbdefb;
}

.activity-text {
  margin-bottom: 8px;
  word-break: break-word;
}

.activity-text .hashtag {
  margin-left: 4px;
}

.item-details {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.item-thumbnail {
  
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
  background: #e9ecef;
  flex-shrink: 0;
}

.item-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-info {
  flex: 1;
}

.item-title {
  font-size: 0.9 rem;
  font-weight: 600;
  color: #dbe2e9;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.item-type-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

.type-sequence {
  background: #e3f2fd;
  color: #1565c0;
}

.type-shot {
  background: #f3e5f5;
  color: #7b1fa2;
}

.type-asset {
  background: #e8f5e9;
  color: #2e7d32;
}

.type-task {
  background: #fff3e0;
  color: #e65100;
}

.item-description {
  color: #6c757d;
  margin-bottom: 12px;
  line-height: 1.5;
}

.item-meta {
  display: flex;
  gap: 20px;
  align-items: center;
}

.item-status {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  gap: 6px;
}

.item-status .status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.report-issue {
  background-color: #E74C3C;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s;
}

.report-issue:hover {
  background-color: #C53030 !important; /* Darker red on hover */
}

.report-issue:active {
  background-color: #9B2C2C !important; /* Even darker red when clicked */
}

.report-issue:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(229, 62, 62, 0.5);
}

.user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid var(--border-color);
  background-color: var(--bg-secondary);
}

.darkmode .user-avatar {
  border-color: var(--dark-border-color);
  background-color: var(--dark-bg-secondary);
}

.user-avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hashtag-badge {
  display: inline-block;
  padding: 2px 8px;
  margin: 2px 4px;
  background-color: rgba(25, 118, 210, 0.1);
  color: #1976d2;
  border-radius: 12px;
  font-size: 0.9em;
  font-weight: 500;
  transition: background-color 0.2s;
  cursor: pointer;
}

.dark .hashtag-badge {
  background-color: rgba(64, 169, 255, 0.2);
  color: #40a9ff;
}

.hashtag-badge:hover {
  background-color: rgba(25, 118, 210, 0.2);
}

.dark .hashtag-badge:hover {
  background-color: rgba(64, 169, 255, 0.3);
}

.hashtag-suggestions {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.dark .hashtag-suggestions {
  background: #2d2d2d;
  border-color: #404040;
}

.hashtag-suggestion-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.hashtag-suggestion-item:hover {
  background-color: #f5f5f5;
}

.dark .hashtag-suggestion-item:hover {
  background-color: #404040;
}

.comment-form {
  position: relative;
}

.user-avatar-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  font-weight: 500;
  font-size: 14px;
}

.darkmode .user-avatar-placeholder {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
}

.upload-progress-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
}

.progress-bar {
  height: 10px;
  background: #4CAF50;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: width 0.3s;
}

.progress-text {
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}

.upload-controls {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.upload-controls button {
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.btn-pause {
  background: #FFC107;
  color: #000;
}

.btn-resume {
  background: #2196F3;
  color: white;
}

.btn-cancel {
  background: #F44336;
  color: white;
}
