.messenger-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.messenger-page.dark-mode {
  background-color: #1a1a1a;
  color: #f5f5f5;
}

.messenger-page-header {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.messenger-page.dark-mode .messenger-page-header {
  background-color: #2a2a2a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.back-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  margin-right: 20px;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.messenger-page.dark-mode .back-button {
  color: #f5f5f5;
}

.back-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.messenger-page.dark-mode .back-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.back-button svg {
  margin-right: 5px;
}

.messenger-page-header h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}

.messenger-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* Untuk tampilan messenger di halaman */
.messenger-container .messenger {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
 
  max-height: 800px !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.messenger-page.dark-mode .messenger-container .messenger {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}
