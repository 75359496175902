/* CSS Variables */
:root {
  /* Light Mode Colors */
  --primary: #2563eb;
  --secondary: #64748b;
  --success: #22c55e;
  --warning: #f59e0b;
  --danger: #ef4444;
  --info: #3b82f6;
  
  --text-primary: #1e293b;
  --text-secondary: #64748b;
  --text-muted: #94a3b8;
  
  --bg-primary: #ffffff;
  --bg-secondary: #f1f5f9;
  --bg-card: #ffffff;
  --bg-hover: #f8fafc;
  
  --border-color: #e2e8f0;
  --border-radius: 8px;
  
  /* Dark Mode Colors */
  --bg-primary-dark: #0f172a;
  --bg-secondary-dark: #1e293b;
  --bg-card-dark: #1e293b;
  --bg-hover-dark: #334155;
  
  --text-primary-dark: #f1f5f9;
  --text-secondary-dark: #cbd5e1;
  --text-muted-dark: #94a3b8;
  
  --border-color-dark: #334155;
  
  /* Shadows */
  --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

/* Base Styles */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #1e293b;
  line-height: 1.5;
}

/* Common Container Styles */
.container {
  max-width: 100%;
  margin: 0 auto;
  
}

/* Global Header Style */
.global-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background: #ffffff;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 1.5rem;
}

.global-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.global-header .actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.view-controls {
  display: flex;
  gap: 0.5rem;
}

.view-controls .btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.form-select {
  padding: 0.5rem 2rem 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: var(--border-radius);
  border: 1px solid #e2e8f0;
  background: #ffffff;
  color: #1e293b;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
}

.form-select:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

/* Dark Mode Header */
.dark-mode .global-header {
  background: #1e293b;
  border-color: #334155;
}

.dark-mode .global-header h2 {
  color: #f1f5f9;
}

.dark-mode .form-select {
  background-color: #0f172a;
  border-color: #334155;
  color: #f1f5f9;
}

.dark-mode .form-select:focus {
  border-color: #2563eb;
}

/* App Header */
.app-header {
  background-color: #2563eb;
  padding: 1rem 0;
  color: white;
  box-shadow: var(--shadow);
}

.app-header h1 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

/* Navigation */
.nav-menu {
  background-color: #64748b;
  padding: 0.75rem 0;
}

.nav-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 1rem;
}

.nav-menu a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  transition: background-color 0.2s ease;
}

.nav-menu a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Cards and Panels */
.card {
  background-color: #918f8f;
  border-radius: 0.5rem;
  box-shadow: var(--shadow);
  padding: 1rem;
  margin-bottom: 1rem;
  transition: all 0.2s ease;
}

.card:hover {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

/* Progress Bar */
.progress-bar {
  width: 100%;
  height: 0.5rem;
  background-color: #e2e8f0;
  border-radius: 9999px;
  overflow: hidden;
}

.progress-bar-fill {
  height: 100%;
  background-color: #2563eb;
  transition: width 0.2s ease;
}

.progress-text {
  font-size: 0.875rem;
  color: #1e293b;
  margin-top: 0.25rem;
}

/* Status Section */
.status-section {
  background-color: #f1f5f9;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

/* Button Styles */
.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.95rem;
}

.btn-primary {
  background-color: #2563eb;
  color: white;
}

.btn-secondary {
  background-color: #64748b;
  color: white;
}

.btn-danger {
  background-color: #ef4444;
  color: white;
}

.btn:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

/* Table Styles */
.table-container {
  flex: 1;
  overflow-x: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}

.data-table th {
  background-color: #f1f5f9;
  font-weight: 600;
}

.data-table tr:hover {
  background-color: #f8fafc;
}

/* Detail Panel Styles */
.detail-panel {
  position: fixed;
  right: 0;
  top: 60px;
  width: 500px;
  height: 100vh;
  background: #f1f5f9;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease;
}

.detail-panel.open {
  transform: translateX(0);
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e2e8f0;
}

/* Thumbnail Styles */
.card-thumbnail,
.thumbnail-container {
  width: 100%;
  height: 100px;
  max-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.thumbnail-image,
.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}

.card:hover .thumbnail-image,
.card:hover .thumbnail {
  transform: scale(1.05);
}

.thumbnail {
  width: 80px;
  height: 45px;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.thumbnail-preview {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background: #f1f5f9;
}

/* Status Badge Styles */
.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  min-width: 80px;
  text-align: center;
}

.status-badge.unknown {
  background-color: #718096;
  color: white;
}

.status-badge.draft {
  background-color: #E6FFFA;
  color: #319795;
}

.status-badge.in-progress {
  background-color: #2563eb;
  color: white;
}

.status-badge.pending {
  background-color: #f59e0b;
  color: white;
}

.status-badge.completed {
  background-color: #22c55e;
  color: white;
}

.status-badge.blocked {
  background-color: #FFF5F5;
  color: #E53E3E;
}

.status-badge.review {
  background-color: #3b82f6;
  color: white;
}

.status-badge.approved {
  background-color: #22c55e;
  color: white;
}

.status-badge.rejected {
  background-color: #ef4444;
  color: white;
}

.status-badge.on-hold {
  background-color: #64748b;
  color: white;
}

.status-badge .icon {
  margin-right: 0.375rem;
  font-size: 0.875rem;
}

.status-badge:hover {
  filter: brightness(0.95);
}

/* Grid Layout Styles */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  margin-bottom: 24px;
}

.grid-item {
  background: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: var(--shadow);
  transition: all 0.3s ease;
}

.grid-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

/* Utility Classes */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-4 {
  gap: 1rem;
}

.mt-2 {
  margin-top: 8px;
}

.mt-4 {
  margin-top: 16px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 16px;
}

/* View Toggle Buttons */
.view-toggle {
  display: flex;
  gap: 8px;
  margin-left: 16px;
}

.toggle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background: #ffffff;
  color: #1e293b;
  cursor: pointer;
  transition: all 0.2s ease;
}

.dark-mode .toggle-btn {
  background: #0f172a;
  border-color: #334155;
  color: #f1f5f9;
}

.toggle-btn:hover {
  background: #f8fafc;
  border-color: #e2e8f0;
}

.dark-mode .toggle-btn:hover {
  background: #334155;
  border-color: #334155;
}

.toggle-btn.active {
  background: #2563eb;
  border-color: #2563eb;
  color: white;
}

.dark-mode .toggle-btn.active {
  background: #2563eb;
  border-color: #2563eb;
  color: white;
}

.toggle-btn.active:hover {
  background: #2563eb;
  border-color: #2563eb;
}

.dark-mode .toggle-btn.active:hover {
  background: #2563eb;
  border-color: #2563eb;
}

/* Responsive Design */
@media (max-width: 768px) {
  .global-header {
    flex-direction: column;
    gap: 1rem;
  }

  .global-header .actions {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }

  .global-header .view-options {
    justify-content: center;
  }

  .global-header .search {
    min-width: auto;
  }

  .grid-container {
    grid-template-columns: 1fr;
  }

  .stats-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .admin-sidebar {
    width: 0;
    transform: translateX(-100%);
  }

  .admin-sidebar.open {
    width: 250px;
    transform: translateX(0);
  }

  .admin-content {
    margin-left: 0;
  }

  .dashboard-container {
    flex-direction: column;
  }
  
  .dashboard-left {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .stats-container {
    grid-template-columns: 1fr;
  }

  .content-header {
    flex-direction: column;
    gap: 16px;
  }

  .global-header .actions {
    width: 100%;
    justify-content: flex-start;
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.3s ease;
}

/* Panel Open Styles */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: margin-right 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.app-container.panel-open {
  margin-right: 450px;
}

.content-container {
  flex: 1;
  padding-left: 0px;
  padding-right: 0px;
  transition: margin-right 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Dashboard Styles */
.dashboard-container {
  display: flex;
  gap: 24px;
  padding: 24px;
}

.dashboard-left {
  flex: 1;
  max-width: 500px;
}

.dashboard-right {
  flex: 2;
}

.section {
  width: 100%;
}

.section-card {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: var(--shadow);
}

.card-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.card-header .card-icon {
  font-size: 24px;
  color: #2563eb;
}

.card-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #1e293b;
  transition: color 0.2s ease;
}

.dark-mode .card-header h3 {
  color: #f1f5f9;
}

.dark-mode .card-header .card-icon {
  color: #f1f5f9;
}

/* Profile Section */
.profile-section {
  grid-column: span 2;
  margin-bottom: 20px;
}

.profile-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}

.profile-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #2563eb;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
}

.profile-info h3 {
  margin: 0 0 4px 0;
  font-size: 1.2rem;
}

.role-badge {
  background: #f1f5f9;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.85rem;
  color: #64748b;
}

.profile-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.info-item .label {
  font-size: 0.85rem;
  color: #64748b;
}

.info-item .value {
  font-weight: 500;
}

/* Stats Section */
.stats-section {
  grid-column: span 2;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  grid-template-rows: repeat(2, auto);
  grid-auto-flow: column;
}

.stats-card {
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: var(--shadow);
}

.stat-number {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 12px;
  color: #2563eb;
}

.status-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.status-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.status-name {
  flex: 1;
  font-size: 0.9rem;
}

.status-count {
  font-weight: 500;
}

/* Activity Section */
.activity-section {
  grid-column: span 2;
}

/* Tasks Section */
.tasks-section {
  grid-column: span 1;
}

.task-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 16px;
}

.task-stat-item {
  text-align: center;
  padding: 12px;
  background: #f1f5f9;
  border-radius: 8px;
}

.task-stat-item .stat-label {
  display: block;
  font-size: 0.85rem;
  color: #64748b;
  margin-bottom: 4px;
}

.task-stat-item .stat-value {
  font-size: 1.5rem;
  font-weight: 600;
}

.stat-value.completed { color: #22c55e; }
.stat-value.in-progress { color: #f59e0b; }
.stat-value.pending { color: #ef4444; }

/* Reminders Section */
.reminders-section {
  grid-column: span 1;
  margin-bottom: 20px;
}

.reminder-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.reminder-item {
  padding: 12px;
  background: #f1f5f9;
  border-radius: 8px;
}

.reminder-info h4 {
  margin: 0 0 4px 0;
  font-size: 1rem;
}

.reminder-info p {
  margin: 0;
  font-size: 0.9rem;
  color: #64748b;
}

.reminder-meta {
  margin-top: 8px;
  font-size: 0.85rem;
  color: #64748b;
}

.due-date {
  display: flex;
  align-items: center;
  gap: 4px;
}

.no-data {
  text-align: center;
  padding: 24px;
  color: #64748b;
  font-style: italic;
}

/* Project Info */
.project-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.project-name {
  font-size: 0.9rem;
  color: #64748b;
  background: #f1f5f9;
  padding: 4px 8px;
  border-radius: 4px;
}

/* Dark Mode */
.dark-mode .section-card {
  background: #1e293b;
}

.dark-mode .stats-card {
  background: #1e293b;
}

.dark-mode .task-stat-item {
  background: #1e293b;
}

.dark-mode .reminder-item {
  background: #1e293b;
}

/* User Profile */
.user-profile {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.profile-main {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.profile-image-container {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  background: #f1f5f9;
  border: 2px solid #e2e8f0;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-avatar {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  color: #1e293b;
  background: #f1f5f9;
}

.profile-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.profile-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.profile-name {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.profile-role {
  font-size: 0.875rem;
  color: #64748b;
  background: #f1f5f9;
  padding: 4px 8px;
  border-radius: 4px;
}

.profile-metadata {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.metadata-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.metadata-label {
  font-size: 0.75rem;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.metadata-value {
  font-size: 0.875rem;
  color: #1e293b;
}

.profile-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 16px;
  padding-top: 16px;
  border-top: 1px solid #e2e8f0;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  text-align: center;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
}

.stat-value.completed {
  color: #22c55e;
}

.stat-value.in-progress {
  color: #f59e0b;
}

.stat-value.pending {
  color: #ef4444;
}

.stat-label {
  font-size: 0.75rem;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Dark Mode Profile */
.dark-mode .profile-name {
  color: #f1f5f9;
}

.dark-mode .profile-role {
  color: #f1f5f9;
  background: #1e293b;
}

.dark-mode .profile-avatar {
  color: #f1f5f9;
  background: #1e293b;
}

.dark-mode .metadata-label {
  color: #cbd5e1;
}

.dark-mode .metadata-value {
  color: #f1f5f9;
}

.dark-mode .stat-value {
  color: #f1f5f9;
}

.dark-mode .stat-label {
  color: #cbd5e1;
}

.dark-mode .profile-image-container {
  background: #1e293b;
  border-color: #334155;
}

.dark-mode .profile-stats {
  border-color: #334155;
}

/* Preserve colored stat values in dark mode */
.dark-mode .stat-value.completed {
  color: #22c55e;
}

.dark-mode .stat-value.in-progress {
  color: #f59e0b;
}

.dark-mode .stat-value.pending {
  color: #ef4444;
}

/* Admin Panel Styles */
.admin-panel {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #ffffff;
}

.admin-topbar {
  background: #ffffff;
  border-bottom: 1px solid #e2e8f0;
  padding: 1rem 1.5rem;
}

.topbar-header {
  margin-bottom: 1rem;
}

.topbar-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
}

.topbar-menu {
  display: flex;
  gap: 1rem;
}

.topbar-menu button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: var(--border-radius);
  background: transparent;
  color: #64748b;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.topbar-menu button:hover {
  color: #1e293b;
  background: #f8fafc;
}

.topbar-menu button.active {
  color: #2563eb;
  background: var(--primary-light);
}

.topbar-menu button svg {
  width: 1.25rem;
  height: 1.25rem;
}

.admin-content {
  flex: 1;
  padding: 1.5rem;
  overflow-y: auto;
  background: #ffffff;
}

/* Dark Mode */
.admin-panel.darkmode {
  background: #0f172a;
}

.admin-topbar.darkmode {
  background: #1e293b;
  border-color: #334155;
}

.topbar-header.darkmode h2 {
  color: #f1f5f9;
}

.topbar-menu button.darkmode {
  color: #cbd5e1;
}

.topbar-menu button.darkmode:hover {
  color: #f1f5f9;
  background: #334155;
}

.topbar-menu button.active.darkmode {
  color: #2563eb;
  background: var(--primary-dark);
}

.admin-content.darkmode {
  background: #0f172a;
}

/* Plans Page Styles */
.calendar {
  background: #ffffff;
  border-radius: var(--border-radius);
  padding: 1.5rem;
  box-shadow: var(--shadow);
}

.calendar .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.calendar .header-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  background: #e2e8f0;
  border: 1px solid #e2e8f0;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.calendar-header {
  background: #f1f5f9;
  padding: 0.75rem;
  text-align: center;
  font-weight: 500;
  color: #64748b;
  font-size: 0.875rem;
}

.calendar-cell {
  background: #ffffff;
  min-height: 120px;
  padding: 0.5rem;
  position: relative;
}

.calendar-cell.empty {
  background: #f1f5f9;
}

.calendar-cell.today {
  background: #f8fafc;
}

.calendar-date {
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 0.5rem;
}

.calendar-cell.today .calendar-date {
  color: #2563eb;
  font-weight: 600;
}

.calendar-events {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.calendar-event {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  color: white;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-time {
  font-weight: 500;
  margin-right: 0.25rem;
}

.event-title {
  opacity: 0.9;
}

.event-tooltip {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: var(--border-radius);
  padding: 1rem;
  width: 300px;
  z-index: 10;
  box-shadow: var(--shadow-lg);
}

.calendar-event:hover .event-tooltip {
  display: block;
}

.event-tooltip p {
  margin: 0.5rem 0;
  color: #1e293b;
  font-size: 0.875rem;
}

.event-tooltip strong {
  color: #1e293b;
}

/* Plan Card Styles */
.plan-cards-container {
  display: flex;
  overflow-x: auto;
  gap: 0.5rem;
  padding: 0.5rem;
  scrollbar-width: thin;
  scrollbar-color: #cbd5e0 #edf2f7;
}

.plan-cards-container::-webkit-scrollbar {
  height: 6px;
}

.plan-cards-container::-webkit-scrollbar-track {
  background: #edf2f7;
  border-radius: 4px;
}

.plan-cards-container::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 4px;
}

.plan-cards-container::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

.plan-card {
  flex: 0 0 auto;
  width: 220px;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  padding: 0.75rem;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

.plan-card.dark {
  background-color: #2d3748;
  border-color: #4a5568;
  color: #e2e8f0;
}

.plan-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.plan-card-header h3 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.plan-card-body p {
  font-size: 0.75rem;
  color: #4a5568;
  margin-bottom: 0.25rem;
}

.plan-card.dark .plan-card-body p {
  color: #e2e8f0;
}

.plan-card-actions {
  display: flex;
  gap: 0.25rem;
  margin-top: 0.5rem;
}

.plan-card-actions button {
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 0.25rem;
  background-color: #edf2f7;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.plan-card-actions button:hover {
  background-color: #e2e8f0;
}

.plan-card.dark .plan-card-actions button {
  background-color: #4a5568;
  color: #e2e8f0;
}

.plan-card.dark .plan-card-actions button:hover {
  background-color: #718096;
}

/* Dark Mode Styles */
.dark-mode .calendar {
  background: #1e293b;
}

.dark-mode .calendar-header {
  background: #1e293b;
  color: #cbd5e1;
}

.dark-mode .calendar-cell {
  background: #1e293b;
}

.dark-mode .calendar-cell.empty {
  background: #1e293b;
}

.dark-mode .calendar-cell.today {
  background: #334155;
}

.dark-mode .calendar-date {
  color: #cbd5e1;
}

.dark-mode .event-tooltip {
  background: #1e293b;
  border-color: #334155;
}

.dark-mode .event-tooltip p,
.dark-mode .event-tooltip strong {
  color: #f1f5f9;
}

.dark-mode .plan-card {
  background: #1e293b;
  border-color: #334155;
}

.dark-mode .plan-card .card-title {
  color: #f1f5f9;
}

.dark-mode .plan-card .info-item,
.dark-mode .plan-card .info-item svg {
  color: #cbd5e1;
}

.dark-mode .plan-card .card-footer {
  border-color: #334155;
}

.dark-mode .attendee-badge {
  background: #1e293b;
  color: #cbd5e1;
}

/* Search Box */
.search-box {
  position: relative;
  min-width: 300px;
}

.search-box input {
  width: 100%;
  padding: 0.5rem 1rem;
  padding-left: 2.5rem;
  border: 1px solid #e2e8f0;
  border-radius: var(--border-radius);
  background: #ffffff;
  color: #1e293b;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.search-box input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.search-box input::placeholder {
  color: #64748b;
}

.search-box::before {
  content: '';
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3e%3cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'%3e%3c/path%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  opacity: 0.5;
}

/* Dark Mode */
.darkmode .search-box input {
  background: #0f172a;
  border-color: #334155;
  color: #f1f5f9;
}

.darkmode .search-box input:focus {
  border-color: #2563eb;
}

.darkmode .search-box input::placeholder {
  color: #cbd5e1;
}

/* Shot Badge Styles */
.shot-badge-container {
  position: relative;
  display: inline-flex;
  margin: 2px;
}

.shot-badge {
  display: inline-flex;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
  background-color: #1f2937;
  color: #ffffff;
  transition: all 0.2s ease;
  gap: 6px;
  max-width: 200px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.shot-badge.status-1 { background-color: #1e40af; border-color: #3b82f6; } /* In Progress */
.shot-badge.status-2 { background-color: #065f46; border-color: #10b981; } /* Completed */
.shot-badge.status-3 { background-color: #991b1b; border-color: #ef4444; } /* On Hold */
.shot-badge.status-4 { background-color: #5b21b6; border-color: #8b5cf6; } /* In Review */
.shot-badge.status-pending { background-color: #374151; border-color: #6b7280; } /* Pending */

.shot-badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.shot-badge-content {
  display: flex;
  align-items: center;
  gap: 6px;
  min-width: 0;
}

.shot-name {
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shot-stats {
  display: flex;
  align-items: center;
  padding-left: 6px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.sequence-name {
  font-size: 0.75rem;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

/* Dark mode adjustments */
.dark-mode .shot-badge {
  background-color: #111827;
  border-color: rgba(255, 255, 255, 0.05);
}

.dark-mode .shot-badge:hover {
  border-color: rgba(255, 255, 255, 0.1);
}

/* Sequence Badge Styles */
.sequence-badge-container {
  position: relative;
  display: inline-flex;
  margin: 2px;
}

.sequence-badge {
  display: inline-flex;
  align-items: center;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #4a5568;
  transition: all 0.2s ease;
  gap: 8px;
}

.sequence-badge.status-1 { background-color: #3b82f6; } /* In Progress */
.sequence-badge.status-2 { background-color: #10b981; } /* Completed */
.sequence-badge.status-3 { background-color: #ef4444; } /* On Hold */
.sequence-badge.status-4 { background-color: #8b5cf6; } /* In Review */
.sequence-badge.status-pending { background-color: #6b7280; } /* Pending */

.sequence-badge:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sequence-stats {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-left: 4px;
  padding-left: 6px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.sequence-badge .shot-count,
.sequence-badge .task-count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 22px;
  height: 22px;
  padding: 0 6px;
  border-radius: 11px;
  font-size: 0.75rem;
  font-weight: 600;
}

.sequence-badge .shot-count {
  background-color: rgba(255, 255, 255, 0.2);
}

.sequence-badge .task-count {
  background-color: rgba(255, 255, 255, 0.15);
}

/* Add icons using pseudo-elements */
.sequence-badge .shot-count::before {
  content: "🎬";
  font-size: 0.75rem;
  margin-right: 4px;
}

.sequence-badge .task-count::before {
  content: "✓";
  font-size: 0.75rem;
  margin-right: 4px;
}

/* Asset Badge Styles */
.asset-badges-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 4px;
}

.asset-badge-container {
  position: relative;
  display: inline-flex;
}

.asset-badge {
  display: inline-flex;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
  background-color: #1f2937;
  color: #ffffff;
  transition: all 0.2s ease;
  gap: 6px;
  max-width: 200px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.asset-badge.status-1 { background-color: #1e40af; border-color: #3b82f6; } /* In Progress */
.asset-badge.status-2 { background-color: #065f46; border-color: #10b981; } /* Completed */
.asset-badge.status-3 { background-color: #991b1b; border-color: #ef4444; } /* On Hold */
.asset-badge.status-4 { background-color: #5b21b6; border-color: #8b5cf6; } /* In Review */
.asset-badge.status-pending { background-color: #374151; border-color: #6b7280; } /* Pending */

.asset-badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.asset-badge-content {
  display: flex;
  align-items: center;
  gap: 6px;
  min-width: 0;
}

.asset-thumbnail {
  width: 80px;
  
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
}

.asset-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.asset-name {
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.asset-stats {
  display: flex;
  align-items: center;
  padding-left: 6px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.asset-badge .task-count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.15);
  font-size: 0.75rem;
  font-weight: 600;
}

.asset-badge .task-count::before {
  content: "📋";
  font-size: 0.75rem;
  margin-right: 3px;
}

/* Dark mode adjustments */
.dark-mode .asset-badge {
  background-color: #111827;
  border-color: rgba(255, 255, 255, 0.05);
}

.dark-mode .asset-badge:hover {
  border-color: rgba(255, 255, 255, 0.1);
}

/* Task Badge Styles */
.task-badges-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 4px;
}

.task-badge-container {
  position: relative;
  display: inline-flex;
}

.task-badge {
  display: inline-flex;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
  background-color: #1f2937;
  color: #ffffff;
  transition: all 0.2s ease;
  gap: 6px;
  max-width: 200px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.task-badge.status-1 { background-color: #1e40af; border-color: #3b82f6; } /* In Progress */
.task-badge.status-2 { background-color: #065f46; border-color: #10b981; } /* Completed */
.task-badge.status-3 { background-color: #991b1b; border-color: #ef4444; } /* On Hold */
.task-badge.status-4 { background-color: #5b21b6; border-color: #8b5cf6; } /* In Review */
.task-badge.status-pending { background-color: #374151; border-color: #6b7280; } /* Pending */

.task-badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.task-badge-content {
  display: flex;
  align-items: center;
  gap: 6px;
  min-width: 0;
}

.task-name {
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.task-stats {
  display: flex;
  align-items: center;
  padding-left: 6px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.task-badge .assignee-count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.15);
  font-size: 0.75rem;
  font-weight: 600;
}

/* Dark mode adjustments */
.dark-mode .task-badge {
  background-color: #111827;
  border-color: rgba(255, 255, 255, 0.05);
}

.dark-mode .task-badge:hover {
  border-color: rgba(255, 255, 255, 0.1);
}

.sequence-badge,
.asset-badge,
.task-badge {
  color: #ffffff !important;
}

.sequence-badge .sequence-stats,
.asset-badge .asset-stats,
.task-badge .task-stats {
  color: #ffffff !important;
}

.sequence-badge .shot-count,
.sequence-badge .task-count,
.asset-badge .task-count,
.task-badge .assignee-count {
  color: #ffffff !important;
}

/* Form Overlay Styles */
.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.form-modal {
  background-color: #ffffff;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-lg);
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  padding: 1.5rem;
  position: relative;
}

.form-modal.dark-mode {
  background-color: #1e293b;
  color: #f1f5f9;
}

.form-modal .close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: #64748b;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.form-modal .close-btn:hover {
  color: #ef4444;
}

.plans-section .section-card {
  background: #ffffff;
  border-radius: var(--border-radius);
  padding: 1.5rem;
  box-shadow: var(--shadow);
  transition: all 0.2s ease;
}

.plans-section .section-card:hover {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.upcoming-plans {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.plan-item {
  background: #f1f5f9;
  border-radius: var(--border-radius);
  padding: 1rem;
  transition: all 0.2s ease;
}

.plan-item:hover {
  background: #f8fafc;
}

.plan-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.plan-icon {
  color: #2563eb;
}

.plan-date {
  font-weight: 500;
  color: #1e293b;
}

.plan-title {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #1e293b;
}

.plan-details {
  display: flex;
  gap: 1rem;
  font-size: 0.875rem;
  color: #64748b;
}

.no-plans {
  text-align: center;
  color: #64748b;
  padding: 1rem;
}

/* Dark Mode */
.darkmode .plans-section .section-card {
  background: #1e293b;
}

.darkmode .plan-item {
  background: #1e293b;
}

.darkmode .plan-item:hover {
  background: #334155;
}

.darkmode .plan-date {
  color: #f1f5f9;
}

.darkmode .plan-title {
  color: #f1f5f9;
}

.darkmode .plan-details {
  color: #cbd5e1;
}

.darkmode .no-plans {
  color: #cbd5e1;
}

/* React Select Styles */
.react-select__control {
  background-color: #ffffff;
  border-color: #e2e8f0;
  border-radius: var(--border-radius);
}

.react-select__menu {
  background-color: #ffffff;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
}

.react-select__option {
  background-color: #ffffff;
  color: #1e293b;
  cursor: pointer;
}

.react-select__option:hover {
  background-color: #f8fafc;
}

.dark-mode .react-select__control {
  background-color: #0f172a;
  border-color: #334155;
  color: #f1f5f9;
}

.dark-mode .react-select__menu {
  background-color: #0f172a;
}

.dark-mode .react-select__option {
  background-color: #0f172a;
  color: #f1f5f9;
}

.dark-mode .react-select__option:hover {
  background-color: #334155;
}



.text-container {
  width: 300px;
  height: 200px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 10px;
  overflow-y: auto;
  background-color: #ffffff;
}

.text-container p {
  margin: 0;
}

.list-item-with-open-panel {
  background-color: #fff3cd !important;
  border: 2px solid #ffc107 !important;
  box-shadow: 0 0 8px rgba(255, 193, 7, 0.3) !important;
}

.dark-mode .list-item-with-open-panel {
  background-color: #332e1a !important;
  border-color: #ffc107 !important;
}

.sender-name {
  font-size: 0.7rem;
  color: #666;
  text-align: center;
  margin-top: 4px;
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sender-name {
  font-size: 0.7rem;
  color: #666;
  text-align: center;
  margin-top: 4px;
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-sender-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
}

.sender-name {
  font-size: 0.7rem;
  color: #666;
  text-align: center;
  margin-top: 4px;
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.group-options-menu {
  margin-left: 10px;
}

.group-options-menu button {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  transition: color 0.2s ease;
}

.group-options-menu button:hover {
  color: #000;
}