.my-tasks-container {
  padding: 24px;
  margin-top: 60px;
  height: calc(100vh - 84px);
  display: flex;
  flex-direction: column;
}

.my-tasks-content {
  display: flex;
  height: calc(100vh - 120px);
  gap: 1rem;
  margin-top: 1rem;
}

.tasks-list-panel {
  flex: 1;
  min-width: 0;
  overflow: auto;
}

.dcc-panel {
  width: 300px;
  background-color: #ffffff;
  border-left: 1px solid #e5e7eb;
  overflow-y: auto;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.darkmode .dcc-panel {
  background-color: #333;
  border-left: 1px solid #555;
}

/* Styling untuk task yang dipilih */
.selected-task {
  background-color: rgba(0, 123, 255, 0.1) !important;
  border-left: 4px solid #007bff !important;
}

.darkmode .selected-task {
  background-color: rgba(0, 123, 255, 0.2) !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  .my-tasks-content {
    flex-direction: column;
    height: auto;
  }

  .tasks-list-panel,
  .dcc-panel {
    width: 100%;
  }

  .dcc-panel {
    border-left: none;
    border-top: 1px solid #e5e7eb;
    margin-top: 1rem;
    padding-top: 1rem;
  }
}
