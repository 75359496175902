.dcc-file-panel {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dcc-header {
  margin-bottom: 20px;
}

.dcc-header h3 {
  margin: 0 0 16px 0;
  color: #333;
  font-size: 1.2rem;
}

.dcc-buttons {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.dcc-create-btn {
  background: #f3a321;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.dcc-create-btn:hover {
  background: #e69100;
  transform: translateY(-1px);
}

.dcc-files-list {
  flex: 1;
  overflow-y: auto;
  margin-top: 20px;
}

.dcc-file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border: 1px solid #eee;
  border-radius: 6px;
  margin-bottom: 8px;
  transition: all 0.2s ease;
}

.dcc-file-item:hover {
  background: #f8f9fa;
  border-color: #e0e0e0;
}

.dcc-file-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.dcc-file-name {
  font-size: 0.9rem;
  color: #333;
}

.dcc-file-version {
  font-size: 0.8rem;
  color: #666;
}

.dcc-open-btn {
  background: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: all 0.2s ease;
}

.dcc-open-btn:hover {
  background: #218838;
}

.dcc-empty-state {
  color: #666;
  text-align: center;
  padding: 40px 20px;
  font-size: 0.9rem;
}

/* Dark mode */
.darkmode .dcc-file-panel {
  background: #2d2d2d;
}

.darkmode .dcc-header h3 {
  color: #e0e0e0;
}

.darkmode .dcc-file-item {
  border-color: #404040;
}

.darkmode .dcc-file-item:hover {
  background: #3d3d3d;
  border-color: #505050;
}

.darkmode .dcc-file-name {
  color: #e0e0e0;
}

.darkmode .dcc-file-version {
  color: #b0b0b0;
}

.darkmode .dcc-empty-state {
  color: #b0b0b0;
}
