/* CSS Variables */
:root {
  /* Light Mode Colors */
  --primary: #2563eb;
  --secondary: #64748b;
  --success: #22c55e;
  --warning: #f59e0b;
  --danger: #ef4444;
  --info: #3b82f6;
  
  --text-primary: #1e293b;
  --text-secondary: #64748b;
  --text-muted: #94a3b8;
  
  --bg-primary: #ffffff;
  --bg-secondary: #f1f5f9;
  --bg-card: #ffffff;
  --bg-hover: #f8fafc;
  
  --border-color: #e2e8f0;
  --border-radius: 8px;
  
  /* Dark Mode Colors */
  --bg-primary-dark: #0f172a;
  --bg-secondary-dark: #1e293b;
  --bg-card-dark: #1e293b;
  --bg-hover-dark: #334155;
  
  --text-primary-dark: #f1f5f9;
  --text-secondary-dark: #cbd5e1;
  --text-muted-dark: #94a3b8;
  
  --border-color-dark: #334155;
  
  /* Shadows */
  --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

/* Form and Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease-in-out;
}

.modal-form {
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  animation: slideIn 0.2s ease-out;
}

.dark-mode .modal-form {
  background: #1a1a1a;
  color: #e5e7eb;
  border: 1px solid #374151;
}

/* Form Elements */
.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.form-group label {
  font-weight: 500;
  color: #374151;
  font-size: 0.875rem;
}

.dark-mode .form-group label {
  color: #e5e7eb;
}

.form-control {
  padding: 0.625rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  transition: all 0.15s ease-in-out;
}

.dark-mode .form-control {
  background: #2d2d2d;
  border-color: #4b5563;
  color: #e5e7eb;
}

.form-control:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.dark-mode .form-control:focus {
  border-color: #60a5fa;
  box-shadow: 0 0 0 2px rgba(96, 165, 250, 0.1);
}

/* Select Styles */
.select-container {
  position: relative;
}

.select__control {
  background-color: white !important;
  border-color: #e5e7eb !important;
  min-height: 38px !important;
  border-radius: 0.375rem !important;
}

.select__menu {
  background-color: white !important;
  border: 1px solid #e5e7eb !important;
  border-radius: 0.375rem !important;
  margin-top: 4px !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1) !important;
  z-index: 1001 !important;
}

.select__option {
  padding: 0.5rem !important;
  cursor: pointer !important;
  background-color: white !important;
}

.select__option--is-focused {
  background-color: #f3f4f6 !important;
}

.select__option--is-selected {
  background-color: #3b82f6 !important;
  color: white !important;
}

/* Dark Mode Styles */
.dark-mode .select__control {
  background-color: #2d2d2d !important;
  border-color: #4b5563 !important;
  color: #e5e7eb !important;
}

.dark-mode .select__menu {
  background-color: #2d2d2d !important;
  border-color: #4b5563 !important;
}

.dark-mode .select__option {
  background-color: #2d2d2d !important;
  color: #e5e7eb !important;
}

.dark-mode .select__option--is-focused {
  background-color: #374151 !important;
}

.dark-mode .select__option--is-selected {
  background-color: #60a5fa !important;
}

/* Button Group */
.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid #e5e7eb;
}

.dark-mode .button-group {
  border-color: #4b5563;
}

/* Form Buttons */
.form-btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.375rem;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

.form-btn-primary {
  background-color: #3b82f6;
  color: white;
  border: none;
}

.form-btn-primary:hover {
  background-color: #2563eb;
}

.form-btn-secondary {
  background-color: #6b7280;
  color: white;
  border: none;
}

.form-btn-secondary:hover {
  background-color: #4b5563;
}

.dark-mode .form-btn-secondary {
  background-color: #4a4a4a;
}

.dark-mode .form-btn-secondary:hover {
  background-color: #3d3d3d;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.task-form {
    padding: 20px;
    background: transparent;
    border-radius: 8px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid rgba(221, 221, 221, 0.3);
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    color: inherit;
  }

  .form-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .submit-button,
  .close-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: rgba(76, 175, 80, 0.9);
    color: white;
    transition: all 0.3s ease;
  }
  
  .close-button {
    background: rgba(244, 67, 54, 0.9);
  }
  
  .submit-button:hover {
    background: rgba(76, 175, 80, 1);
    transform: translateY(-1px);
  }
  
  .close-button:hover {
    background: rgba(244, 67, 54, 1);
    transform: translateY(-1px);
  }

  .task-form-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    max-width: 500px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    right: 0;
    transition: right 0.3s ease;
  }

  .dark .task-form-popup {
    background-color: #2d2d2d;
    color: #fff;
  }

  .panel-open {
    margin-right: 450px;
  }
  
  .activity-panel {
    right: 0;
    transition: right 0.3s ease;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-form {
    position: relative;
    width: 100%;
    max-width: 600px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    margin: 20px auto;
    color: #fff;
  }

  .modal-header {
    position: sticky;
    top: 0;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -20px -20px 20px -20px;
    padding: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px 8px 0 0;
    z-index: 1;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: inherit;
  }
  
  .modal-header .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: inherit;
    padding: 5px;
    line-height: 1;
    opacity: 0.7;
    transition: opacity 0.3s;
  }
  
  .modal-header .close-button:hover {
    opacity: 1;
  }
  
  .task-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 10px;
    max-height: calc(100vh - 180px);
    overflow-y: auto;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 5px;
  }
  
  .form-group label {
    font-weight: 500;
    color: inherit;
    opacity: 0.8;
    font-size: 0.9rem;
  }
  
  .form-group input[type="text"],
  .form-group input[type="number"],
  .form-group input[type="date"],
  .form-group select,
  .form-group textarea {
    padding: 10px;
    border: 1px solid var(--border-color, #ddd);
    border-radius: 4px;
    font-size: 14px;
    background-color: var(--input-bg, #f8f9fa);
    color: inherit;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .form-group input[type="text"]:focus,
  .form-group input[type="number"]:focus,
  .form-group input[type="date"]:focus,
  .form-group select:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
  }
  
  .form-group textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  .form-group select {
    cursor: pointer;
    appearance: none;
    padding-right: 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='currentColor' viewBox='0 0 16 16'%3E%3Cpath d='M8 11L3 6h10z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
  }
  
  .form-buttons {
    position: sticky;
    bottom: -20px;
    background: inherit;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin: 20px -20px -20px -20px;
    padding: 15px 20px;
    border-top: 1px solid var(--border-color, #eee);
    border-radius: 0 0 8px 8px;
    z-index: 1;
  }
  
  .submit-btn,
  .cancel-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s;
    font-size: 0.9rem;
  }
  
  .submit-btn {
    background-color: #007bff;
    color: white;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
    transform: translateY(-1px);
  }
  
  .cancel-btn {
    background-color: #6c757d;
    color: white;
  }
  
  .cancel-btn:hover {
    background-color: #5a6268;
    transform: translateY(-1px);
  }

  .shot-form {
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .submit-button,
  .close-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .close-button {
    background-color: #f44336;
    color: white;
  }
  
  .sequence-form {
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .sequence-form-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-width: 500px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
  }
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .submit-button,
  .close-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .close-button {
    background-color: #f44336;
    color: white;
  }
  
  .status-select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: white;
  }
  
  .status-select option {
    padding: 8px;
    margin: 2px 0;
  }
  
  .plan-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .plan-form {
    background: white;
    border-radius: 8px;
    padding: 24px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    color: black;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #666;
  }
  
  .form-group {
    margin-bottom: 16px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  .form-group input[type="text"],
  .form-group input[type="number"],
  .form-group input[type="date"],
  .form-group input[type="time"],
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #000;
  }
  
  .form-row {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }
  
  .form-row .form-group {
    flex: 1;
    margin-bottom: 0;
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
  }
  
  .checkbox-group label {
    margin-bottom: 0;
    margin-left: 8px;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }
  
  .cancel-button,
  .submit-button {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
  }
  
  .cancel-button {
    background: none;
    border: 1px solid #ddd;
  }
  
  .submit-button {
    background: #007bff;
    color: white;
    border: none;
  }
  
  .submit-button:hover {
    background: #0056b3;
  }
  
  .project-form-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .project-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .submit-button,
  .close-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .close-button {
    background-color: #f44336;
    color: white;
  }
  
  .close-button:hover {
    background-color: #d32f2f;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .header-title {
    margin: 0;
    font-size: 1.5em;
    font-weight: 600;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: inherit;
  }

  .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    transition: border-color 0.2s;
  }

  .dark .form-control {
    background-color: #3d3d3d;
    border-color: #4d4d4d;
    color: #fff;
  }

  .form-control:focus {
    border-color: #4a90e2;
    outline: none;
  }

  textarea.form-control {
    min-height: 100px;
    resize: vertical;
  }

  .form-buttons {
    display: flex;
    gap: 12px;
    margin-top: 24px;
  }

  .submit-button,
  .close-button {
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
  }

  .submit-button {
    background-color: #4a90e2;
    color: white;
  }

  .submit-button:hover {
    background-color: #357abd;
  }

  .close-button {
    background-color: #e0e0e0;
    color: #333;
  }

  .close-button:hover {
    background-color: #d0d0d0;
  }

  .dark .close-button {
    background-color: #4d4d4d;
    color: #fff;
  }

  .dark .close-button:hover {
    background-color: #5d5d5d;
  }

  /* Priority Badges */
.priority-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  min-width: 70px;
}

.priority-badge.priority-high {
  background-color: #dc3545;
  color: white;
}

.priority-badge.priority-medium {
  background-color: #ffc107;
  color: #000;
}

.priority-badge.priority-low {
  background-color: #28a745;
  color: white;
}

/* Progress Bar */
.progress-container {
  width: 100%;
  min-width: 100px;
  height: 20px;
  background-color: #e9ecef;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: 100%;
  transition: width 0.3s ease;
  min-width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-text {
  color: white;
  font-size: 0.75rem;
  font-weight: 500;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  text-align: center;
}

  /* React Select customization */
  .basic-multi-select {
    margin-top: 8px;
  }

  .select__control {
    border-color: #ddd !important;
    box-shadow: none !important;
  }

  .dark .select__control {
    background-color: #3d3d3d !important;
    border-color: #4d4d4d !important;
  }

  .dark .select__menu {
    background-color: #3d3d3d !important;
  }

  .dark .select__option {
    background-color: #3d3d3d !important;
    color: #fff !important;
  }

  .dark .select__option--is-focused {
    background-color: #4d4d4d !important;
  }

  .dark .select__option--is-selected {
    background-color: #4a90e2 !important;
  }

  .dark .select__multi-value {
    background-color: #4d4d4d !important;
  }

  .dark .select__multi-value__label {
    color: #fff !important;
  }

  .dark .select__input {
    color: #fff !important;
  }

  .dark .select__single-value {
    color: #fff !important;
  }

  .react-select__menu {
    background-color: var(--bg-card) !important;
  }

  .react-select__menu-list {
    background-color: var(--bg-card) !important;
  }

  .react-select__option {
    background-color: var(--bg-card) !important;
  }

  .react-select__option--is-focused {
    background-color: var(--bg-hover) !important;
  }

  .react-select__option--is-selected {
    background-color: var(--primary) !important;
    color: white !important;
  }

  /* Dark Mode Styles */
  .dark-mode .react-select__menu {
    background-color: var(--bg-card-dark) !important;
  }

  .dark-mode .react-select__menu-list {
    background-color: var(--bg-card-dark) !important;
  }

  .dark-mode .react-select__option {
    background-color: var(--bg-card-dark) !important;
  }

  .dark-mode .react-select__option--is-focused {
    background-color: var(--bg-hover-dark) !important;
  }

  .dark-mode .react-select__option--is-selected {
    background-color: var(--primary) !important;
    color: white !important;
  }

  /* Form Row */
  .form-row {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .form-row .form-group {
    flex: 1;
    margin-bottom: 0;
  }

  .flex-1 {
    flex: 1;
  }

  .related-items {
    max-height: 100px;
    overflow-y: auto;
    padding: 4px;
  }

  .related-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .related-items li {
    font-size: 0.875rem;
    padding: 2px 4px;
    margin-bottom: 2px;
    background-color: #f1f5f9;
    border-radius: 4px;
  }

  .related-items .text-gray-400 {
    color: #9ca3af;
    font-size: 0.875rem;
  }

  .react-select_menu {
    background-color: #ffffff !important;
  }