.role-manager {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .role-form {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .role-form input,
  .role-form textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .permissions-container {
    margin: 15px 0;
  }
  
  .permissions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    margin-top: 10px;
  }
  
  .permission-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    background: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .role-list {
    display: grid;
    gap: 15px;
  }
  
  .role-item {
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  
  .role-info {
    flex: 1;
  }
  
  .role-permissions {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 10px;
  }
  
  .permission-tag {
    background: #e9ecef;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.9em;
  }
  
  .role-actions {
    display: flex;
    gap: 8px;
  }
  
  .role-actions button {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .role-actions button:first-child {
    background: #007bff;
    color: white;
  }
  
  .role-actions button:last-child {
    background: #dc3545;
    color: white;
  }
  
  /* Dark mode */

  