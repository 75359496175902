/* CSS Variables */
:root {
  /* Light Mode Colors */
  --primary: #2563eb;
  --secondary: #64748b;
  --success: #22c55e;
  --warning: #f59e0b;
  --danger: #ef4444;
  --info: #3b82f6;
  
  --text-primary: #1e293b;
  --text-secondary: #64748b;
  --text-muted: #94a3b8;
  
  --bg-primary: #ffffff;
  --bg-secondary: #f1f5f9;
  --bg-card: #ffffff;
  --bg-hover: #f8fafc;
  
  --border-color: #e2e8f0;
  --border-radius: 8px;
  
  /* Dark Mode Colors */
  --bg-primary-dark: #0f172a;
  --bg-secondary-dark: #1e293b;
  --bg-card-dark: #1e293b;
  --bg-hover-dark: #334155;
  
  --text-primary-dark: #f1f5f9;
  --text-secondary-dark: #cbd5e1;
  --text-muted-dark: #94a3b8;
  
  --border-color-dark: #334155;
  
  /* Shadows */
  --shadow-sm: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}


/* Card Grid Layout */
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
  
  width: 100%;
}

/* Card Container */
.card-view {
  background: var(--background-color, #ffffff);
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-view:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

/* Thumbnail */
.thumbnail-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  overflow: hidden;
}

.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card-view:hover .thumbnail {
  transform: scale(1.05);
}

/* Card Header */
.card-header {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  border-bottom: 1px solid var(--border-color, rgba(0, 0, 0, 0.1));
}

.card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-color, #1a1a1a);
  margin: 0;
  line-height: 1.4;
  flex: 1;
}

/* Status Badge */
.status-badge {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.status-pending { background-color: #FEF3C7; color: #92400E; }
.status-in-progress { background-color: #DBEAFE; color: #1E40AF; }
.status-completed { background-color: #D1FAE5; color: #065F46; }
.status-review { background-color: #E0E7FF; color: #3730A3; }
.status-approved { background-color: #BBF7D0; color: #166534; }

/* Badge Styles */
.badge {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
  transition: all 0.2s;
}

.badge-outline {
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-primary);
}

.badge-sm {
  padding: 0.125rem 0.375rem;
  font-size: 0.7rem;
}

.badge:hover {
  background-color: var(--background-hover);
}

.badge.cursor-pointer {
  cursor: pointer;
}

/* Card Description */
.card-description {
  padding: 16px 20px;
  color: var(--text-secondary, #4B5563);
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 0;
  flex-grow: 1;
}

/* Progress Bar */
.progress-container {
  margin: 15px 0;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.progress-bar {
  flex-grow: 1;
  height: 24px;
  background-color: rgba(var(--progress-color-rgb), 0.15);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.progress-fill {
  height: 100%;
  border-radius: 4px;
  background-color: rgb(var(--progress-color-rgb));
  transition: width 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 8px;
  color: white;
  font-size: 0.85rem;
  font-weight: 500;
}

/* Progress colors */
.progress-fill.progress-low {
  --progress-color-rgb: 82, 196, 26;
}

.progress-fill.progress-medium {
  --progress-color-rgb: 250, 140, 22;
}

.progress-fill.progress-high {
  --progress-color-rgb: 24, 144, 255;
}

.progress-text {
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--text-secondary);
  min-width: 45px;
  text-align: right;
}

.progress-text:first-child {
  min-width: 80px;
  text-align: left;
}

.progress-text:last-child {
  min-width: 45px;
  text-align: right;
}

/* Hover effect */
.progress-container:hover .progress-fill {
  filter: brightness(1.1);
}

/* Animation for progress bar */
@keyframes progressAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.progress-fill {
  background-size: 200% auto;
  animation: progressAnimation 3s ease infinite;
}

/* Related Items */
.related-items-container {
  padding: 0 20px 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.item-badge {
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  background: var(--background-secondary, #F3F4F6);
  border-radius: 16px;
  font-size: 0.75rem;
  color: var(--text-secondary, #4B5563);
  gap: 6px;
  max-width: 100%;
}

.item-badge img {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  object-fit: cover;
}

.item-badge .count {
  background: rgba(0, 0, 0, 0.1);
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 0.7rem;
  font-weight: 600;
}

/* Badge containers */
.related-sequences-container,
.related-assets-container,
.related-tasks-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.sequence-badge-container,
.asset-badge-container,
.task-badge-container,
.shot-badge-container {
  display: inline-flex;
}

/* Badge styles */
.sequence-badge,
.asset-badge,
.task-badge,
.shot-badge {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  line-height: 1;
  cursor: pointer;
  transition: all 0.2s ease;
}

.sequence-badge:hover,
.asset-badge:hover,
.task-badge:hover,
.shot-badge:hover {
  filter: brightness(0.95);
}

.sequence-badge-content,
.asset-badge-content,
.task-badge-content,
.shot-badge-content {
  display: flex;
  align-items: center;
  gap: 4px;
}

.sequence-name,
.asset-name,
.task-name,
.shot-name {
  font-weight: 500;
  white-space: nowrap;
}

/* Stats Section */
.stats-container {
  padding: 12px 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  border-top: 1px solid var(--border-color, rgba(0, 0, 0, 0.1));
}

.stat-item {
  text-align: center;
}

.stat-label {
  font-size: 0.75rem;
  color: var(--text-secondary, #6B7280);
  margin-bottom: 4px;
}

.stat-value {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-color, #1F2937);
}

/* Card Footer */
.card-footer {
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--background-secondary, #F9FAFB);
  border-top: 1px solid var(--border-color, rgba(0, 0, 0, 0.1));
}

.card-actions {
  display: flex;
  gap: 8px;
}

.action-button {
  padding: 8px;
  border-radius: 8px;
  border: none;
  background: transparent;
  color: var(--text-secondary, #6B7280);
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button:hover {
  background: var(--background-hover, #F3F4F6);
  color: var(--primary-color, #2563EB);
}

.action-button svg {
  width: 18px;
  height: 18px;
}

/* Assignee Badge */
.assignee-badge {
  display: inline-flex;
  align-items: center;
  padding: 4px;
  background: var(--primary-color, #2563EB);
  color: white;
  border-radius: 50%;
  
  height: 24px;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 600;
}

/* Priority Badge */
.priority-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
}

.priority-low { background-color: #E5E7EB; color: #374151; }
.priority-medium { background-color: #FEF3C7; color: #92400E; }
.priority-high { background-color: #FEE2E2; color: #991B1B; }

/* Dark Mode */
.dark-mode .card-view {
  background: var(--dark-background, #1F2937);
  border-color: var(--dark-border, #374151);
}

.dark-mode .card-title {
  color: var(--dark-text, #F9FAFB);
}

.dark-mode .card-description {
  color: var(--dark-text-secondary, #9CA3AF);
}

.dark-mode .item-badge {
  background: var(--dark-background-secondary, #374151);
  color: var(--dark-text-secondary, #9CA3AF);
}

.dark-mode .card-footer {
  background: var(--dark-background-secondary, #374151);
  border-color: var(--dark-border, #4B5563);
}

.dark-mode .action-button {
  color: var(--dark-text-secondary, #9CA3AF);
}

.dark-mode .action-button:hover {
  background: var(--dark-background-hover, #4B5563);
  color: var(--primary-color, #60A5FA);
}

.dark-mode .react-select__menu {
  background-color: var(--dark-bg-secondary) !important;
  border: 1px solid var(--dark-border-primary) !important;
  color: var(--dark-text-primary) !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  .card-grid {
    grid-template-columns: 1fr;
    padding: 16px;
    gap: 16px;
  }

  .card-header {
    padding: 12px 16px;
  }

  .card-description {
    padding: 12px 16px;
  }

  .progress-container,
  .related-items-container {
    padding: 0 16px 12px;
  }

  .stats-container {
    padding: 12px 16px;
  }

  .card-footer {
    padding: 12px 16px;
  }
}

/* Form Styling */
.card-view.editing {
  padding: 20px;
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-color, #1F2937);
  margin-bottom: 4px;
}

.form-control {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--border-color, #E5E7EB);
  border-radius: 6px;
  font-size: 0.875rem;
  transition: border-color 0.2s ease;
}

.form-control:focus {
  outline: none;
  border-color: var(--primary-color, #2563EB);
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

textarea.form-control {
  min-height: 100px;
  resize: vertical;
}

.form-actions {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 24px;
}

.btn {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-primary {
  background: var(--primary-color, #2563EB);
  color: white;
  border: none;
}

.btn-primary:hover {
  background: var(--primary-dark, #1D4ED8);
}

.btn-secondary {
  background: var(--background-secondary, #F3F4F6);
  color: var(--text-color, #1F2937);
  border: 1px solid var(--border-color, #E5E7EB);
}

.btn-secondary:hover {
  background: var(--background-hover, #E5E7EB);
}

/* Loading State */
.card-view.loading {
  pointer-events: none;
  opacity: 0.7;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  width: 24px;
  height: 24px;
  border: 2px solid var(--primary-color, #2563EB);
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Filter Panel */
.filters-panel {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin: 16px 0;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filters-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  align-items: start;
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter-group label {
  font-weight: 500;
  color: var(--text-color);
}

.filter-group input[type="date"] {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--input-background);
  color: var(--text-color);
}

.react-select-container {
  width: 100%;
}

.react-select__control {
  background-color: var(--input-background) !important;
  border-color: var(--border-color) !important;
}

.react-select__menu {
  background-color: var(--background-color) !important;
  border: 1px solid var(--border-color) !important;
  color: #1a1a1a;
}

.react-select__option {
  background-color: #166534;
  color: var(--text-color) !important;
}

.react-select__option--is-focused {
  background-color: var(--hover-color) !important;
}

.react-select__single-value {
  color: var(--text-color) !important;
}

.react-select__input {
  color: var(--text-color) !important;
}

.dark-mode .filters-panel {
  background-color: var(--dark-background);
  border-color: var(--dark-border);
}

.dark-mode .filter-group label {
  color: var(--dark-text);
}

.dark-mode .filter-group input[type="date"] {
  background-color: var(--dark-input-background);
  border-color: var(--dark-border);
  color: var(--dark-text);
}

.dark-mode .react-select__control {
  background-color: var(--dark-input-background) !important;
  border-color: var(--dark-border) !important;
}

.dark-mode .react-select__menu {
  background-color: var(--dark-background) !important;
  border-color: var(--dark-border) !important;
}

.dark-mode .react-select__option {
  background-color: var(--dark-background) !important;
  color: var(--dark-text) !important;
}

.dark-mode .react-select__option--is-focused {
  background-color: var(--dark-hover) !important;
}

.dark-mode .react-select__single-value {
  color: var(--dark-text) !important;
}

.dark-mode .react-select__input {
  color: var(--dark-text) !important;
}

/* Search */
.search {
  display: flex;
  gap: 8px;
  align-items: center;
}

.search input {
  min-width: 200px;
}

.search .btn-secondary {
  height: 38px;
  display: flex;
  align-items: center;
  gap: 4px;
}
