.document-editor {
  height: 100%;
  padding: 20px;
}

.document-editor.darkmode {
  background-color: #1a202c;
  color: #fff;
}

.editor-container {
  height: calc(100vh - 180px);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.darkmode .editor-container {
  background-color: #2d3748;
}

.editor {
  height: 100%;
}

/* Quill Editor Dark Mode Overrides */
.darkmode .ql-toolbar {
  background-color: #2d3748;
  border-color: #4a5568;
}

.darkmode .ql-container {
  background-color: #2d3748;
  border-color: #4a5568;
}

.darkmode .ql-editor {
  color: #fff;
}

.darkmode .ql-picker {
  color: #fff;
}

.darkmode .ql-stroke {
  stroke: #fff;
}

.darkmode .ql-fill {
  fill: #fff;
}
