.sequence-overview {
    padding: 20px;
    height: 100%;
  }
  
  .sequence-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .header-actions {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .report-issue-btn {
    padding: 8px 16px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .report-issue-btn:hover {
    background-color: #c82333;
  }
  
  .navigation-buttons {
    display: flex;
    gap: 10px;
  }
  
  .sequence-details {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .sequence-image {
    max-width: 300px;
    border-radius: 8px;
  }
  
  .sequence-info {
    flex: 1;
  }
  
  .sequence-info p {
    margin: 10px 0;
  }
  
  .tab-container {
    display: flex;
    overflow-x: auto;
    margin-bottom: 20px;
  }
  
  .tab {
    padding: 10px 20px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .tab.active {
    background-color: #007bff;
    color: white;
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid #ddd;
  }
  
  .task-list-container {
    overflow-x: auto;
    width: 100%;
  }
  
  .task-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .task-table th,
  .task-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .task-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .task-table tr:hover {
    background-color: #f5f5f5;
  }
  
  .zoom-buttons {
    margin-bottom: 10px;
  }
  
  .zoom-buttons button {
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .zoom-buttons button:hover {
    background-color: #e0e0e0;
  }
  
  .view-controls {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
  }
  
  .view-button {
    padding: 8px 16px;
    border: 1px solid #ddd;
    background: white;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .view-button.active {
    background: #007bff;
    color: white;
    border-color: #0056b3;
  }
  
  .related-tasks-section {
    padding: 20px;
  }
  
  .sequence-thumbnail {
    max-width: 400px;
    max-height: 400px;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Dark mode untuk tabel */
  
  /* Dark mode untuk tab dan kontrol */
  
  .published-content {
  padding: 20px;
  }
  
  .published-item {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  overflow: hidden;
  }
  
  .preview-container {
  position: relative;
  background: #000;
  }
  
  .preview-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.5);
  opacity: 0;
  transition: opacity 0.3s;
  }
  
  .preview-container:hover .preview-overlay {
  opacity: 1;
  }
  
  .play-button {
  background: rgba(255,255,255,0.9);
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  }
  
  .publish-info {
  padding: 15px;
  }
  
  .publish-info h4 {
  margin: 0 0 10px 0;
  }
  
  .version {
  color: #666;
  margin: 5px 0;
  }
  
  .description {
  margin: 10px 0;
  }
  
  .timestamp {
  color: #999;
  font-size: 0.9em;
  margin: 5px 0;
  }
  
  .published-library {
  padding: 20px;
  }
  
  .library-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  padding: 20px 0;
  }
  
  .library-item {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s;
  cursor: pointer;
  }
  
  .library-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  }
  
  .video-preview {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  }
  
  .video-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  }
  
  .video-preview:hover img {
  transform: scale(1.05);
  }
  
  .video-info-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 10px;
  }
  
  .version {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  }
  
  .video-details {
  padding: 15px;
  }
  
  .video-details h4 {
  margin: 0 0 8px 0;
  font-size: 16px;
  color: #333;
  }
  
  .description {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  }
  
  .meta-info {
  font-size: 12px;
  color: #999;
  display: flex;
  align-items: center;
  gap: 8px;
  }
  
  .dot {
  font-size: 8px;
  }
  
  .version {
  background: rgba(0,0,0,0.6);
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  }
  
  .video-preview-container {
  position: relative;
  margin-bottom: 20px;
  }
  
  .open-modal-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
  }
  
  .open-modal-button:hover {
  background: rgba(0, 0, 0, 0.9);
  }
  
  .video-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  }
  
  .activity-tab-content {
  height: calc(100vh - 300px);
  overflow-y: auto;
  }
  
  .activity-tab-content .activity-panel {
  position: relative;
  right: auto;
  top: auto;
  width: 100%;
  height: auto;
  transform: none;
  box-shadow: none;
  background: transparent;
  }
  
  .activity-tab-content .panel-content {
  padding: 0;
  height: auto;
  }
  
  .activity-tab-content .panel-header {
  display: none;
  }
  
  .versions-tab-content {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  }
  
  .versions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  }
  
  .versions-header button {
  padding: 8px 16px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  }
  
  .versions-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  }
  
  .version-item {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: flex-start;
  }
  
  .version-info {
  flex: 1;
  }
  
  .version-info h4 {
  margin: 0 0 10px 0;
  color: #333;
  }
  
  .version-info p {
  margin: 0 0 10px 0;
  color: #666;
  }
  
  .version-info .timestamp {
  font-size: 0.875rem;
  color: #888;
  }
  
  .version-item video {
  border-radius: 4px;
  object-fit: cover;
  }
  
  .versions-container {
  display: flex;
  gap: 20px;
  }
  
  .versions-list {
  flex: 1;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  }
  
  .version-item {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  }
  
  .version-info {
  flex: 1;
  }
  
  .version-preview {
  position: relative;
  cursor: pointer;
  }
  
  .version-preview:hover .preview-overlay {
  opacity: 1;
  }
  
  .preview-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  }
  
  .preview-overlay span {
  color: white;
  font-size: 48px;
  }
  
  .video-player-container {
  flex: 2;
  position: sticky;
  top: 20px;
  }
  
  .approved-badge {
  background-color: #4CAF50;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 8px;
  }
  
  .approve-button {
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 8px;
  }
  
  .approve-button.approved {
  background-color: #4CAF50;
  cursor: default;
  }
  
  .video-info-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  background: rgba(0,0,0,0.7);
  color: white;
  display: flex;
  align-items: center;
  }
  .approve-button.cancel {
  background-color: #ff4d4f;
  }
  
  .approve-button.cancel:hover {
  background-color: #ff7875;
  }
  
  .approval-history-container {
  margin-top: 2rem;
  padding: 1rem;
  background: #f5f5f5;
  border-radius: 8px;
  }
  
  .approval-history-container h3 {
  margin-bottom: 1rem;
  color: #333;
  }
  
  .approval-history-list {
  max-height: 300px;
  overflow-y: auto;
  }
  
  .history-item {
  display: flex;
  align-items: flex-start;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  background: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .history-icon {
  margin-right: 1rem;
  font-size: 1.2rem;
  }
  
  .history-content {
  flex: 1;
  }
  
  .history-action {
  font-weight: 600;
  margin-bottom: 0.25rem;
  }
  
  .history-details {
  font-size: 0.9rem;
  color: #666;
  }
  
  /* Dark mode styles */
  
  /* Scrollbar styling untuk dark mode */
  
  .shot-overview {
    padding: 20px;
    height: 100%;
  }
  
  .shot-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .navigation-buttons button {
    margin-left: 10px;
  }
  
  .shot-details {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .shot-image {
    max-width: 300px;
    border-radius: 8px;
  }
  
  .shot-info {
    flex: 1;
  }
  
  .shot-info p {
    margin: 10px 0;
  }
  
  .tab-container {
    display: flex;
    overflow-x: auto;
    margin-bottom: 20px;
  }
  
  .tab {
    padding: 10px 20px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .tab.active {
    background-color: #007bff;
    color: white;
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid #ddd;
  }

  .task-list-container {
    overflow-x: auto;
    width: 100%;
  }

  .task-table {
    width: 100%;
    border-collapse: collapse;
  }

  .task-table th,
  .task-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  .task-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  .task-table tr:hover {
    background-color: #f5f5f5;
  }

  .zoom-buttons {
    margin-bottom: 10px;
  }

  .zoom-buttons button {
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
  }

  .zoom-buttons button:hover {
    background-color: #e0e0e0;
  }

  .view-controls {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
  }

  .view-button {
    padding: 8px 16px;
    border: 1px solid #ddd;
    background: white;
    cursor: pointer;
    border-radius: 4px;
  }

  .view-button.active {
    background: #007bff;
    color: white;
    border-color: #0056b3;
  }

  .related-tasks-section {
    padding: 20px;
  }

  .shot-thumbnail {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 16px;
  }

  .shot-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Dark mode untuk tabel */
  
  /* Dark mode untuk tab dan kontrol */
  
  .published-content {
  padding: 20px;
  }
  
  .published-item {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  overflow: hidden;
  }
  
  .preview-container {
  position: relative;
  background: #000;
  }
  
  .preview-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.5);
  opacity: 0;
  transition: opacity 0.3s;
  }
  
  .preview-container:hover .preview-overlay {
  opacity: 1;
  }
  
  .play-button {
  background: rgba(255,255,255,0.9);
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  }
  
  .publish-info {
  padding: 15px;
  }
  
  .publish-info h4 {
  margin: 0 0 10px 0;
  }
  
  .version {
  color: #666;
  margin: 5px 0;
  }
  
  .description {
  margin: 10px 0;
  }
  
  .timestamp {
  color: #999;
  font-size: 0.9em;
  margin: 5px 0;
  }
  
  .published-library {
  padding: 20px;
  }
  
  .library-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  padding: 20px 0;
  }
  
  .library-item {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s;
  cursor: pointer;
  }
  
  .library-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  }
  
  .video-preview {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  }
  
  .video-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  }
  
  .video-preview:hover img {
  transform: scale(1.05);
  }
  
  .video-info-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 10px;
  }
  
  .version {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  }
  
  .video-details {
  padding: 15px;
  }
  
  .video-details h4 {
  margin: 0 0 8px 0;
  font-size: 16px;
  color: #333;
  }
  
  .description {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  }
  
  .meta-info {
  font-size: 12px;
  color: #999;
  display: flex;
  align-items: center;
  gap: 8px;
  }
  
  .dot {
  font-size: 8px;
  }
  
  .version {
  background: rgba(0,0,0,0.6);
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  }
  
  .video-preview-container {
  position: relative;
  margin-bottom: 20px;
  }
  
  .open-modal-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
  }
  
  .open-modal-button:hover {
  background: rgba(0, 0, 0, 0.9);
  }
  
  .video-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  }
  
  .activity-tab-content {
  height: calc(100vh - 300px);
  overflow-y: auto;
  }
  
  .activity-tab-content .activity-panel {
  position: relative;
  right: auto;
  top: auto;
  width: 100%;
  height: auto;
  transform: none;
  box-shadow: none;
  background: transparent;
  }
  
  .activity-tab-content .panel-content {
  padding: 0;
  height: auto;
  }
  
  .activity-tab-content .panel-header {
  display: none;
  }
  
  .versions-tab-content {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  }
  
  .versions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  }
  
  .versions-header button {
  padding: 8px 16px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  }
  
  .versions-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  }
  
  .version-item {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: flex-start;
  }
  
  .version-info {
  flex: 1;
  }
  
  .version-info h4 {
  margin: 0 0 10px 0;
  color: #333;
  }
  
  .version-info p {
  margin: 0 0 10px 0;
  color: #666;
  }
  
  .version-info .timestamp {
  font-size: 0.875rem;
  color: #888;
  }
  
  .version-item video {
  border-radius: 4px;
  object-fit: cover;
  }
  
  .versions-container {
  display: flex;
  gap: 20px;
  }
  
  .versions-list {
  flex: 1;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  }
  
  .version-item {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  }
  
  .version-info {
  flex: 1;
  }
  
  .version-preview {
  position: relative;
  cursor: pointer;
  }
  
  .version-preview:hover .preview-overlay {
  opacity: 1;
  }
  
  .preview-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  }
  
  .preview-overlay span {
  color: white;
  font-size: 48px;
  }
  
  .video-player-container {
  flex: 2;
  position: sticky;
  top: 20px;
  }
  
  .approved-badge {
  background-color: #4CAF50;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 8px;
  }
  
  .approve-button {
  background-color: #2196F3;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 8px;
  }
  
  .approve-button.approved {
  background-color: #4CAF50;
  cursor: default;
  }
  
  .video-info-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  background: rgba(0,0,0,0.7);
  color: white;
  display: flex;
  align-items: center;
  }
  .approve-button.cancel {
  background-color: #ff4d4f;
  }
  
  .approve-button.cancel:hover {
  background-color: #ff7875;
  }
  
  .approval-history-container {
  margin-top: 2rem;
  padding: 1rem;
  background: #f5f5f5;
  border-radius: 8px;
  }
  
  .approval-history-container h3 {
  margin-bottom: 1rem;
  color: #333;
  }
  
  .approval-history-list {
  max-height: 300px;
  overflow-y: auto;
  }
  
  .history-item {
  display: flex;
  align-items: flex-start;
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  background: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .history-icon {
  margin-right: 1rem;
  font-size: 1.2rem;
  }
  
  .history-content {
  flex: 1;
  }
  
  .history-action {
  font-weight: 600;
  margin-bottom: 0.25rem;
  }
  
  .history-details {
  font-size: 0.9rem;
  color: #666;
  }
  
  /* Dark mode styles */
  
  /* Scrollbar styling untuk dark mode */
  
  .shot-info-container {
    padding: 20px;
    background-color: var(--background-color);
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .shot-info-section {
    margin-bottom: 30px;
  }
  
  .shot-info-section h3 {
    margin-bottom: 15px;
    color: var(--primary-color);
  }
  
  .info-row {
    display: flex;
    margin-bottom: 10px;
  }
  
  .info-label {
    width: 150px;
    font-weight: 500;
    color: var(--text-color);
  }
  
  .info-value {
    flex: 1;
    color: var(--text-color-secondary);
  }
  
  .asset-thumbnail {
    width: 100px;
    height: 60px;
    margin-right: 20px;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .asset-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .overview-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 15px;
    background-color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .overview-thumbnail {
    width: 120px;
    height: 80px;
    border-radius: 4px;
    margin-right: 20px;
    object-fit: cover;
  }
  
  .header-content {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  
  .header-actions {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
  }
  
  .report-issue-button {
    background-color: #ff4444;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }
  
  .report-issue-button:hover {
    background-color: #cc0000;
  }
  
  