.roadmap-container {
  padding: 32px;
  background: #f8f9fa;
  min-height: 100vh;
}

.roadmap-header {
  background: white;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 24px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}
.header-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.epic-count {
  color: #6B778C;
  font-size: 14px;
  padding: 4px 8px;
  background: #F4F5F7;
  border-radius: 3px;
}

.header-actions {
  display: flex;
  gap: 16px;
  align-items: center;
}

.search-bar {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  max-width: 400px;
}

.search-bar input {
  border: none;
  background: none;
  width: 100%;
  font-size: 14px;
}

.filter-btn {
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #dee2e6;
  background: white;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-btn:hover {
  background: #f8f9fa;
}

.filter-btn.active {
  background: #e3f2fd;
  border-color: #0d6efd;
  color: #0d6efd;
}

.add-epic-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.add-epic-btn:hover {
  background-color: #45a049;
}

.add-epic-btn svg {
  font-size: 1rem;
}

.epics-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.epic-item {
  background: white;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
  border-left: 4px solid transparent;
}

.epic-item:hover {
  transform: translateX(4px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.epic-item.priority-high { border-left-color: #dc3545; }
.epic-item.priority-medium { border-left-color: #ffc107; }
.epic-item.priority-low { border-left-color: #28a745; }

.epic-item.status-todo { background-color: #F4F5F7; }
.epic-item.status-in-progress { background-color: #DEEBFF; }
.epic-item.status-done { background-color: #E3FCEF; }

.epic-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.epic-header h3 {
  font-size: 18px;
  margin: 0;
  color: #212529;
}

.darkmode .epic-header h3 {
  color: var(--dark-text-primary);
  background-color: var(--dark-bg-secondary);
  border-bottom: 1px solid var(--dark-border-primary);
}

.epic-indicators {
  display: flex;
  gap: 12px;
}

.epic-status {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.epic-status.todo { background: #f8f9fa; color: #6c757d; }
.epic-status.in-progress { background: #e3f2fd; color: #0d6efd; }
.epic-status.done { background: #d4edda; color: #198754; }

.progress-container {
  background: #e9ecef;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
  margin: 8px 0;
}

.progress-bar {
  height: 100%;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.progress-bar.high { background: linear-gradient(90deg, #dc3545 0%, #ff6b6b 100%); }
.progress-bar.medium { background: linear-gradient(90deg, #ffc107 0%, #ffdb4d 100%); }
.progress-bar.low { background: linear-gradient(90deg, #28a745 0%, #34ce57 100%); }

.epic-description {
  color: #6B778C;
  font-size: 14px;
  margin-bottom: 16px;
}

.epic-details {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 13px;
  color: #6B778C;
}

.epic-assignee {
  display: flex;
  align-items: center;
  gap: 8px;
}

.epic-assignee img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.progress-container {
  flex: 1;
  background: #F4F5F7;
  height: 4px;
  border-radius: 2px;
  position: relative;
}

.progress-bar {
  height: 100%;
  background: #0052CC;
  border-radius: 2px;
}

.progress-text {
  position: absolute;
  right: -30px;
  top: -8px;
  font-size: 12px;
}

/* Dark mode */

.view-toggle {
  display: flex;
  gap: 4px;
  margin-right: 12px;
}

.view-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;
  border: 1px solid #DFE1E6;
  background: #F4F5F7;
  color: #42526E;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.view-btn.active {
  background: #0052CC;
  color: white;
  border-color: #0052CC;
}

.gantt-view {
  height: calc(100vh - 200px);
  margin-top: 20px;
}

/* Dark mode */

.filter-panel {
  background: #fff;
  border: 1px solid #DFE1E6;
  border-radius: 4px;
  padding: 16px;
  margin: 16px 0;
  display: flex;
  gap: 24px;
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter-group label {
  font-size: 12px;
  font-weight: 500;
  color: #6B778C;
}

.filter-group select {
  padding: 8px;
  border: 1px solid #DFE1E6;
  border-radius: 4px;
  min-width: 200px;
}

.filter-btn.active {
  background: #0052CC;
  color: white;
}

/* Dark mode */

.roadmap-statistics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
}

.stat-item {
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s ease;
}

.stat-item:hover {
  transform: translateY(-2px);
}

.stat-item h3 {
  color: #6c757d;
  font-size: 14px;
  margin-bottom: 8px;
}

.stat-item span {
  font-size: 24px;
  font-weight: 600;
}

.timeline-toggle {
  display: flex;
  gap: 8px;
  margin-left: 16px;
}

.timeline-toggle button {
  padding: 6px 12px;
  border: 1px solid #DFE1E6;
  background: #F4F5F7;
  border-radius: 3px;
  font-size: 13px;
  color: #42526E;
  cursor: pointer;
}

.timeline-toggle button.active {
  background: #0052CC;
  color: white;
  border-color: #0052CC;
}

.timeline-groups {
  display: flex;
  flex-direction: column;
  gap: 24px;
}



.period-header {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #DFE1E6;
  margin-bottom: 16px;
  color: #172B4D;
}

.period-header svg {
  color: #6B778C;
}

.period-header .epic-count {
  margin-left: auto;
}

/* Dark mode styles */

/* Progress bar styling yang lebih detail */
.epic-progress {
  position: relative;
  margin: 12px 0;
}

.progress-container {
  height: 8px;
  background: #F4F5F7;
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  transition: width 0.3s ease;
}

.progress-bar.high {
  background: linear-gradient(90deg, #FF5630 0%, #FF8B6B 100%);
}

.progress-bar.medium {
  background: linear-gradient(90deg, #FFAB00 0%, #FFD666 100%);
}

.progress-bar.low {
  background: linear-gradient(90deg, #36B37E 0%, #79F2C0 100%);
}

.progress-details {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  font-size: 12px;
  color: #6B778C;
}

/* Status badges dengan animasi */
.epic-status {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;
  transition: all 0.2s ease;
}

.epic-status.todo {
  background: #EAE6FF;
  color: #403294;
}

.epic-status.in-progress {
  background: #DEEBFF;
  color: #0747A6;
  animation: pulse 2s infinite;
}

.epic-status.done {
  background: #E3FCEF;
  color: #006644;
}

.epic-status svg {
  margin-right: 4px;
  font-size: 10px;
}

/* Priority indicators */
.priority-indicator {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 500;
}

.priority-indicator.high {
  background: #FFEBE6;
  color: #DE350B;
}

.priority-indicator.medium {
  background: #FFFAE6;
  color: #974F0C;
}

.priority-indicator.low {
  background: #E3FCEF;
  color: #006644;
}

/* Hover effects */
.epic-item {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.epic-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

/* Dark mode adjustments */

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.advanced-filter-panel {
  background: #fff;
  border: 1px solid #DFE1E6;
  border-radius: 8px;
  padding: 20px;
  margin: 16px 0;
}

.filter-section {
  margin-bottom: 20px;
}

.filter-section h4 {
  font-size: 14px;
  color: #172B4D;
  margin-bottom: 8px;
}

.date-range {
  display: flex;
  gap: 12px;
  align-items: center;
}

.date-range input[type="date"] {
  padding: 8px;
  border: 1px solid #DFE1E6;
  border-radius: 4px;
  font-size: 14px;
}

.progress-range {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.progress-range input[type="range"] {
  width: 100%;
}

.multiple-select {
  position: relative;
}

.multiple-select-header {
  padding: 8px;
  border: 1px solid #DFE1E6;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #DFE1E6;
  border-radius: 4px;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

/* Dark mode */

.select-option {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.select-option:hover {
  background: #F4F5F7;
}

.checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #DFE1E6;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0052CC;
}

.export-dropdown {
  position: relative;
  display: inline-block;
}

.export-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #0052CC;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.export-options {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #DFE1E6;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  display: none;
  z-index: 1000;
}

.export-dropdown:hover .export-options {
  display: block;
}

.export-options button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
}

.export-options button:hover {
  background: #F4F5F7;
}

/* Dark mode */

.toolbar-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
  padding: 12px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.import-export-group {
  display: flex;
  gap: 8px;
}

.toolbar-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #F4F5F7;
  border: 1px solid #DFE1E6;
  border-radius: 4px;
  color: #172B4D;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.toolbar-button:hover {
  background: #EBECF0;
}

.import-error {
  color: #DE350B;
  margin-top: 8px;
  font-size: 12px;
}

/* Dark mode */

.import-error-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  max-width: 500px;
  width: 90%;
  z-index: 1000;
}

.error-header {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #DE350B;
  margin-bottom: 16px;
}

.error-content {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 16px;
}

.error-content p {
  margin: 8px 0;
  color: #172B4D;
  font-size: 14px;
}

.error-footer button {
  padding: 8px 16px;
  background: #DE350B;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Dark mode */

@keyframes darkPulse {
  0% {
    opacity: 1;
    background: #1a365d;
  }
  50% {
    opacity: 0.7;
    background: #2a4365;
  }
  100% {
    opacity: 1;
    background: #1a365d;
  }
}
