

.messenger.dark-mode {
  background: #1a1a1a;
  color: #ffffff;
}

.messenger.open {
  opacity: 1;
  pointer-events: all;
}

.messenger-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
 
  margin: 0 auto;
  padding: 20px;
}



.messenger-header h3 {
  margin: 0;
  font-size: 16px;
}

.messenger-controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.messenger-controls button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  color: #666;
}

.dark-mode .messenger-controls button {
  color: #aaa;
}

.messenger-controls button:hover {
  background: rgba(0, 0, 0, 0.05);
  color: #333;
}

.dark-mode .messenger-controls button:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.messenger-controls .close-button:hover {
  color: #dc3545;
}

.messenger-minimized {
  position: fixed;
  left: 20px;
  bottom: 20px;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  border-radius: 8px;
  display: flex;
}

.conversation-name .notification-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #e53e3e;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 11px;
  font-weight: bold;
  margin-left: 8px;
  vertical-align: middle;
}

.messenger-minimized {
  align-items: center;
  gap: 10px;
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.2s ease;
}

.dark-mode.messenger-minimized {
  background: #1a1a1a;
  color: #ffffff;
}

.messenger-minimized:hover {
  transform: translateY(-2px);
}

.minimized-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #007bff;
}

.unread-indicator {
  width: 8px;
  height: 8px;
  background: #dc3545;
  border-radius: 50%;
  margin-left: 5px;
}

.messenger-content {
  display: flex;
  overflow: hidden;
  flex: 1;
  background: white;
}

.dark-mode .messenger-content {
  background: #1a1a1a;
}

.conversations-list {
  width: 300px;
  border-right: 1px solid #eee;
  overflow-y: auto;
  background: white;
}

.dark-mode .conversations-list {
  background: #1a1a1a;
  border-right-color: #404040;
}

.conversations-list {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #f5f5f5;
}

.conversation-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
}

.conversation-item:hover {
  background-color: #e6e6e6;
}

.conversation-item.active {
  background-color: #e0e0e0;
}

.conversation-avatar {
  margin-right: 10px;
}

.conversation-avatar img.avatar,
.conversation-avatar .group-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.conversation-details {
  flex-grow: 1;
}

.conversation-name {
  font-weight: bold;
  font-size: 14px;
}

.conversation-last-message {
  color: #666;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-message-btn {
  width: 100%;
  padding: 10px;
  background: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.new-message-btn:hover {
  background: #0056b3;
}

.conversation-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  gap: 10px;
}

.message-avatar, .user-avatar, .conversation-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
}

.message-avatar img, .user-avatar img, .conversation-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.message-balloon {
  padding: 10px;
  border-radius: 15px;
  max-width: 70%;
  position: relative;
  word-wrap: break-word;
  display: flex;
  align-items: flex-start;
}

.message-content {
  margin-bottom: 5px;
  flex: 1;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
}

.dark-mode .conversation-item {
  border-bottom-color: #404040;
}

.conversation-item:hover {
  background: #f8f9fa;
}

.dark-mode .conversation-item:hover {
  background: #2d2d2d;
}

.conversation-item.active {
  background: #e9ecef;
}

.dark-mode .conversation-item.active {
  background: #404040;
}

.conversation-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9ecef;
  flex-shrink: 0;
}

.dark-mode .conversation-avatar {
  background: #2d2d2d;
}

.conversation-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.conversation-avatar svg {
  width: 20px;
  height: 20px;
  color: #6c757d;
}

.dark-mode .conversation-avatar svg {
  color: #aaa;
}

.conversation-info {
  flex: 1;
  min-width: 0;
}

.conversation-name {
  font-weight: 500;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conversation-preview {
  font-size: 12px;
  color: #6c757d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dark-mode .conversation-preview {
  color: #aaa;
}

.messages-container {
  flex: 1;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  background: white;
}

.dark-mode .messages-container {
  background: #1a1a1a;
}

.messages-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.messages-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.group-options-menu {
  display: flex;
  align-items: center;
}

.pinned-messages-container {
  background-color: #f0f2f5;
  padding: 10px;
  max-height: 150px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-bottom: 1px solid #e0e0e0;
}

.pinned-message {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: background-color 0.2s;
}

.pinned-message:hover {
  background-color: #f5f5f5;
}

.pinned-message-content {
  flex-grow: 1;
  margin-left: 10px;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pinned-message-actions {
  display: flex;
  align-items: center;
  gap: 5px;
}

.unpin-message-btn {
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  transition: color 0.2s;
}

.unpin-message-btn:hover {
  color: #333;
}

.messages-list {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
}

.messages-header {
  padding: 15px;
  border-bottom: 1px solid #eee;
  background: #f8f9fa;
}

.dark-mode .messages-header {
  background: #2d2d2d;
  border-bottom-color: #404040;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9ecef;
}

.dark-mode .user-avatar {
  background: #2d2d2d;
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-avatar svg {
  width: 20px;
  height: 20px;
  color: #6c757d;
}

.dark-mode .user-avatar svg {
  color: #aaa;
}

.user-name {
  font-weight: 500;
  font-size: 16px;
  color: #020202;
}

.dark-mode .user-name {
  color: #e0e0e0;
}

.message-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.message-container.sent {
  align-items: flex-end;
}

.message-container.received {
  align-items: flex-start;
}

.message-reactions {
  display: flex;
  gap: 5px;
  position: absolute;
  bottom: 100%;
  background: white;
  border-radius: 15px;
  padding: 2px 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  z-index: 10;
}

.message-container.sent .message-reactions {
  right: 10px;
}

.message-container.received .message-reactions {
  left: 10px;
}

.reaction-emoji {
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s;
}

.reaction-emoji:hover {
  transform: scale(1.2);
}

.message-balloon {
  position: relative;
  max-width: 70%;
  padding: 10px;
  border-radius: 10px;
}

.message-balloon.sent {
  align-self: flex-end;
  background-color: #dcf8c6;
  margin-right: 10px;
  color: #111;
}

.message-balloon.received {
  align-self: flex-start;
  background-color: #ffffff;
  margin-left: 10px;
  color: #111;
}

.message-time {
  font-size: 0.7em;
  color: #888;
  margin-top: 5px;
  text-align: right;
}

.message {
  margin-bottom: 15px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
}

.message.sent {
  flex-direction: row-reverse;
}

.message-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
}

.dark-mode .message-avatar {
  background: #2d2d2d;
}

.message-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.message-avatar svg {
  width: 16px;
  height: 16px;
  color: #6c757d;
}

.dark-mode .message-avatar svg {
  color: #aaa;
}

.message-bubble {
  max-width: 80%;
}

.message-sender {
  font-size: 12px;
  color: #6c757d;
  margin-bottom: 2px;
}

.dark-mode .message-sender {
  color: #aaa;
}

.message-content {
  padding: 8px 12px;
  border-radius: 15px;
  background: #007bff;
  color: white;
  word-wrap: break-word;
}

.message.received .message-content {
  background: #e9ecef;
  color: #212529;
}

.dark-mode .message.received .message-content {
  background: #2d2d2d;
  color: #ffffff;
}

.message.sent {
  margin-left: auto;
}

.message.received {
  margin-right: auto;
}

.message-time {
  font-size: 12px;
  color: #6c757d;
  margin-top: 4px;
  text-align: right;
}

.dark-mode .message-time {
  color: #aaa;
}

.message-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #eee;
  background: white;
}

.dark-mode .message-input {
  background: #1a1a1a;
  border-top-color: #404040;
}

.message-input input[type="text"] {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-right: 10px;
  outline: none;
  transition: border-color 0.3s;
}

.message-input input[type="text"]:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.3);
}

.dark-mode .message-input input[type="text"] {
  background: #2d2d2d;
  border-color: #404040;
  color: #ffffff;
}

.message-textarea {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-right: 10px;
  resize: none;
  outline: none;
  transition: border-color 0.3s;
  line-height: 1.5;
  min-height: 60px;
  max-height: 120px;
  overflow-y: auto;
}

.message-textarea:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.3);
}

.dark-mode .message-textarea {
  background: #2d2d2d;
  border-color: #404040;
  color: #ffffff;
}

.message-input button {
  padding: 8px 16px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.message-input button:hover {
  background: #0056b3;
}

.message-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.delete-message-btn, 
.pin-message-btn, 
.like-message-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.2s;
  padding: 4px 8px;
  border-radius: 12px;
}

.delete-message-btn:hover, 
.pin-message-btn:hover, 
.like-message-btn:hover {
  transform: scale(1.2);
}

.pin-message-btn.pinned {
  color: #007bff;
}

.like-message-btn.liked {
  color: #e74c3c;
  animation: heartBeat 0.5s;
}

@keyframes heartBeat {
  0% { transform: scale(1); }
  25% { transform: scale(1.3); }
  50% { transform: scale(1); }
  75% { transform: scale(1.3); }
  100% { transform: scale(1); }
}

.pin-message-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1em;
  opacity: 0.5;
  transition: opacity 0.2s;
}

.pin-message-btn:hover {
  opacity: 0.8;
}

.pin-message-btn.pinned {
  opacity: 1;
}

.like-section {
  position: relative;
  display: inline-block;
}

.emoji-picker {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  z-index: 10;
}

.like-section:hover .emoji-picker {
  display: flex;
  gap: 5px;
}

.emoji-picker span {
  cursor: pointer;
  font-size: 18px;
  transition: transform 0.2s;
}

.emoji-picker span:hover {
  transform: scale(1.3);
}

.pinned-messages-section {
  background: #656565;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 2px;
}

.pinned-message {
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  font-size: 0.9em;
  color: #333;
}

.highlighted-message {
  animation: highlight-message 2s ease-in-out;
}

@keyframes highlight-message {
  0%, 100% {
    background-color: transparent;
  }
  20%, 80% {
    background-color: rgba(255, 255, 0, 0.2);
  }
}

.pinned-message {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
  padding: 5px;
  border-radius: 5px;
}

.pinned-message:hover {
  background-color: rgba(0,0,0,0.1);
}

.pinned-message-icon {
  margin-right: 10px;
  font-size: 1em;
}

.pinned-message-content {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-attachment {
  margin-top: 10px;
  max-width: 300px;
  border-radius: 8px;
  overflow: hidden;
}

.message-attachment-image {
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.message-attachment-image:hover {
  transform: scale(1.05);
}

.message-attachment-link {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  text-decoration: none;
  color: #333;
  border-radius: 8px;
}

.message-attachment-link i {
  margin-right: 10px;
  font-size: 24px;
  color: #007bff;
}

.message-attachment-link:hover {
  background-color: #e0e0e0;
}

.attachment-preview {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 8px 12px;
  margin-bottom: 10px;
  border-radius: 8px;
  max-width: 300px;
}

.attachment-preview span {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.attachment-preview button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 12px;
}

.attachment-preview button:hover {
  background-color: #ff3333;
}

.input-container {
  position: relative;
}

.suggestions-container {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  z-index: 10;
}

.suggestions {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
}

.suggestion {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.suggestion:hover {
  background-color: #f0f0f0;
}

.dark-mode .suggestions {
  background-color: #333;
  border-color: #555;
}

.dark-mode .suggestion:hover {
  background-color: #444;
}

.user-selection-group {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  margin-top: 10px;
}

.user-item {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.user-item:hover {
  background-color: #f0f0f0;
}

.group-member {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 5px 10px;
  margin: 5px 0;
  border-radius: 4px;
}

.group-member button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.group-member.admin {
  background-color: #e6f3ff;
  color: #0066cc;
  font-weight: bold;
}

.sidebar-actions {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f5f5;
}

.new-message-btn,
.new-group-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.new-message-btn:hover,
.new-group-btn:hover {
  background-color: #45a049;
}

.new-group-form {
  padding: 10px;
  background-color: #f5f5f5;
}

.new-group-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.group-members-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.user-item {
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.user-item.selected {
  background-color: #e0e0e0;
}

.user-item img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.selected-members {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.selected-member {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  padding: 5px;
  border-radius: 5px;
}

.selected-member button {
  background: none;
  border: none;
  margin-left: 5px;
  cursor: pointer;
}

.group-form-actions {
  display: flex;
  justify-content: space-between;
}

.create-group-btn,
.cancel-group-btn {
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.create-group-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
}

.cancel-group-btn {
  background-color: #f44336;
  color: white;
  border: none;
}

.group-members {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  font-size: 0.8em;
  color: #666;
  margin-top: 5px;
}

.group-member {
  background-color: #f0f0f0;
  padding: 2px 5px;
  border-radius: 3px;
}

/* Resize handle styles */
.react-resizable {
  position: relative;
  background: white;
  border-radius: 8px;
}

.dark-mode .react-resizable {
  background: #1a1a1a;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IEwgNiA2IEwgNiA2IEwgNiA2IEwgNiA2IEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.dark-mode .react-resizable-handle {
  filter: invert(1);
}

.react-resizable-handle-e {
  right: 0;
  top: 50%;
  margin-top: -10px;
  cursor: e-resize;
}

.react-resizable-handle-s {
  bottom: 0;
  left: 50%;
  margin-left: -10px;
  cursor: s-resize;
}

.upload-status {
  font-size: 0.8em;
  margin-top: 5px;
}

.upload-status.success {
  color: green;
}

.upload-status.error {
  color: red;
}
