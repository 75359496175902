.project-list {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100vh;
  }
  
  .project-list h2 {
    margin-bottom: 20px;
  }
  
  .project-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .project-list li {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
  }
  
  .project-list li:hover {
    background-color: #f5f5f5;
  }
  
  .add-project-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .add-project-button:hover {
    background-color: #45a049;
  }

.project-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.project-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
}

.delete-project-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.delete-project-button:hover {
  background-color: #d32f2f;
}

.delete-confirmation-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
}

.confirm-delete-button,
.cancel-delete-button {
  margin: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-delete-button {
  background-color: #f44336;
  color: white;
}

.cancel-delete-button {
  background-color: #4CAF50;
  color: white;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 24px;
  padding: 20px;
}

.project-card {
  position: relative;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  transition: all 0.2s ease;
  border: 3px solid transparent;
}

.project-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.project-thumbnail-container {
  width: 100%;
  height: 400px;
  overflow: hidden;
  cursor: pointer;
}

.project-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-thumbnail:hover {
  transform: scale(1.05);
}

.project-thumbnail-placeholder {
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-thumbnail-placeholder span {
  font-size: 48px;
  color: #999;
}

.project-info {
  padding: 16px;
}

.project-info h3 {
  margin: 0 0 8px 0;
  font-size: 18px;
}

.project-description {
  color: #666;
  font-size: 14px;
  margin: 0;
  line-height: 1.4;
}

.project-actions {
  display: flex;
  gap: 12px;
  padding: 16px;
  border-top: 1px solid #eee;
}

.detail-button, 
.delete-project-button {
  flex: 1;
  padding: 8px 16px;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.detail-button {
  background-color: #007bff;
  color: white;
}

.delete-project-button {
  background-color: #dc3545;
  color: white;
}

.detail-button:hover {
  background-color: #0056b3;
}

.delete-project-button:hover {
  background-color: #c82333;
}

.project-card.active {
  border-color: #4CAF50;
  background-color: #f1f8f1;
}

.project-card.active::before {
  content: 'Active Project';
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #4CAF50;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  z-index: 2;
}

.project-card.active .project-info {
  background-color: #f1f8f1;
}

.project-card.active .project-actions {
  background-color: #f1f8f1;
  border-top: 1px solid #4CAF50;
}

.active-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #4CAF50;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.project-thumbnail-container {
  position: relative;
}

.project-card {
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.project-card.active {
  border: 3px solid #4CAF50;
  background-color: rgba(76, 175, 80, 0.1);
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(76, 175, 80, 0.2);
}

.active-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #4CAF50;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  z-index: 2;
}

