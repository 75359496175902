.render-manager {
    padding: 20px;
}

.render-preview {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
}

.job-details {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
}

.status-badge {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.85em;
    font-weight: 500;
}

.progress-bar {
    height: 8px;
    border-radius: 4px;
    margin: 5px 0;
}

.job-row {
    cursor: pointer;
    transition: background-color 0.2s;
}

.job-row:hover {
    background-color: #f7fafc;
}
