.board-wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

/* Left Sidebar */
.board-sidebar {
  width: 48px;
  background-color: #f5f5f5;
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;
}

.sidebar-tools {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tool-button {
  width: 32px;
  height: 32px;
  border: none;
  background: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  cursor: pointer;
}

.tool-button:hover {
  background-color: #e0e0e0;
  color: #333;
}

/* Main Content */
.board-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Top Toolbar */
.board-toolbar {
  height: 56px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.toolbar-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.back-button {
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
}

.board-type-button {
  padding: 4px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: none;
  cursor: pointer;
}

.toolbar-right {
  display: flex;
  align-items: center;
  gap: 8px;
}

.toolbar-button {
  padding: 6px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
}

.toolbar-button:hover {
  background-color: #f5f5f5;
}

/* Board Container */
.board-container {
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow: auto;
  background-color: #f8f9fa;
}

.board-columns {
  display: flex;
  gap: 20px;
  height: 100%;
}

.board-column {
  flex: 0 0 300px;
  background-color: #f1f3f5;
  border-radius: 8px;
  padding: 12px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.column-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.column-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.column-actions {
  display: flex;
  gap: 8px;
}

.add-card-btn,
.column-menu-btn {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #666;
}

.add-card-btn:hover,
.column-menu-btn:hover {
  color: #333;
}

.column-content {
  flex: 1;
  overflow-y: auto;
  padding: 4px;
}

.column-content.dragging-over {
  background-color: rgba(0, 102, 204, 0.1);
}

.board-card {
  background: white;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: grab;
}

.board-card:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.board-card.dragging {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}

.card-title {
  font-weight: 500;
  margin-bottom: 8px;
}

.card-description {
  font-size: 14px;
  color: #666;
}

/* ReactFlow Styles */
.reactflow-wrapper {
  width: 100%;
  height: 100%;
}

.task-node {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 250px;
  min-height: 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.task-node-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 600;
}

.node-menu-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
}

.task-node-content {
  flex-grow: 1;
  padding: 10px;
  color: #333;
}

.task-node-footer {
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
}

.task-assignee {
  font-size: 12px;
  color: #666;
}

/* Task Type Styles */
.task-node.todo {
  border-left: 4px solid #3498db;
}

.task-node.in-progress {
  border-left: 4px solid #f39c12;
}

.task-node.done {
  border-left: 4px solid #2ecc71;
}

/* Dark Mode Styles */
.board-wrapper.darkmode {
  background-color: var(--dark-bg-primary);
}

.board-sidebar.darkmode {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
}

.board-sidebar.darkmode .tool-button {
  color: var(--dark-text-secondary);
}

.board-sidebar.darkmode .tool-button:hover {
  background-color: var(--dark-bg-hover);
  color: var(--dark-text-primary);
}

.board-toolbar.darkmode {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
}

.board-toolbar.darkmode .back-button {
  color: var(--dark-text-secondary);
}

.board-toolbar.darkmode .board-type-button,
.board-toolbar.darkmode .toolbar-button {
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.board-toolbar.darkmode .board-type-button:hover,
.board-toolbar.darkmode .toolbar-button:hover {
  background-color: var(--dark-bg-hover);
}

.board-container.darkmode {
  background-color: var(--dark-bg-primary);
}

.board-column.darkmode {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
}

.column-header.darkmode {
  color: var(--dark-text-primary);
}

.column-header.darkmode .add-card-btn,
.column-header.darkmode .column-menu-btn {
  color: var(--dark-text-secondary);
}

.column-header.darkmode .add-card-btn:hover,
.column-header.darkmode .column-menu-btn:hover {
  color: var(--dark-text-primary);
}

.board-card.darkmode {
  background-color: var(--dark-bg-tertiary);
  color: var(--dark-text-primary);
  border: 1px solid var(--dark-border-primary);
}

.board-card.darkmode:hover {
  background-color: var(--dark-bg-hover);
}

.board-card.darkmode .card-description {
  color: var(--dark-text-secondary);
}

.board-wrapper.darkmode .task-node {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.board-wrapper.darkmode .task-node-header {
  border-bottom-color: var(--dark-border-primary);
}

.board-wrapper.darkmode .node-menu-btn {
  color: var(--dark-text-secondary);
}

.board-wrapper.darkmode .task-node-content {
  color: var(--dark-text-primary);
}

.board-wrapper.darkmode .task-node-footer {
  border-top-color: var(--dark-border-primary);
}

.board-wrapper.darkmode .task-assignee {
  color: var(--dark-text-secondary);
}

.board-wrapper.darkmode .task-node.todo {
  border-left-color: #4a90e2;
}

.board-wrapper.darkmode .task-node.in-progress {
  border-left-color: #f39c12;
}

.board-wrapper.darkmode .task-node.done {
  border-left-color: #2ecc71;
}

/* ReactFlow Controls and Background */
.react-flow__controls {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.react-flow__minimap {
  background-color: #f8f9fa;
  border: 1px solid #e0e0e0;
}

.board-wrapper.darkmode .react-flow__controls {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
}

.board-wrapper.darkmode .react-flow__minimap {
  background-color: var(--dark-bg-secondary);
  border-color: var(--dark-border-primary);
}

/* Node Edit Modal */
.node-edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.node-edit-content {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.node-edit-content h3 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.edit-form-group {
  margin-bottom: 16px;
}

.edit-form-group label {
  display: block;
  margin-bottom: 6px;
  color: #666;
  font-weight: 500;
}

.edit-form-group input,
.edit-form-group textarea,
.edit-form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
}

.edit-form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-actions button {
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.modal-actions button:first-child {
  background-color: #4CAF50;
  color: white;
}

.modal-actions button:last-child {
  background-color: #f44336;
  color: white;
}

/* Node Actions */
.node-actions {
  display: flex;
  gap: 8px;
}

.node-edit-btn,
.node-menu-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 4px;
  border-radius: 4px;
}

.node-edit-btn:hover,
.node-menu-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #333;
}

/* Dark Mode Styles for Modal */
.board-wrapper.darkmode .node-edit-modal {
  background-color: rgba(0, 0, 0, 0.7);
}

.board-wrapper.darkmode .node-edit-content {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
}

.board-wrapper.darkmode .node-edit-content h3 {
  color: var(--dark-text-primary);
}

.board-wrapper.darkmode .edit-form-group label {
  color: var(--dark-text-secondary);
}

.board-wrapper.darkmode .edit-form-group input,
.board-wrapper.darkmode .edit-form-group textarea,
.board-wrapper.darkmode .edit-form-group select {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.board-wrapper.darkmode .modal-actions button:first-child {
  background-color: #388E3C;
}

.board-wrapper.darkmode .modal-actions button:last-child {
  background-color: #D32F2F;
}

.board-wrapper.darkmode .node-actions .node-edit-btn,
.board-wrapper.darkmode .node-actions .node-menu-btn {
  color: var(--dark-text-secondary);
}

.board-wrapper.darkmode .node-actions .node-edit-btn:hover,
.board-wrapper.darkmode .node-actions .node-menu-btn:hover {
  background-color: var(--dark-bg-hover);
  color: var(--dark-text-primary);
}

/* Mode Editing Styles */
.board-container.editing-mode {
  background-color: rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s ease;
}

.task-node.editing-mode {
  border: 2px dashed var(--primary-color);
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.3);
  transition: all 0.3s ease;
}

.task-node.editing-mode .node-actions {
  opacity: 1;
  visibility: visible;
}

.board-container:not(.editing-mode) .reactflow-wrapper {
  pointer-events: none;
}

.board-container.editing-mode .reactflow-wrapper {
  pointer-events: auto;
}

/* Tambahan styling untuk node saat mode editing */
.task-node.editing-mode .task-node-header {
  background-color: rgba(0, 123, 255, 0.1);
}

.task-node.editing-mode .task-node-content {
  opacity: 0.8;
}

/* Styling untuk NodeResizer */
.react-flow__node-resizer {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.task-node.editing-mode .react-flow__node-resizer {
  opacity: 1;
}

/* Plus Menu Styles */
.plus-menu {
  position: absolute;
  z-index: 1000;
  background-color: var(--light-bg-primary);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 8px;
  min-width: 200px;
  animation: fadeIn 0.2s ease;
}

.plus-menu.darkmode {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
}

.plus-menu-content {
  display: flex;
  flex-direction: column;
}

.plus-menu-item {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 4px;
}

.plus-menu-item:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.plus-menu-item svg {
  margin-right: 10px;
  color: var(--primary-color);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Sidebar Plus Menu Styles */
.sidebar-plus-menu {
  position: absolute;
  z-index: 1000;
  background-color: var(--light-bg-primary);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 8px;
  min-width: 200px;
  animation: fadeInSide 0.2s ease;
}

.sidebar-plus-menu.darkmode {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
}

@keyframes fadeInSide {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Custom Node Styles */
.custom-node {
  background-color: var(--light-bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 12px;
  min-width: 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: relative;
}

.custom-node.darkmode {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
  border-color: var(--dark-border-color);
}

.custom-node .node-content {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-node.text-node {
  background-color: rgba(0, 123, 255, 0.05);
  border-color: rgba(0, 123, 255, 0.3);
}

.custom-node.code-node {
  background-color: rgba(40, 167, 69, 0.05);
  border-color: rgba(40, 167, 69, 0.3);
}

.custom-node .node-content pre {
  margin: 0;
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 0.8em;
  max-height: 100px;
  overflow: auto;
}

.react-flow__handle {
  background-color: var(--primary-color);
  width: 10px;
  height: 10px;
  border: none;
}

.react-flow__handle-left {
  left: -5px;
}

.react-flow__handle-right {
  right: -5px;
}

/* Node Selection Styles */
.task-node.selected,
.custom-node.selected {
  outline: 3px solid var(--primary-color);
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.3);
  transition: all 0.2s ease;
  z-index: 10;
}

.task-node.selected .task-node-header,
.custom-node.selected .node-content {
  background-color: rgba(0, 123, 255, 0.05);
}

/* Remove delete button styles */
.task-node .node-delete-btn,
.custom-node .node-delete-btn {
  display: none;
}

/* Inline Editing Styles */
.inline-edit-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: var(--light-bg-secondary);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.inline-edit-input,
.inline-edit-textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--light-bg-primary);
  color: var(--text-primary);
  transition: all 0.2s ease;
}

.inline-edit-textarea.code-edit {
  font-family: monospace;
  min-height: 100px;
}

.inline-edit-input:focus,
.inline-edit-textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
}

.inline-edit-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.inline-edit-actions button {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.save-btn {
  background-color: var(--success-color);
  color: white;
}

.cancel-btn {
  background-color: var(--danger-color);
  color: white;
}

.save-btn:hover {
  background-color: var(--success-color-hover);
}

.cancel-btn:hover {
  background-color: var(--danger-color-hover);
}

/* Dark Mode Adjustments */
.darkmode .inline-edit-container {
  background-color: var(--dark-bg-secondary);
}

.darkmode .inline-edit-input,
.darkmode .inline-edit-textarea {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
  border-color: var(--dark-border-color);
}

/* Board Action Dropdown Styles */
.board-action-dropdown {
  background-color: var(--light-bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  padding: 8px 0;
  z-index: 1000;
}

.board-action-dropdown .dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.board-action-dropdown .dropdown-item:hover {
  background-color: var(--light-bg-secondary);
}

.board-action-dropdown .dropdown-item svg {
  color: var(--text-secondary);
  margin-right: 8px;
}

/* Dark Mode Adjustments */
.darkmode .board-action-dropdown {
  background-color: var(--dark-bg-primary);
  border-color: var(--dark-border-color);
}

.darkmode .board-action-dropdown .dropdown-item:hover {
  background-color: var(--dark-bg-secondary);
}

.darkmode .board-action-dropdown .dropdown-item svg {
  color: var(--dark-text-secondary);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.modal-content {
  background-color: var(--light-bg-primary);
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  padding: 24px;
  width: 500px;
  
  max-height: 80%;
  overflow-y: auto;
}

.modal-content h2 {
  margin-bottom: 20px;
  color: var(--text-primary);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}

.boards-list {
  max-height: 400px;
  overflow-y: auto;
}

.board-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.board-item:hover {
  background-color: var(--light-bg-secondary);
}

.board-item span {
  font-weight: 600;
  color: var(--text-primary);
}

.board-item small {
  color: var(--text-secondary);
  font-size: 0.8em;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
}

.modal-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.modal-actions button:hover {
  background-color: var(--primary-color-hover);
}

/* Dark Mode Adjustments */
.darkmode .modal-content {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
}

.darkmode .modal-content h2 {
  border-bottom-color: var(--dark-border-color);
}

.darkmode .board-item {
  border-bottom-color: var(--dark-border-color);
}

.darkmode .board-item:hover {
  background-color: var(--dark-bg-secondary);
}

.darkmode .board-item span {
  color: var(--dark-text-primary);
}

.darkmode .board-item small {
  color: var(--dark-text-secondary);
}

.darkmode .toolbar-button {
  background-color: var(--dark-bg-tertiary);
  color: var(--dark-text-primary);
  border-color: var(--dark-border-primary);
}

.darkmode .toolbar-button:hover {
  background-color: var(--dark-bg-hover);
}
