.video-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .video-modal-container {
    position: relative;
    width: 90%;
    max-width: 1280px;
    aspect-ratio: 16/9;
  }
  
  .video-modal-content {
    position: relative;
    width: 100%;
    height: 100%;
    background: #000;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .video-modal-content .video-js {
    width: 100% !important;
    height: 100% !important;
  }
  
  .modal-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  
  .modal-close-button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  /* Media queries untuk responsivitas */
  @media screen and (max-width: 1024px) {
    .video-modal-container {
      width: 95%;
    }
  }