.tracking-settings {
    display: flex;
    height: 100vh;
  }
  
  .sidebar {
    width: 250px;
    background-color: #f0f0f0;
    padding: 20px;
    overflow-y: auto;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  .tab {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    text-align: left;
    background-color: #e0e0e0;
    border: none;
    cursor: pointer;
  }
  
  .tab.active {
    background-color: #4CAF50;
    color: white;
  }
  
  .dropdown {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .dropdown.open {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
  }
  
  .sub-tab {
    display: block;
    width: 100%;
    padding: 8px 10px 8px 20px;
    margin-bottom: 5px;
    text-align: left;
    background-color: #f8f8f8;
    border: none;
    cursor: pointer;
  }
  
  .sub-tab:hover {
    background-color: #e8e8e8;
  }
  
  .content {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    padding-top:60px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  /* Dark mode styles */

  .shots-tabs {
    display: flex;
    margin-bottom: 20px;
  }

  .shots-tabs button {
    padding: 10px 20px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    margin-right: 10px;
  }

  .shots-tabs button.active {
    background-color: #4CAF50;
    color: white;
  }

  .shot-status-manager {
    padding: 20px;
  }

  .add-status {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  .color-selector {
    position: relative;
    display: flex;
    align-items: center;
  }

  .color-preview {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #ccc;
    cursor: pointer;
  }

  .color-picker-popover {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    margin-top: 8px;
  }

  .color-picker-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .status-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .status-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 8px;
  }

  .status-info {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .status-name {
    font-weight: 500;
  }

  .status-abbr {
    font-size: 0.9em;
    color: #666;
    margin: 0 8px;
    font-family: monospace;
  }

  .progress-type {
    padding: 2px 8px;
    border-radius: 4px;
    margin-left: 8px;
    font-weight: bold;
  }

  .progress-type.positive {
    background-color: #e6ffe6;
    color: #008000;
  }

  .progress-type.negative {
    background-color: #ffe6e6;
    color: #ff0000;
  }

  .status-controls {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .color-preview {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }

  .default-badge {
    background-color: #e0e0e0;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
    color: #666;
  }

  .user-manager {
    margin-top: 20px;
  }

  .user-manager .add-user {
    display: flex;
    margin-bottom: 20px;
  }

  .user-manager .add-user input,
  .user-manager .add-user select,
  .user-manager .add-user button {
    margin-right: 10px;
    padding: 5px;
  }

  .user-manager .user-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .user-manager .user-list button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }

  .default-badge {
    background-color: #e0e0e0;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
    color: #666;
  }

  .status-edit-form {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 4px;
  }

  .status-edit-form input,
  .status-edit-form select {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .status-edit-form button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .status-edit-form button:first-of-type {
    background-color: #4CAF50;
    color: white;
  }

  .status-edit-form button:last-of-type {
    background-color: #f44336;
    color: white;
  }

  /* Dark Mode Styles */
  .dark-mode .status-item {
    background-color: var(--bg-card-dark) !important;
    color: var(--text-primary-dark) !important;
  }

  .dark-mode .status-abbr {
    color: var(--text-muted-dark) !important;
  }

  .dark-mode .progress-type.positive {
    background-color: #2d2d2d !important;
    color: #22c55e !important;
  }

  .dark-mode .progress-type.negative {
    background-color: #2d2d2d !important;
    color: #ef4444 !important;
  }

  .dark-mode .default-badge {
    background-color: #374151 !important;
    color: var(--text-secondary-dark) !important;
  }

  .type-manager {
    margin: 20px;
  }

  .add-type-form {
    margin-bottom: 30px;
  }

  .add-type-form input,
  .add-type-form textarea {
    display: block;
    margin-bottom: 10px;
    padding: 8px;
    width: 300px;
  }

  .add-type-form textarea {
    height: 100px;
  }

  .type-list table {
    width: 100%;
    border-collapse: collapse;
  }

  .type-list th,
  .type-list td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }

  .type-list th {
    background-color: #f5f5f5;
  }

  .type-list button {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }

  .type-list button:hover {
    opacity: 0.8;
  }

  .type-list input,
  .type-list textarea {
    width: 100%;
    padding: 5px;
    margin: 5px 0;
  }
