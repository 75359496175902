.project-analytics {
  padding: 20px;
  background: var(--background-color);
  color: var(--text-color);
}

.analytics-header {
  margin-bottom: 30px;
}

.analytics-header h2 {
  margin-bottom: 20px;
  color: var(--text-color);
}

.tab-navigation {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-navigation button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: var(--card-background);
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-navigation button:hover {
  background: var(--hover-color);
}

.tab-navigation button.active {
  background: #1976d2;
  color: white;
}

.analytics-section {
  background: var(--card-background);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.chart-container {
  margin: 20px 0;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}

.insights-container {
  margin: 24px 0;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.insights-container h4 {
  margin-bottom: 16px;
  color: #333;
  font-weight: 500;
}

.insights-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.insight {
  display: flex;
  align-items: flex-start;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 6px;
  font-size: 14px;
}

.insight.warning {
  background-color: #fff3e0;
  color: #e65100;
}

.insight.success {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.insight-icon {
  margin-right: 8px;
  font-size: 16px;
}

.bottleneck-analysis {
  margin-top: 24px;
}

.bottleneck-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.bottleneck-card {
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bottleneck-card h5 {
  margin: 0 0 8px 0;
  color: #333;
  font-weight: 500;
}

.bottleneck-card p {
  margin: 0 0 16px 0;
  color: #666;
  font-size: 14px;
}

.impact-meter {
  position: relative;
  height: 24px;
  background-color: #f5f5f5;
  border-radius: 12px;
  overflow: hidden;
}

.impact-fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(90deg, #ff9800, #f44336);
  transition: width 0.3s ease;
}

.impact-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
}

.suggestion-card {
  background: var(--card-background);
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.savings-indicator {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background: rgba(76, 175, 80, 0.1);
  border-radius: 5px;
  color: #4caf50;
}

.risk-factor-card {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.risk-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.risk-level {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

.risk-level.high {
  background-color: #ffebee;
  color: #c62828;
}

.risk-level.medium {
  background-color: #fff3e0;
  color: #ef6c00;
}

.risk-level.low {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.strategy-card {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.strategy-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.priority {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

.priority.high {
  background-color: #ffebee;
  color: #c62828;
}

.priority.medium {
  background-color: #fff3e0;
  color: #ef6c00;
}

.priority.low {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  background-color: #e3f2fd;
  color: #1565c0;
  margin-top: 8px;
}

.mitigation-strategies {
  margin-top: 24px;
}

.risk-factors h4,
.mitigation-strategies h4 {
  margin-bottom: 16px;
  color: #333;
  font-weight: 500;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  font-size: 1.2em;
  color: var(--text-color);
}
.project-selector {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.project-selector label {
  font-weight: bold;
}

.project-selector select {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  color: #333;
}

.project-selector.darkmode select {
  background-color: #2d2d2d;
  color: #fff;
  border-color: #555;
}

/* Styling tambahan untuk select agar lebih menarik */
.project-selector select:focus {
  outline: none;
  border-color: #8884d8;
  box-shadow: 0 0 5px rgba(136, 132, 216, 0.5);
}
.filters {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
}

.summary {
  display: flex;
  gap: 2rem;
  margin: 1rem 0;
  padding: 1rem;
  background: #f0f0f0;
  border-radius: 4px;
}

.summary.darkmode {
  background: #333;
  color: #fff;
}

.project-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem 0;
}

.error-message {
  color: red;
  margin: 1rem 0;
}

.notification {
  background: #ffcccc;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 4px;
}

.custom-tooltip {
  background: #fff;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
}

.data-table th, .data-table td {
  border: 1px solid #ddd;
  padding: 0.5rem;
  text-align: left;
}

.data-table th {
  background: #f4f4f4;
}

.data-table.darkmode th {
  background: #444;
  color: #fff;
}

.data-table.darkmode td {
  color: #ddd;
}

.task-lists {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.task-lists ul {
  list-style: none;
  padding: 0;
}

.task-lists h5 {
  margin-bottom: 0.5rem;
}
.department-progress-section {
  margin-top: 20px;
}

.department-progress-section h5 {
  margin-bottom: 10px;
  font-size: 1.1em;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.data-table th, .data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.data-table th {
  background-color: #f2f2f2;
}

.darkmode .data-table th {
  background-color: #333;
}

.darkmode .data-table th, .darkmode .data-table td {
  border-color: #555;
}