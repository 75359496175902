/* Report Manager Container */
.report-manager {
  padding: 24px;
  min-height: 100vh;
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.report-controls {
  display: flex;
  gap: 16px;
}

.report-controls select {
  padding: 8px 16px;
  border-radius: 8px;
  min-width: 200px;
  border: 1px solid #e2e8f0;
  background: white;
  color: #2d3748;
  font-size: 0.95em;
}

.report-tabs {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.report-tabs button {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background: #f7fafc;
  color: #4a5568;
  font-size: 0.95em;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.report-tabs button:hover {
  background: #edf2f7;
}

.report-tabs button.active {
  background: #4299e1;
  color: white;
}

/* Project Overview */
.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 24px;
  padding: 16px;
}

.project-card {
  background: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.project-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.project-header h4 {
  margin: 0;
  font-size: 1.4em;
  font-weight: 600;
  color: #2c3e50;
}

.status {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9em;
  font-weight: 500;
}

.status.in-production { background: #e3f2fd; color: #1976d2; }
.status.pre-production { background: #f3e5f5; color: #7b1fa2; }
.status.completed { background: #e8f5e9; color: #388e3c; }
.status.on-hold { background: #fff3e0; color: #f57c00; }

.project-progress {
  margin: 20px 0;
}

.progress-bar {
  height: 8px;
  background: #edf2f7;
  border-radius: 4px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #4299e1, #667eea);
  border-radius: 4px;
  transition: width 0.3s ease;
}

.progress-label {
  display: block;
  margin-top: 8px;
  font-size: 0.9em;
  color: #4a5568;
  text-align: right;
}

.project-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin: 24px 0;
}

.stat-group {
  background: #f8fafc;
  padding: 16px;
  border-radius: 8px;
}

.stat-group h5 {
  margin: 0 0 12px 0;
  color: #2d3748;
  font-size: 1.1em;
  font-weight: 600;
}

.stat-details {
  display: grid;
  gap: 8px;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stat-item label {
  color: #718096;
  font-size: 0.9em;
}

.stat-item span {
  font-weight: 600;
  font-size: 1em;
}

.stat-item span.completed { color: #38a169; }
.stat-item span.in-progress { color: #3182ce; }
.stat-item span.pending { color: #d69e2e; }

.project-sequences {
  margin-top: 24px;
}

.project-sequences h5 {
  margin: 0 0 12px 0;
  color: #2d3748;
  font-size: 1.1em;
  font-weight: 600;
}

.sequences-list {
  background: #f8fafc;
  border-radius: 8px;
  padding: 12px;
}

.sequence-item {
  
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #e2e8f0;
}

.sequence-item:last-child {
  border-bottom: none;
}

.sequence-name {
  color: #2d3748;
  font-weight: 500;
}

.sequence-shots {
  color: #718096;
  font-size: 0.9em;
}

.more-sequences {
  text-align: center;
  color: #4a5568;
  font-size: 0.9em;
  padding: 8px;
  background: #edf2f7;
  border-radius: 4px;
  margin-top: 8px;
}

.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.loading-spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-state {
  text-align: center;
  color: #e53e3e;
  padding: 20px;
}

/* Dark mode styles */
.darkmode .report-manager {
  background: #1a202c;
  color: #e2e8f0;
}

.darkmode .report-controls select {
  background: #2d3748;
  border-color: #4a5568;
  color: #e2e8f0;
}

.darkmode .report-tabs button {
  background: #2d3748;
  color: #a0aec0;
}

.darkmode .report-tabs button:hover {
  background: #4a5568;
}

.darkmode .report-tabs button.active {
  background: #4299e1;
  color: white;
}

.darkmode .project-card {
  background: #2d3748;
  box-shadow: 0 4px 6px rgba(0,0,0,0.2);
}

.darkmode .project-header h4 {
  color: #e2e8f0;
}

.darkmode .status {
  opacity: 0.9;
}

.darkmode .progress-bar {
  background: #4a5568;
}

.darkmode .progress-fill {
  background: linear-gradient(90deg, #4299e1, #667eea);
}

.darkmode .progress-label {
  color: #a0aec0;
}

.darkmode .stat-group {
  background: #2d3748;
}

.darkmode .stat-group h5 {
  color: #e2e8f0;
}

.darkmode .stat-item label,
.darkmode .stat-item span {
  color: #ffffff !important;
}

.darkmode .sequences-list {
  background: #2d3748;
}

.darkmode .sequence-item {
  border-bottom-color: #4a5568;
}

.darkmode .sequence-name {
  color: #e2e8f0;
}

.darkmode .sequence-shots {
  color: #a0aec0;
}

.darkmode .more-sequences {
  background: #4a5568;
  color: #e2e8f0;
}

.team-performance-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

@media (max-width: 1200px) {
  .team-performance-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .team-performance-grid {
    grid-template-columns: 1fr;
  }
}

.report-tab {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.report-tab.active {
  background: var(--primary-color);
  color: white;
}

.report-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.report-card {
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.report-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-card-title {
  font-size: 1.1rem;
  font-weight: 500;
}

.report-manager {
  padding: 20px;
  min-height: 100vh;
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.report-controls {
  display: flex;
  gap: 1rem;
}

.report-controls select {
  padding: 0.5rem;
  border-radius: 4px;
  min-width: 150px;
}

.report-content {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.chart-section {
  margin-bottom: 2rem;
}

.chart-container {
  height: 300px;
  margin-bottom: 1rem;
}

.stats-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.stat-card {
  background-color: var(--background-secondary);
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.darkmode .stat-card {
  background-color: var(--dark-background-secondary);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.stat-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.stat-card h3 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: var(--text-primary);
  font-size: 1.25rem;
  font-weight: 600;
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 0.75rem;
}

.darkmode .stat-card h3 {
  color: var(--dark-text-primary);
  border-bottom-color: var(--dark-border-color);
}

.stat-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stat-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.stat-label {
  color: var(--text-secondary);
  font-size: 0.95rem;
}

.darkmode .stat-label {
  color: var(--dark-text-secondary);
}

.stat-value {
  font-weight: 600;
  font-size: 1rem;
  color: var(--text-primary);
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
  background: var(--background-tertiary);
}

.darkmode .stat-value {
  color: var(--dark-text-primary);
  background: var(--dark-background-tertiary);
}

.stat-value.completed {
  color: #10B981;
  background: rgba(16, 185, 129, 0.1);
}

.stat-value.in-progress {
  color: #3B82F6;
  background: rgba(59, 130, 246, 0.1);
}

.stat-value.blocked {
  color: #EF4444;
  background: rgba(239, 68, 68, 0.1);
}

.stat-value.trend-increasing {
  color: #10B981;
  background: rgba(16, 185, 129, 0.1);
}

.stat-value.trend-decreasing {
  color: #EF4444;
  background: rgba(239, 68, 68, 0.1);
}

.stat-label {
  font-size: 0.9rem;
  opacity: 0.8;
}

.table-section {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 1rem;
  text-align: left;
}

.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.team-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
}


.member-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.member-name {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.member-role {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.member-stats {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.member-stat {
  text-align: center;
}

.stat-number {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.stat-title {
  font-size: 0.8rem;
}

.project-overview .projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 24px;
}

.project-card {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.project-header h4 {
  margin: 0;
  color: var(--text-primary);
}

.project-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 16px;
}

.project-stats .stat-item {
  text-align: center;
}

.project-stats .stat-item label {
  display: block;
  font-size: 0.85em;
  color: var(--text-secondary);
  margin-bottom: 4px;
}

.project-stats .stat-item span {
  font-size: 1.2em;
  font-weight: 500;
  color: var(--text-primary);
}

.sequence-stats {
  display: grid;
  gap: 24px;
}

.critical-shots-list,
.critical-assets-list {
  display: grid;
  gap: 12px;
  margin-top: 16px;
}

.critical-item {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  gap: 16px;
  padding: 12px;
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.critical-item .item-id {
  font-family: monospace;
  color: var(--text-secondary);
}

.critical-item .item-status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.85em;
  font-weight: 500;
}

.critical-item .item-due {
  color: var(--text-secondary);
  font-size: 0.9em;
}

.asset-tracking {
  display: grid;
  gap: 24px;
}

.asset-progress {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
}

.progress-item {
  margin-bottom: 16px;
}

.progress-item:last-child {
  margin-bottom: 0;
}

.progress-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.progress-bar {
  height: 8px;
  background: var(--border-color);
  border-radius: 4px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: var(--primary-color);
  border-radius: 4px;
  transition: width 0.3s ease;
}

.stage-name {
  color: var(--text-primary);
  font-weight: 500;
}

.progress-numbers {
  color: var(--text-secondary);
  font-size: 0.9em;
}

.asset-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  gap: 24px;
  width: 100%;
}

@media (max-width: 1400px) {
  .asset-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 900px) {
  .asset-cards {
    grid-template-columns: 1fr;
  }
}

.asset-card {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  min-width: 0; /* Prevents overflow in grid */
}

.asset-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Ensure content doesn't overflow */
.asset-card > * {
  min-width: 0;
  width: 100%;
}

.asset-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.asset-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.asset-title h5 {
  margin: 0;
  color: var(--text-primary);
  font-size: 1.1em;
}

.priority {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  font-weight: 500;
}

.priority.high {
  background: var(--danger-color);
  color: white;
}

.priority.medium {
  background: var(--warning-color);
  color: white;
}

.priority.low {
  background: var(--info-color);
  color: white;
}

.asset-meta {
  display: flex;
  align-items: center;
  gap: 12px;
}

.asset-id {
  font-family: monospace;
  color: var(--text-secondary);
  font-size: 0.9em;
}

.asset-stages {
  margin: 20px 0;
}

.stage-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid var(--border-color);
}

.stage-item:last-child {
  border-bottom: none;
}

.stage-status {
  display: flex;
  align-items: center;
  gap: 8px;
}

.status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.completion-date,
.progress-percent {
  font-size: 0.9em;
  color: var(--text-secondary);
}

.asset-assignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 12px;
  background: var(--card-bg-secondary);
  border-radius: 6px;
}

.assigned-user {
  display: flex;
  align-items: center;
  gap: 12px;
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-name {
  color: var(--text-primary);
  font-weight: 500;
}

.user-role {
  font-size: 0.85em;
  color: var(--text-secondary);
}

.due-date {
  text-align: right;
}

.due-date .label {
  display: block;
  font-size: 0.85em;
  color: var(--text-secondary);
}

.due-date .date {
  color: var(--text-primary);
  font-weight: 500;
}

.asset-activities {
  margin-top: 20px;
}

.activity-list {
  margin-top: 12px;
}

.activity-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 8px 0;
  border-bottom: 1px solid var(--border-color);
}

.activity-item:last-child {
  border-bottom: none;
}

.activity-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: var(--border-color);
  flex-shrink: 0;
}

.activity-content {
  flex-grow: 1;
}

.activity-description {
  display: block;
  color: var(--text-primary);
  margin-bottom: 4px;
}

.activity-date {
  font-size: 0.85em;
  color: var(--text-secondary);
}

.asset-notes {
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid var(--border-color);
}

.asset-notes h6 {
  margin: 0 0 8px 0;
  color: var(--text-primary);
}

.asset-notes p {
  margin: 0;
  color: var(--text-secondary);
  font-size: 0.9em;
  line-height: 1.5;
}

/* Activity type specific styles */
.activity-item.update .activity-icon {
  background: var(--primary-color);
}

.activity-item.feedback .activity-icon {
  background: var(--warning-color);
}

.activity-item.milestone .activity-icon {
  background: var(--success-color);
}

.activity-item.review .activity-icon {
  background: var(--info-color);
}

.activity-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background: var(--item-bg);
  border-radius: 6px;
  border: 1px solid var(--border-color);
}

/* Status Colors */
.status.in-production { background: var(--success-color); }
.status.pre-production { background: var(--warning-color); }
.status.review { background: var(--info-color); }
.status.final { background: var(--success-color); }
.status.in-progress { background: var(--warning-color); }

/* Project Overview Styles */
.project-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 2rem;
  border-top: 1px solid var(--border-color);
  padding-top: 2rem;
}

.project-card {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.project-metrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 15px;
}

.metric {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background: white;
  border-radius: 4px;
}

/* Progress Bars */
.progress-bar {
  width: 100%;
  height: 8px;
  background: #e9ecef;
  border-radius: 4px;
  margin: 10px 0;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: #007bff;
  border-radius: 4px;
  transition: width 0.3s ease;
}

/* Analytics Grid */
.analytics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 30px;
  margin-top: 2rem;
  border-top: 1px solid var(--border-color);
  padding-top: 2rem;
}

.metrics-section {
  margin: 24px 0;
}

.chart-container {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  height: 400px;
}

.chart-container h4 {
  margin: 0 0 16px 0;
  color: var(--text-primary);
  font-size: 1.1em;
}

.chart-container canvas {
  width: 100% !important;
  height: 300px !important;
}

.charts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
  padding: 1rem;
  width: 100%;
  margin-bottom: 2rem;
}

.chart-wrapper {
  background: var(--background-secondary);
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 400px;
  display: flex;
  flex-direction: column;
}

.chart-wrapper h4 {
  margin: 0 0 1rem 0;
  color: var(--text-primary);
  font-size: 1.1rem;
  text-align: center;
}

/* Dark mode support */

.report-section {
  margin-bottom: 2rem;
}

.team-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 2rem;
  border-top: 1px solid var(--border-color);
  padding-top: 2rem;
}

.metric-highlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.highlight-card {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
}

.highlight-card span:first-child {
  display: block;
  color: #6c757d;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.highlight-card span:last-child {
  font-size: 1.5em;
  font-weight: bold;
  color: #007bff;
}

/* Timeline */
.timeline-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.milestone-item,
.deadline-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 12px;
  border-bottom: 1px solid #eee;
  align-items: center;
}

.milestone-item.completed {
  background: #e8f5e9;
}

.milestone-item.in-progress {
  background: #fff3e0;
}

.milestone-item .status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  text-align: center;
}

.milestone-item.completed .status {
  background: #4caf50;
  color: white;
}

.milestone-item.in-progress .status {
  background: #ff9800;
  color: white;
}

.milestone-item.pending .status {
  background: #9e9e9e;
  color: white;
}

/* Hashtag Analytics Styles */
.hashtag-analytics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.department-hashtags-item {
  background: var(--background-secondary);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.department-hashtags-item h5 {
  margin: 0 0 1rem 0;
  color: var(--text-primary);
  font-size: 1rem;
}

.tag-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tag-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background: var(--background-primary);
  border-radius: 4px;
}

.timeline-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.timeline-item {
  background: var(--background-secondary);
  border-radius: 8px;
  padding: 1rem;
}

.timeline-item .date {
  display: block;
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
}

.timeline-item .hashtags {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.timeline-item .tag {
  background: var(--background-primary);
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.hashtag-overview {
  margin-top: 2rem;
  border-top: 1px solid var(--border-color);
  padding-top: 2rem;
}

.hashtag-categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.category-section {
  background: var(--background-secondary);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category-section h4 {
  margin: 0 0 1rem 0;
  color: var(--text-primary);
  font-size: 1.1rem;
}

.hashtag-list {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.hashtag-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  background: var(--background-primary);
  border-radius: 6px;
  transition: background-color 0.2s;
}

.hashtag-item:hover {
  background: var(--background-hover);
}

.hashtag-info {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.hashtag-name {
  font-weight: 500;
  color: var(--text-primary);
}

.hashtag-count {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.hashtag-trend {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.trend-indicator {
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
}

.trend-indicator.up {
  color: #22c55e;
  background: rgba(34, 197, 94, 0.1);
}

.trend-indicator.down {
  color: #ef4444;
  background: rgba(239, 68, 68, 0.1);
}

.trend-indicator.stable {
  color: #f59e0b;
  background: rgba(245, 158, 11, 0.1);
}

/* Dark mode support */

/* New Hashtag Sections Styles */
.hashtag-overview {
  margin-top: 2rem;
  border-top: 1px solid var(--border-color);
  padding-top: 2rem;
}

.hashtag-categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.category-section {
  background: var(--background-secondary);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category-section h4 {
  margin: 0 0 1rem 0;
  color: var(--text-primary);
  font-size: 1.1rem;
}

.hashtag-list {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.hashtag-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  background: var(--background-primary);
  border-radius: 6px;
  transition: background-color 0.2s;
}

.hashtag-item:hover {
  background: var(--background-hover);
}

.hashtag-info {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.hashtag-name {
  font-weight: 500;
  color: var(--text-primary);
}

.hashtag-count {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.hashtag-trend {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.trend-indicator {
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
}

.trend-indicator.up {
  color: #22c55e;
  background: rgba(34, 197, 94, 0.1);
}

.trend-indicator.down {
  color: #ef4444;
  background: rgba(239, 68, 68, 0.1);
}

.trend-indicator.stable {
  color: #f59e0b;
  background: rgba(245, 158, 11, 0.1);
}

/* Dark mode support */

/* User Metrics Styles */
.user-metrics {
  margin: 24px 0;
}

.user-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
  margin-top: 16px;
}

.user-card {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
  transition: all 0.2s ease;
}

.user-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.user-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--border-color);
}

.user-info h4 {
  margin: 0 0 4px 0;
  color: var(--text-primary);
  font-size: 1.1em;
}

.user-role {
  display: inline-block;
  font-size: 0.9em;
  color: var(--text-secondary);
  margin-right: 12px;
}

.user-department {
  display: inline-block;
  font-size: 0.9em;
  color: var(--text-accent);
  padding: 2px 8px;
  background: var(--accent-bg);
  border-radius: 12px;
}

.user-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: var(--stats-bg);
  border-radius: 6px;
}

.stat-item label {
  font-size: 0.8em;
  color: var(--text-secondary);
  margin-bottom: 4px;
}

.stat-item span {
  font-size: 1.1em;
  font-weight: 500;
  color: var(--text-primary);
}

/* Current Assignments */
.user-current-work {
  margin-bottom: 16px;
}

.user-current-work h5,
.user-activity h5 {
  margin: 0 0 12px 0;
  color: var(--text-primary);
  font-size: 1em;
}

.assignments-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.assignment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: var(--item-bg);
  border-radius: 6px;
}

.assignment-name {
  font-size: 0.9em;
  color: var(--text-primary);
}

.assignment-details {
  display: flex;
  align-items: center;
  gap: 8px;
}

.assignment-details .status {
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  font-weight: 500;
}

.assignment-details .deadline {
  font-size: 0.8em;
  color: var(--text-secondary);
}

/* Activity Timeline */
.activity-timeline {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.activity-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background: var(--item-bg);
  border-radius: 6px;
  border: 1px solid var(--border-color);
}

.activity-type {
  font-size: 0.8em;
  padding: 2px 8px;
  border-radius: 4px;
  background: var(--activity-bg);
  color: var(--text-on-color);
}

.activity-item-name {
  font-size: 0.9em;
  color: var(--text-primary);
  flex: 1;
}

.activity-date {
  font-size: 0.8em;
  color: var(--text-secondary);
}

/* Activity Type Colors */
.activity-type.task_completed { background: var(--color-final); }
.activity-type.task_started { background: var(--color-progress); }
.activity-type.feedback_given { background: var(--color-review); }
.activity-type.review_completed { background: var(--color-final-review); }
.activity-type.tool_deployed { background: var(--color-rendering); }
.activity-type.bug_fixed { background: var(--color-priority-high); }
.activity-type.code_review { background: var(--color-priority-medium); }

/* Activity Timeline Dark Mode */

/* Team Metrics Dark Mode */

/* Dark Mode Styles */

.detailed-list {
  margin-top: 10px;
  border-top: 1px solid var(--border-color);
  padding-top: 10px;
}

.detail-item {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 8px;
  transition: all 0.2s ease;
}

.detail-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.detail-id {
  font-family: monospace;
  font-size: 0.9em;
  color: var(--text-secondary);
}

.detail-status,
.detail-priority {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  font-weight: 500;
}

.status-progress {
  background: var(--color-progress);
  color: var(--text-on-color);
}

.status-review {
  background: var(--color-review);
  color: var(--text-on-color);
}

.status-final {
  background: var(--color-final);
  color: var(--text-on-color);
}

.status-queue {
  background: var(--color-queue);
  color: var(--text-on-color);
}

.status-rendering {
  background: var(--color-rendering);
  color: var(--text-on-color);
}

.status-final-review {
  background: var(--color-final-review);
  color: var(--text-on-color);
}

.status-pending {
  background: var(--color-pending);
  color: var(--text-on-color);
}

.priority-high {
  background: var(--color-priority-high);
  color: var(--text-on-color);
}

.priority-medium {
  background: var(--color-priority-medium);
  color: var(--text-on-color);
}

.priority-low {
  background: var(--color-priority-low);
  color: var(--text-on-color);
}

.detail-content h5 {
  margin: 0 0 8px 0;
  color: var(--text-primary);
  font-size: 1em;
}

.detail-info {
  display: flex;
  gap: 16px;
  color: var(--text-secondary);
  font-size: 0.9em;
}

.detail-info span {
  display: flex;
  align-items: center;
}

:root {
  --color-progress: #3498db;
  --color-review: #f39c12;
  --color-final: #27ae60;
  --color-queue: #95a5a6;
  --color-rendering: #8e44ad;
  --color-final-review: #2ecc71;
  --color-pending: #e74c3c;
  --color-priority-high: #e74c3c;
  --color-priority-medium: #f39c12;
  --color-priority-low: #3498db;
  --text-on-color: #ffffff;
  --stats-bg: rgba(0, 0, 0, 0.03);
  --item-bg: rgba(0, 0, 0, 0.02);
  --activity-bg: var(--color-progress);
}

.team-metrics {
  display: grid;
  gap: 24px;
}

.team-metrics .chart-container {
  width: 100%;
}

.team-metrics .chart-container:last-child {
  height: 350px;
}

.team-metrics .chart-container:last-child canvas {
  height: 250px !important;
}

/* Weekly Analytics Styles */
.weekly-analytics {
  display: grid;
  gap: 24px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.weekly-analytics > div {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.weekly-analytics .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.weekly-analytics .date-range {
  padding: 8px 16px;
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 0.9em;
  color: var(--text-secondary);
}

/* Overview Cards */
.analytics-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.overview-card {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
}

.overview-card h4 {
  margin: 0 0 16px 0;
  color: var(--text-primary);
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 16px;
}

.metric-item {
  text-align: center;
}

.metric-item .label {
  display: block;
  font-size: 0.85em;
  color: var(--text-secondary);
  margin-bottom: 4px;
}

.metric-item .value {
  font-size: 1.5em;
  font-weight: 500;
  color: var(--text-primary);
}

.trend {
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  font-size: 0.9em;
}

.trend-up {
  color: var(--success-color);
  background: rgba(var(--success-color-rgb), 0.1);
}

.trend-down {
  color: var(--danger-color);
  background: rgba(var(--danger-color-rgb), 0.1);
}

/* Productivity Score */
.productivity-score {
  text-align: center;
}

.score-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 8px solid var(--primary-color);
  margin: 0 auto 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.score-circle .score {
  font-size: 2em;
  font-weight: 600;
  color: var(--text-primary);
}

.score-circle .label {
  font-size: 0.8em;
  color: var(--text-secondary);
}

/* Time Tracking */
.time-metrics {
  display: grid;
  gap: 16px;
}

.utilization-bar {
  margin-top: 8px;
}

.utilization-bar .label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

/* Charts Section */
.analytics-charts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 24px;
}

.analytics-charts .chart-container {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
}

/* Top Performers */
.performers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 24px;
}

.performer-card {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
}

.performer-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.performer-info h5 {
  margin: 0;
  color: var(--text-primary);
}

.performer-info .role {
  font-size: 0.9em;
  color: var(--text-secondary);
}

.performer-metrics {
  display: flex;
  gap: 16px;
}

.performer-metrics .metric {
  text-align: center;
}

.performer-metrics .label {
  display: block;
  font-size: 0.8em;
  color: var(--text-secondary);
}

.performer-metrics .value {
  font-size: 1.1em;
  font-weight: 500;
  color: var(--text-primary);
}

.performer-highlights {
  border-top: 1px solid var(--border-color);
  padding-top: 16px;
}

.highlight-item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  font-size: 0.9em;
  color: var(--text-secondary);
}

.highlight-icon {
  color: var(--success-color);
}

/* Key Milestones */
.milestones-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.milestone-card {
  background: var(--card-bg);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
}

.milestone-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.milestone-header h5 {
  margin: 0;
  color: var(--text-primary);
}

.milestone-progress {
  margin: 12px 0;
}

.milestone-due {
  font-size: 0.9em;
  color: var(--text-secondary);
}

.status.on-track {
  background: var(--success-color);
  color: white;
}

.status.at-risk {
  background: var(--warning-color);
  color: white;
}

.status.delayed {
  background: var(--danger-color);
  color: white;
}

.weekly-tasks {
  background: var(--card-bg);
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: var(--card-shadow);
}

.tasks-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 15px;
}

.task-section {
  background: var(--secondary-bg);
  border-radius: 6px;
  padding: 15px;
}

.task-section h5 {
  margin: 0 0 15px 0;
  color: var(--text-primary);
}

.task-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.task-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 0.5fr;
  align-items: center;
  background: var(--card-bg);
  padding: 12px;
  border-radius: 6px;
  gap: 10px;
}

.task-name {
  font-weight: 500;

}

.task-status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  text-align: center;
}

.task-status.in-progress {
  background: var(--status-in-progress);
  color: var(--text-light);
}

.task-status.review {
  background: var(--status-review);
  color: var(--text-light);
}

.task-status.not-started {
  background: var(--status-not-started);
  color: var(--text-light);
}

.task-due {
  font-size: 0.9em;
  color: var(--text-secondary);
}

.task-progress {
  font-weight: 500;
  text-align: right;
  color: var(--accent-color);
}

.gantt-section h4 {
  margin-bottom: 1rem;
  color: #333;
  font-size: 1.1rem;
}

.gantt-chart-container {
 
  height: 500px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.weekly-analytics .gantt-section {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.weekly-analytics .gantt-chart-container {
  max-width: 100%;
  overflow-x: auto;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.date-range {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.week-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.week-selector label {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.week-selector select {
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--background-primary);
  color: var(--text-primary);
  font-size: 0.9rem;
  cursor: pointer;
}

.week-selector select:hover {
  border-color: var(--border-hover);
}

.week-selector select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.1);
}

/* Dark mode support */

.activity-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  background: var(--background-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 8px;
  transition: all 0.2s ease;
}

.activity-item:hover {
  background: var(--background-hover);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.activity-type {
  font-weight: 500;
  margin-right: 0.5rem;
}

.activity-item-name {
  flex: 1;
  font-size: 0.9rem;
  color: var(--text-primary);
}

.activity-date {
  font-size: 0.8rem;
  color: var(--text-secondary);
}

/* Activity type variations */
.activity-type.task_completed {
  background: var(--success-light);
  color: var(--success-dark);
}

.activity-type.task_started {
  background: var(--info-light);
  color: var(--info-dark);
}

.activity-type.feedback_given {
  background: var(--warning-light);
  color: var(--warning-dark);
}

.activity-type.review_completed {
  background: var(--success-light);
  color: var(--success-dark);
}

.activity-type.tool_deployed {
  background: var(--primary-light);
  color: var(--primary-dark);
}

.activity-type.bug_fixed {
  background: var(--danger-light);
  color: var(--danger-dark);
}

.activity-type.code_review {
  background: var(--info-light);
  color: var(--info-dark);
}

/* Dark mode support */

/* Dark mode activity type variations */

/* Team Member Performance Styles */
.team-member-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}


.member-header {
  border-bottom: 1px solid #eee;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.member-header h4 {
  margin: 0 0 0.5rem 0;
  color: #d0cfcf;
  font-size: 1.2rem;
}

.member-header .department,
.member-header .role {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  background: #f0f0f0;
  color: #666;
}

.performance-metrics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.metric {
  text-align: center;
}

.metric label {
  display: block;
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 0.25rem;
}

.metric span {
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
}

.current-tasks,
.recent-activity {
  margin-top: 1.5rem;
}

.current-tasks h5,
.recent-activity h5 {
  margin: 0 0 0.75rem 0;
  color: #333;
  font-size: 1rem;
}

.current-tasks ul,
.recent-activity ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.current-tasks li,
.recent-activity li {
  padding: 0.5rem;
  border-radius: 4px;
  background: #717171;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-name {
  flex: 1;
  margin-right: 0.5rem;
}

.task-status {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.task-status.in-progress {
  background: #fff3cd;
  color: #856404;
}

.task-status.pending {
  background: #cce5ff;
  color: #004085;
}

.task-status.completed {
  background: #d4edda;
  color: #155724;
}

.task-deadline {
  font-size: 0.8rem;
  color: #666;
  margin-left: 0.5rem;
}

.activity-type {
  font-weight: 500;
  margin-right: 0.5rem;
}

.activity-item {
  flex: 1;
  color: #666;
}

.activity-time {
  font-size: 0.8rem;
  color: #999;
}

/* Loading and Error States */
.loading,
.error {
  text-align: center;
  padding: 2rem;
  background: #f8f8f8;
  border-radius: 8px;
  margin: 1rem;
}

.error {
  color: #721c24;
  background: #f8d7da;
  border: 1px solid #f5c6cb;
}

/* Project Cards */
.project-card {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 20px;
}

.project-header h4 {
  margin: 0;
  color: #333333;
}

.project-stats .stat-item label {
  color: #666666;
}

.project-stats .stat-item span {
  color: #333333;
}

/* Dark Mode for Project Cards */

/* Progress Bars */
.progress-bar {
  background: #e9ecef;
}

/* Tables */
.report-table {
  background: #ffffff;
  border: 1px solid #dddddd;
}

.report-table th {
  background: #f8f9fa;
  color: #333333;
}

.report-table td {
  color: #333333;
  border-color: #dddddd;
}

/* Team Member Grid Layout */
.team-performance-grid {

  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1rem;
}



/* Responsive adjustments */
@media (max-width: 1200px) {
  .team-performance-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .team-performance-grid {
    grid-template-columns: 1fr;
  }
}

/* Team Performance Filters */
.team-performance-filters {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  
  border-radius: 8px;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
}

.filter-group {
  display: flex;
  flex-direction: column;
  min-width: 200px;
}

.filter-group label {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: #666666;
}

.filter-group select {
  padding: 0.5rem;
  border: 1px solid #dddddd;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333333;
  font-size: 0.9rem;
  cursor: pointer;
}

.filter-group select:hover {
  border-color: #999999;
}

.filter-group select:focus {
  outline: none;
  border-color: #f39521;
  box-shadow: 0 0 0 2px rgba(243, 149, 33, 0.1);
}

/* Task Completion Range Slider */
.completion-range {
  width: 100%;
  margin-top: 0.5rem;
}

.completion-range input[type="range"] {
  width: 100%;
  height: 4px;
  background: #dddddd;
  border-radius: 2px;
  outline: none;
  -webkit-appearance: none;
}

.completion-range input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background: #f39521;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.2s;
}

.completion-range input[type="range"]::-webkit-slider-thumb:hover {
  background: #e88a10;
}

.completion-value {
  text-align: center;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #666666;
}

.report-container {
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.report-container.darkmode {
  background-color: var(--dark-bg-secondary);
  color: var(--dark-text-primary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.report-header {
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.report-header.darkmode {
  border-color: var(--dark-border-primary);
}

.header-controls {
  display: flex;
  gap: 16px;
  align-items: center;
}

.header-controls.darkmode select,
.header-controls.darkmode input {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

.report-content {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.report-content.darkmode {
  background-color: var(--dark-bg-secondary);
}

.chart-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  min-height: 300px;
}

.chart-container.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.stats-container {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.stats-container.darkmode {
  background-color: var(--dark-bg-secondary);
}

.stat-card {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.stat-card.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.stat-card h3 {
  margin: 0 0 8px 0;
  font-size: 0.9rem;
  color: #666666;
}

.stat-card.darkmode h3 {
  color: var(--dark-text-secondary);
}

.stat-card .value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333333;
}

.stat-card.darkmode .value {
  color: var(--dark-text-primary);
}

.table-container {
  overflow-x: auto;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.table-container.darkmode {
  background-color: var(--dark-bg-tertiary);
  border-color: var(--dark-border-primary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

table {
  width: 100%;
  border-collapse: collapse;
}

.darkmode table {
  color: var(--dark-text-primary);
}

th {
  background-color: #f8f9fa;
  padding: 12px 16px;
  text-align: left;
  font-weight: 600;
  color: #333333;
  border-bottom: 1px solid #e0e0e0;
}

.darkmode th {
  background-color: var(--dark-bg-tertiary);
  color: var(--dark-text-secondary);
  border-color: var(--dark-border-primary);
}

td {
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
}

.darkmode td {
  border-color: var(--dark-border-primary);
  color: var(--dark-text-primary);
}

/* Hashtag Report Styles */

/* Hashtag Tasks Table */
.hashtag-tasks {
  margin-top: 2rem;
}

.hashtag-tasks h3 {
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.table-responsive {
  overflow-x: auto;
  background: var(--card-bg);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dark .table-responsive {
  background: var(--dark-card-bg);
}

.hashtag-tasks .report-table {
  width: 100%;
  border-collapse: collapse;
}

.hashtag-tasks .report-table th,
.hashtag-tasks .report-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

.dark .hashtag-tasks .report-table th,
.dark .hashtag-tasks .report-table td {
  border-color: var(--dark-border-color);
  color: var(--dark-text);
}

.hashtag-tasks .report-table th {
  background: var(--bg-secondary);
  font-weight: 600;
  color: var(--text-primary);
}

.dark .hashtag-tasks .report-table th {
  background: var(--dark-bg-secondary);
  color: var(--dark-text);
}

.hashtag-tasks .status-badge {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
}

.hashtag-tasks .status-badge.completed {
  background: var(--success-bg);
  color: var(--success-color);
}

.hashtag-tasks .status-badge.in-progress {
  background: var(--warning-bg);
  color: var(--warning-color);
}

.hashtag-tasks .status-badge.not-started {
  background: var(--danger-bg);
  color: var(--danger-color);
}

.hashtag-tasks .progress-bar {
  width: 100%;
  height: 8px;
  background: var(--bg-secondary);
  border-radius: 4px;
  position: relative;
}

.dark .hashtag-tasks .progress-bar {
  background: var(--dark-bg-secondary);
}

.hashtag-tasks .progress-fill {
  height: 100%;
  background: var(--primary-color);
  border-radius: 4px;
  transition: width 0.3s ease;
}

.hashtag-tasks .progress-text {
  position: absolute;
  right: 0;
  top: -20px;
  font-size: 0.75rem;
  color: var(--text-secondary);
}
.hashtag-report {
  padding: 1.5rem;
}

.hashtag-report.dark {
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

.hashtag-report .report-header {
  margin-bottom: 2rem;
}

.hashtag-report .report-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.hashtag-report .summary-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.hashtag-report .stat-card {
  background: var(--card-bg);
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hashtag-report.dark .stat-card {
  background: var(--dark-card-bg);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.hashtag-report .stat-card h3 {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
}

.hashtag-report .stat-card p {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-color);
}

.hashtag-report .chart-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.hashtag-report .chart-section {
  background: var(--card-bg);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hashtag-report.dark .chart-section {
  background: var(--dark-card-bg);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.hashtag-report .chart-section h3 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.hashtag-report .chart-wrapper {
  height: 300px;
  position: relative;
}

.hashtag-report .hashtag-list {
  background: var(--card-bg);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hashtag-report.dark .hashtag-list {
  background: var(--dark-card-bg);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.hashtag-report .hashtag-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.hashtag-report .hashtag-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--bg-secondary);
  padding: 0.75rem 1rem;
  border-radius: 6px;
  border-left: 4px solid var(--primary-color);
}

.hashtag-report.dark .hashtag-card {
  background: var(--dark-bg-secondary);
}

.hashtag-report .hashtag-name {
  font-weight: 500;
  color: var(--text-primary);
}

.hashtag-report .hashtag-count {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.hashtag-report .trend-indicator {
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.hashtag-report .trend-indicator.up {
  color: var(--success-color);
  background: var(--success-bg);
}

.hashtag-report .trend-indicator.down {
  color: var(--danger-color);
  background: var(--danger-bg);
}

tr:hover {
  background-color: #f8f9fa;
}

.darkmode tr:hover {
  background-color: var(--dark-bg-hover);
}

/* Chart specific styles */
.chart-legend {
  margin-top: 16px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.chart-legend.darkmode {
  color: var(--dark-text-secondary);
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
  color: #666666;
}

.legend-item.darkmode {
  color: var(--dark-text-secondary);
}

.legend-color {
  width: 12px;
  height: 12px;
  border-radius: 2px;
}

/* Status badges */
.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 500;
}

.status-badge.completed {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status-badge.in-progress {
  background-color: #e3f2fd;
  color: #1565c0;
}

.status-badge.pending {
  background-color: #fff3e0;
  color: #f57c00;
}

.status-badge.delayed {
  background-color: #ffebee;
  color: #c62828;
}

/* Dark mode status badges */
.darkmode .status-badge.completed {
  background-color: rgba(46, 125, 50, 0.2);
  color: #81c784;
}

.darkmode .status-badge.in-progress {
  background-color: rgba(21, 101, 192, 0.2);
  color: #64b5f6;
}

.darkmode .status-badge.pending {
  background-color: rgba(245, 124, 0, 0.2);
  color: #ffb74d;
}

.darkmode .status-badge.delayed {
  background-color: rgba(198, 40, 40, 0.2);
  color: #e57373;
}

/* Loading states */
.loading-spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #f3a321;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

.darkmode .loading-spinner {
  border-color: var(--dark-border-primary);
  border-top-color: var(--dark-accent);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Empty states */
.empty-state {
  text-align: center;
  padding: 48px 24px;
  color: #666666;
}

.darkmode .empty-state {
  color: var(--dark-text-secondary);
}

.empty-state svg {
  font-size: 48px;
  margin-bottom: 16px;
  color: #999999;
}

.darkmode .empty-state svg {
  color: var(--dark-text-secondary);
}

/* Sequence Shot Report Styles */
.sequence-shot-report {
  padding: 24px;
  background-color: var(--background-color);
  min-height: 100vh;
}

.sequence-shot-report .report-header {
  margin-bottom: 32px;
}

.sequence-shot-report .report-header h2 {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 24px;
}

.sequence-shot-report .summary-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  margin-top: 24px;
}

.sequence-shot-report .stat-card {
  background-color: var(--card-bg);
  padding: 20px;
  border-radius: 12px;
  border: 1px solid var(--border-color);
  transition: transform 0.2s;
}

.sequence-shot-report .stat-card:hover {
  transform: translateY(-2px);
}

.sequence-shot-report .stat-card h3 {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-secondary);
  margin-bottom: 12px;
}

.sequence-shot-report .stat-card p {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color);
}

.sequence-shot-report .chart-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 32px;
  margin-bottom: 32px;
}

.sequence-shot-report .chart-section {
  background-color: var(--card-bg);
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--border-color);
}

.sequence-shot-report .chart-section h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 20px;
}

.sequence-shot-report .chart-wrapper {
  height: 320px;
  position: relative;
  margin-top: 16px;
}

/* Dark mode adjustments */
.sequence-shot-report.dark .stat-card,
.sequence-shot-report.dark .chart-section {
  background-color: var(--dark-bg-primary);
  border-color: var(--dark-border-primary);
}

.sequence-shot-report.dark .stat-card h3,
.sequence-shot-report.dark .chart-section h3 {
  color: var(--dark-text-primary);
}

.sequence-shot-report.dark .stat-card p {
  color: var(--dark-text-primary);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sequence-shot-report .chart-container {
    grid-template-columns: 1fr;
  }
  
  .sequence-shot-report .summary-stats {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  
  .sequence-shot-report .stat-card {
    padding: 16px;
  }
  
  .sequence-shot-report .chart-section {
    padding: 20px;
  }
  
  .sequence-shot-report .chart-wrapper {
    height: 280px;
  }
}
