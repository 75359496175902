.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 0 24px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  z-index: 1000;
  transition: all 0.3s ease;
}

.top-bar-left {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 32px;
}

.top-bar-right {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 16px;
}

.logo {
  font-size: 1.4rem;
  font-weight: 600;
  color: #ef970b;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
}

.logo:hover {
  color: #f3a321;
}

nav {
  height: 100%;
}

nav ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  gap: 4px;
}

nav ul li {
  height: 100%;
  display: flex;
  align-items: center;
}

nav ul li a {
  color: #666666;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
}

nav ul li a:hover {
  color: #f3a321;
  background-color: #fff3e0;
}

nav ul li a.active {
  color: #f3a321;
  background-color: #fff3e0;
}

.search-input {
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 0.95rem;
  width: 240px;
  transition: all 0.2s ease;
}

.search-input:focus {
  border-color: #f3a321;
  box-shadow: 0 0 0 2px rgba(243, 163, 33, 0.1);
  outline: none;
}

.user-menu {
  background-color: transparent;
  color: #666666;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.user-menu:hover {
  color: #f3a321;
  background-color: #fff3e0;
}

.user-menu-container {
  position: relative;
}

.user-dropdown {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background-color: #ffffff;
  min-width: 200px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.user-dropdown button {
  color: #666666;
  padding: 12px 16px;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.user-dropdown button:hover {
  background-color: #f8f9fa;
  color: #f3a321;
}

.user-dropdown button.tracking-settings {
  color: #f3a321;
}

.mode-toggle {
  background: none;
  border: none;
  color: #666666;
  cursor: pointer;
  padding: 8px;
 
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.mode-toggle:hover {
  background-color: #f8f9fa;
  color: #f3a321;
}

.task-menu-container {
  position: relative;
}

.nav-button {
  color: #666666;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
  background: none;
  border: none;
  cursor: pointer;
}

.nav-button:hover {
  color: #f3a321;
  background-color: #fff3e0;
}

.task-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  min-width: 180px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1000;
  margin-top: 4px;
}

.task-dropdown-menu li {
  width: 100%;
  height: auto;
}

.task-dropdown-menu a {
  padding: 12px 16px;
  width: 100%;
  display: block;
  color: #666666;
  text-decoration: none;
  transition: all 0.2s ease;
}

.task-dropdown-menu a:hover {
  background-color: #f8f9fa;
  color: #f3a321;
}

.task-dropdown-menu a.active {
  color: #f3a321;
  background-color: #fff3e0;
}

/* Dark mode styles */
.darkmode .topbar {
  background-color: #242424;
  border-color: #333333;
}

.darkmode .logo {
  color: #f9b50a;
}
.logo:hover {
  color: #ffdfac;
}
.darkmode .logo:hover {
  color: #ffdfac;
}

.darkmode nav ul li a {
  color: #b0b0b0;
}

.darkmode nav ul li a:hover,
.darkmode nav ul li a.active {
  color: #f3a321;
  background-color: #333333;
}

.darkmode .search-input {
  background-color: #2d2d2d;
  border-color: #333333;
  color: #e0e0e0;
}

.darkmode .search-input:focus {
  border-color: #f3a321;
}

.darkmode .user-menu {
  color: #b0b0b0;
}

.darkmode .user-menu:hover {
  color: #f3a321;
  background-color: #333333;
}

.darkmode .user-dropdown {
  background-color: #242424;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.darkmode .user-dropdown button {
  color: #b0b0b0;
}

.darkmode .user-dropdown button:hover {
  background-color: #333333;
  color: #f3a321;
}

.darkmode .mode-toggle {
  color: #b0b0b0;
}

.darkmode .mode-toggle:hover {
  background-color: #333333;
  color: #f3a321;
}

.darkmode .nav-button {
  color: #e0e0e0;
}

.darkmode .nav-button:hover {
  color: #f3a321;
  background-color: rgba(243, 163, 33, 0.1);
}

.darkmode .task-dropdown-menu {
  background-color: #2d2d2d;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.user-dropdown .notification-badge {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #e53e3e;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}

.darkmode .task-dropdown-menu a {
  color: #e0e0e0;
}

.darkmode .task-dropdown-menu a:hover {
  background-color: #3d3d3d;
  color: #f3a321;
}

.darkmode .task-dropdown-menu a.active {
  color: #f3a321;
  background-color: rgba(243, 163, 33, 0.1);
}
