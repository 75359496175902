.spreadsheet-editor-wrapper {
  width: 100%;
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

.spreadsheet-editor {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #ffffff;
}

.spreadsheet-toolbar {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.export-button {
  padding: 8px 16px;
  background-color: #4299e1;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.export-button:hover {
  background-color: #3182ce;
}

.spreadsheet-container {
  flex: 1;
  min-height: 500px;
  position: relative;
}

#luckysheet {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
