.profile-image-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profile-preview {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .upload-button {
    padding: 8px 16px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .upload-button:hover {
    background: #0056b3;
  }

  .user-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }

  .user-card {
    background: rgb(101, 100, 100);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    overflow: hidden;
    transition: transform 0.2s;
  }

  .user-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  }

  .user-card-header {
    padding: 20px;
    text-align: center;
    background: #b8b8b8;
    border-bottom: 1px solid #eee;
  }

  .profile-image-container {
    width: 100px;
    height: 100px;
    margin: 0 auto 15px;
    border-radius: 50%;
    overflow: hidden;
  }

  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .profile-image-placeholder {
    width: 100%;
    height: 100%;
    background: #007bff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    font-weight: bold;
  }

  .user-card-body {
    padding: 20px;
  }

  .user-card-body p {
    margin: 8px 0;
    font-size: 0.9em;
  }

  .user-card-actions {
    padding: 15px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    border-top: 1px solid #eee;
  }

  .edit-button, .delete-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
  }

  .edit-button {
    background: #007bff;
    color: white;
  }

  .delete-button {
    background: #dc3545;
    color: white;
  }

  .edit-button:hover {
    background: #0056b3;
  }

  .delete-button:hover {
    background: #c82333;
  }

  /* Dark mode styles */

